import { useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import Select from "react-select";
import ConditionalRequiredFieldError from "../../../components/errors/ConditionalRequiredFieldError";
import SalaryInputError from "../../../components/errors/SalaryInputError";
import AfterUpload from "../../../components/upload-file/AfterUpload";
import BeforeUpload from "../../../components/upload-file/BeforeUpload";
import CommonUploadError from "../../../components/upload-file/CommonUploadError";
import UploadError from "../../../components/upload-file/UploadError";
import Option from "../../../components/utils/Option";
import SingleValue from "../../../components/utils/SingleValue";
import { AppStorageService } from "../../../services/AppStorageService";
import {
  deleteCv,
  formatBytes,
  getCandidateProfile,
  updateCandidateProfile,
  uploadCv,
} from "../../../services/CandidateService";
import { getEnglishLevels } from "../../../services/SalaryDetailsService";
import {
  checkForValidExperience,
  getAllSkills,
  getExclusionOfInterest,
  getExperiencesBySkillId,
  getExtraSkill,
  getIndustries,
  getJobTypes,
  getSpecificExperience,
} from "../../../services/SkillService";
import { Candidate } from "../../../types/Candidate";
import { Industry } from "../../../types/IndustryClass";
import { ReactSelect } from "../../../types/ReactSelect";
import { Skill } from "../../../types/Skill";
import {
  CvDeleteErrorMsg,
  FiftyCharacterErrorMsg,
  NumberOfPrimarySkills,
  RequiredFieldCommonErrorMsg,
  ThousandCharacterErrorMsg,
} from "../../../utils/CommonConstants";

import { forEach } from "react-bootstrap/ElementChildren";
import useProfile from "./useProfile";
import ProgressUploadBar from "../../../components/upload-file/ProgressUploadBar";
import StartUpload from "../../../components/upload-file/StartUpload";
import InputFieldErrorMessage from "../../../components/errors/InputFieldErrorMessage";
import { scrollIntoView } from "react-select/dist/declarations/src/utils";

const CandidateProfile = () => {
  let storage = new AppStorageService();
  let candidateObject = new Candidate();

  let allSkillsFromStorage = storage.getAllSkills();
  let englishLevelsFromStorage = storage.getAllEnglishLevels();
  let initialFormValue: any = [];
  let fileUploadLabel = "Click to upload or drag and drop (max. 800x400px)";
  const fileTypes = ["PDF"];
  let commonInputErrorClass = "col-12 col-md-6 profile-page-input-error";
  let commonInputWithoutErrorClass = "col-12 col-md-6";

  let commentTextareWithoutErrorClass = "col-12 order-md-3";
  let commentTextareErrorClass = "col-12 order-md-3  profile-page-input-error";

  let commonTextareWithoutErrorClass = "col-12";
  let commonTextareErrorClass = "col-12 profile-page-input-error";

  const [
    uploadDocument,
    uploadCompletePercent,
    setUploadCompletePercent,
    uploading,
    setUploading,
  ] = useProfile();
  const nameRef = useRef<any>(null);
  const nameInputRef = useRef<any>(null);
  const titleRef = useRef<any>(null);
  const titleInputRef = useRef<any>(null);
  const currentSalaryRef = useRef<any>(null);
  const currentSalaryInputRef = useRef<any>(null);
  const expectedSalaryRef = useRef<any>(null);
  const expectedSalaryInputRef = useRef<any>(null);
  const englishLevelRef = useRef<any>(null);
  const workExperienceRef = useRef<any>(null);
  const achievementRef = useRef<any>(null);
  const commentRef = useRef<any>(null);

  const [candidate, setCandidate] = useState(candidateObject);
  const [prevName, setPrevName] = useState("");
  const [name, setName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [currentSalary, setCurrentSalary] = useState("");
  const [expectedSalary, setExpectedSalary] = useState("");
  const [englishLevels, setEnglishLevels] = useState([]);
  const [showErrorMsg, setShowErrorMsg] = useState("");
  const [showSuccessMsg, setShowSuccessMsg] = useState("");
  const [selectedEnglishLevelId, setSelectedEnglishLevelId] = useState("");
  const [skills, setSkills] = useState<any[]>(allSkillsFromStorage);
  const [selectedSkills, setSelectedSkills] = useState<any[]>([]);
  const [experiences, setExperience] = useState<any[]>([]);
  const [industry, setIndustry] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedIndustriesFromApi, setSelectedIndustriesFromApi] = useState(
    []
  );
  const [extraSkill, setExtraSkill] = useState([]);
  const [selectedExtraSkill, setSelectedExtraSkill] = useState([]);
  const [selectedExtraSkillFromApi, setSelectedExtraSkillFromApi] = useState(
    []
  );
  const [formValues, setFormValues] = useState(initialFormValue);
  const [selectedSkillsFromApi, setSelectedSkillsFromApi] = useState([]);
  const [availableSkillNumber, setAvailableSkillNumber] = useState(5);
  const [showAddMoreOption, setShowAddMoreOption] = useState(true);
  const [selectedEnglishLevel, setSelectedEnglishLevel] = useState({
    value: "",
    label: "",
    title: "",
    subtitle: "",
  });
  const [showFileUploadFirstPart, setShowFileUploadFirstPart] = useState(true);
  const [showFileUploadSuccessPart, setShowFileUploadSuccessPart] =
    useState(false);
  const [showFileUploadErrorPart, setShowFileUploadErrorPart] = useState(false);
  const [showFileUploadCommonError, setShowFileUploadCommonError] =
    useState(false);
  const [workExperienceHighlight, setWorkExperienceHighlight] = useState("");
  const [achievements, setAchievements] = useState("");
  const [linkedinProfile, setLinkedinProfile] = useState("");
  const [githubProfile, setGithubProfile] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [cv, setCV] = useState("");
  const [cvName, setCVName] = useState("");
  const [comment, setComment] = useState("");
  const [allJobTypes, setAllJobTypes] = useState([]);
  const [selectedJobTypes, setSelectedJobTypes] = useState([]);
  const [selectedJobTypeFromApi, setSelectedJobTypeFromApi] = useState([]);
  const [allExclusionInterests, setAllExclusionInterests] = useState([]);
  const [selectedExclusionInterests, setSelectedExclusionInterests] = useState(
    []
  );
  const [
    selectedExclusionInterestsFromApi,
    setSelectedExclusionInterestsFromApi,
  ] = useState([]);
  const [currentSalaryError, setCurrentSalaryError] = useState(false);
  const [expectedSalaryError, setExpectedSalaryError] = useState(false);
  const [enableSaveButton, setEnableSaveButton] = useState(true);
  const [isCurrentSalaryCorrect, setIsCurrentSalaryCorrect] = useState(true);
  const [isExpectedSalaryCorrect, setIsExpectedSalaryCorrect] = useState(true);
  const [showNameError, setShowNameError] = useState("");
  const [showJobTitleError, setShowJobTitleError] = useState("");
  const [showWorkExperienceError, setShowWorkExperienceError] = useState("");
  const [showAchievementError, setAchievementError] = useState("");
  const [showCommentError, setShowCommentError] = useState("");
  const [cvFile, setCvFile] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [uploadedFileSize, setUploadedFileSize] = useState("");
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);
  const getProfileData = async () => {
    const apiResult = await getCandidateProfile();
    if (apiResult && apiResult.data) {
      let profileData: Candidate = apiResult.data;
      setCandidate(profileData);
      setPrevName(profileData.name ?? "");
      setName(profileData.name ?? "");
      setCurrentSalary(profileData.currentSalary ?? "");
      setJobTitle(profileData.jobPosition ?? "");
      setExpectedSalary(profileData.expectedSalary ?? "");
      setSelectedEnglishLevelId(profileData.englishLevelId ?? "");
      setWorkExperienceHighlight(profileData.workExperience ?? "");
      setAchievements(profileData.achievements ?? "");
      setLinkedinProfile(profileData.linkedinProfile ?? "");
      setGithubProfile(profileData.githubProfile ?? "");
      setWhatsappNumber(profileData.whatsappNumber ?? "");
      setCV(profileData.cv);
      setCVName(profileData.cv_name);
      setUploadedFileSize(
        profileData.cv_size && profileData.cv_size.length > 0
          ? formatBytes(profileData.cv_size, 2)
          : ""
      );
      setComment(profileData.comment ?? "");
      //@ts-ignore
      setSelectedJobTypeFromApi(profileData.jobType);
      //@ts-ignore
      setSelectedExclusionInterestsFromApi(profileData.exclusionInterest);
      //@ts-ignore
      setSelectedIndustriesFromApi(profileData.preferredIndustries);
      //@ts-ignore
      setSelectedExtraSkillFromApi(profileData.extraSkills);
      //@ts-ignore
      setSelectedSkillsFromApi(profileData.primarySkill);
    }
  };

  const getSkills = async () => {
    const skillsFromServer = await getAllSkills();
    let skillOptions: any = [];
    if (skillsFromServer && skillsFromServer.data) {
      skillsFromServer.data.map((item: any) => {
        let temp: any = {};
        temp.value = item.id;
        temp.label = item.name;
        skillOptions.push(temp);
      });
      storage.storeAllSkills(skillOptions);
      setSkills(skillOptions);
    } else {
      console.log("No skills found from server");
    }
  };

  const fetchEnglishLevels = async () => {
    const englishLevelsFromServer = await getEnglishLevels();
    let englishLevelOptions: any = [];
    if (englishLevelsFromServer && englishLevelsFromServer.data) {
      englishLevelsFromServer.data.map((item: any) => {
        let temp: any = {};
        temp.value = item.id;
        temp.label = item.name;
        temp.title = item.name;
        temp.subTitle = item.display;
        englishLevelOptions.push(temp);
      });
      storage.storeAllEnglishLevels(englishLevelOptions);
      setEnglishLevels(englishLevelOptions);
    } else {
      console.log("Something went wrong");
    }
  };

  let getIndustryFromServer = async () => {
    let dataFromAPI = await getIndustries("");
    if (dataFromAPI && dataFromAPI.data) {
      let options: any = [];
      dataFromAPI.data.map((item: any) => {
        let itemArr = new ReactSelect();
        itemArr["value"] = item.id;
        itemArr["label"] = item.name;
        options.push(itemArr);
      });
      setIndustry(options);
    }
  };

  let getExtraSkillFromServer = async () => {
    let dataFromAPI = await getExtraSkill("");
    if (dataFromAPI && dataFromAPI.data) {
      let options: any = [];
      dataFromAPI.data.map((item: any) => {
        let itemArr = new ReactSelect();
        itemArr["value"] = item.id;
        itemArr["label"] = item.name;
        options.push(itemArr);
      });
      setExtraSkill(options);
    }
  };

  let getJobTypesFromServer = async () => {
    let dataFromAPI = await getJobTypes("");
    if (dataFromAPI && dataFromAPI.data) {
      setAllJobTypes(dataFromAPI.data);
    }
  };

  let getExclusionInterestFromServer = async () => {
    let dataFromAPI = await getExclusionOfInterest("");
    if (dataFromAPI && dataFromAPI.data) {
      setAllExclusionInterests(dataFromAPI.data);
    }
  };

  let deleteCvFromServer = async () => {
    let dataFromAPI = await deleteCv();
    return !!(dataFromAPI && dataFromAPI.success);
  };

  useEffect(() => {
    setShowSuccessMsg("");
    setShowErrorMsg("");
    getIndustryFromServer();
    getExtraSkillFromServer();
    getJobTypesFromServer();
    getExclusionInterestFromServer();
    getProfileData();
    if (englishLevelsFromStorage != null) {
      setEnglishLevels(englishLevelsFromStorage);
    } else {
      fetchEnglishLevels();
    }

    if (allSkillsFromStorage && allSkillsFromStorage.length > 0) {
    } else {
      getSkills();
    }
  }, []);

  useEffect(() => {
    if (selectedEnglishLevelId && englishLevels && englishLevels.length > 0) {
      englishLevels.map((item: any) => {
        if (item.value == selectedEnglishLevelId) {
          setSelectedEnglishLevel(item);
        }
      });
    }
  }, [englishLevels, selectedEnglishLevelId]);

  useEffect(() => {
    if (selectedIndustriesFromApi && selectedIndustriesFromApi.length > 0) {
      let industryData: any = [];
      selectedIndustriesFromApi.map((item: any) => {
        let tempArr: any = [];
        tempArr.value = item.id;
        tempArr.label = item.name;
        industryData.push(tempArr);
      });
      setSelectedIndustries(industryData);
    }
  }, [selectedIndustriesFromApi]);

  useEffect(() => {
    if (selectedExtraSkillFromApi && selectedExtraSkillFromApi.length > 0) {
      let extraSkillData: any = [];
      selectedExtraSkillFromApi.map((item: any) => {
        let tempArr: any = [];
        tempArr.value = item.id;
        tempArr.label = item.name;
        extraSkillData.push(tempArr);
      });
      setSelectedExtraSkill(extraSkillData);
    }
  }, [selectedExtraSkillFromApi]);

  useEffect(() => {
    if (selectedJobTypeFromApi && selectedJobTypeFromApi.length > 0) {
      let tempArr: any = [];
      selectedJobTypeFromApi.map((item: any) => {
        tempArr[item.id - 1] = item.id;
      });
      setSelectedJobTypes(tempArr);

      let oldAllJobTypeData: any = allJobTypes;
      let newAllJobTypes: any = [];
      oldAllJobTypeData.map((item: any) => {
        if (tempArr.includes(item.id)) {
          item.checked = true;
        }
        newAllJobTypes.push(item);
      });
    }
  }, [selectedJobTypeFromApi]);

  useEffect(() => {
    if (
      selectedExclusionInterestsFromApi &&
      selectedExclusionInterestsFromApi.length > 0
    ) {
      let tempArr: any = [];
      selectedExclusionInterestsFromApi.map((item: any) => {
        tempArr[item.id - 1] = item.id;
      });
      setSelectedExclusionInterests(tempArr);

      let oldExclusionInterestData: any = allExclusionInterests;
      let newAllJobTypes: any = [];
      oldExclusionInterestData.map((item: any) => {
        if (tempArr.includes(item.id)) {
          item.checked = true;
        }
        newAllJobTypes.push(item);
      });
    }
  }, [selectedExclusionInterestsFromApi]);

  useEffect(() => {
    if (selectedSkillsFromApi && selectedSkillsFromApi.length > 0) {
      setAvailableSkillNumber(5 - selectedSkillsFromApi.length);
      let allData: any = [];
      let skillData: any = [];
      selectedSkillsFromApi.map((item: any) => {
        let experienceData = getSpecificExperience(item.id, item.experience);

        let tempArr: any = {};
        tempArr["skill"] = {};
        tempArr["experience"] = {};
        tempArr["skill"].value = item.id;
        tempArr["skill"].label = item.name;
        tempArr["experience"].value = experienceData.value;
        tempArr["experience"].label = experienceData.label;
        tempArr["currently_working"] =
          item.currently_working == "Yes" ? true : false;
        allData.push(tempArr);
        skillData.push(tempArr["skill"]);
      });
      setSelectedSkills(skillData);
      setFormValues(allData);
      setSkills(
        storage.disabledSkillOptionWithSpecificSkills(skills, skillData)
      );
    }
  }, [selectedSkillsFromApi]);

  useEffect(() => {
    if (cvName) {
      setUploadedFileName(cvName);
      setShowFileUploadFirstPart(false);
      setShowFileUploadErrorPart(false);
      setShowFileUploadSuccessPart(true);
      setShowFileUploadCommonError(false);
    }
  }, [cvName]);

  useEffect(() => {
    let experienceList: any = [];
    if (selectedSkills && selectedSkills.length > 0) {
      selectedSkills.map((item: Skill) => {
        if (item) {
          experienceList.push(getExperiencesBySkillId(item.value));
        }
      });
    }
    setExperience(experienceList);
  }, [selectedSkills]);

  useEffect(() => {
    if (formValues.length > 0 && experiences.length > 0) {
      formValues.map((item: any, index: any) => {
        if (item.experience && experiences[index]) {
          if (checkForValidExperience(item.experience, experiences[index])) {
          } else {
            let newFormValues = [...formValues];
            newFormValues[index].experience = null;
            setFormValues(newFormValues);
            //setSelectedExperiences([]);
          }
        }
      });
    }
  }, [experiences]);

  useEffect(() => {
    if (uploading) {
      setShowFileUploadFirstPart(false);
      setShowFileUploadSuccessPart(false);
      setShowFileUploadErrorPart(false);
      setShowFileUploadCommonError(false);
    }
  }, [uploading]);

  const handleCvUpload = (file: any) => {
    setShowErrorMsg("");
    setShowSuccessMsg("");
    setCvFile(file);
    if (file) {
      setUploadedFileName(file.name ?? "");
      if (file.size) {
        setUploadedFileSize(formatBytes(file.size, 2));
      }
    }

    const formData = new FormData();
    formData.append("cv", file);
    uploadTheFile(formData);
  };

  const uploadTheFile = (formData: any) => {
    setUploading(true);
    setTimeout(() => {
      waitAndUploadTheFile(formData);
    }, 1000);
  };

  const waitAndUploadTheFile = async (formData: any) => {
    let apiResult = await uploadDocument(formData);
    if (apiResult && apiResult.success) {
      setUploadedFileName(apiResult.data.file_name ?? "");
      setCV(apiResult.data.cv ?? "");
      setTimeout(() => {
        setUploading(false);
        setShowFileUploadFirstPart(false);
        setShowFileUploadSuccessPart(true);
        setShowFileUploadErrorPart(false);
        setShowFileUploadCommonError(false);
        setUploadCompletePercent(0);
      }, 1000);

      setShowSuccessMsg("Saved Successfully");
    } else {
      setUploadCompletePercent(0);
      setUploading(false);
      setShowFileUploadFirstPart(false);
      setShowFileUploadErrorPart(true);
      setShowFileUploadSuccessPart(false);
      setShowFileUploadCommonError(false);
      setShowErrorMsg("Something went wrong!");
    }
  };

  const handleTryAgainBtn = () => {
    setShowErrorMsg("");
    setShowSuccessMsg("");
    const formData = new FormData();
    formData.append("cv", cvFile);
    uploadTheFile(formData);
  };

  const handleDeleteBtn = () => {
    setShowErrorMsg("");
    setShowSuccessMsg("");
    setDisableDeleteBtn(true);
    deleteCvFromServer().then((response: any) => {
      if (response) {
        setShowFileUploadSuccessPart(false);
        setShowFileUploadErrorPart(false);
        setShowFileUploadFirstPart(true);
        setShowFileUploadCommonError(false);
        setDisableDeleteBtn(false);
      } else {
        setShowErrorMsg(CvDeleteErrorMsg);
        setDisableDeleteBtn(false);
      }
    });
  };

  const handleCrossBtn = () => {
    setShowErrorMsg("");
    setShowSuccessMsg("");
    setShowFileUploadSuccessPart(false);
    setShowFileUploadErrorPart(false);
    setShowFileUploadFirstPart(true);
    setShowFileUploadCommonError(false);
  };

  const handleMaxSizeError = () => {
    setShowFileUploadSuccessPart(false);
    setShowFileUploadErrorPart(false);
    setShowFileUploadFirstPart(false);
    setShowFileUploadCommonError(true);
  };

  const handleName = (event: any) => {
    let value = event.target.value;
    setShowNameError("");
    nameRef.current.className = commonInputWithoutErrorClass;
    candidate.name = value;
    setCandidate(candidate);
    if (value.length > 50) {
      nameRef.current.className = commonInputErrorClass;
      setShowNameError(FiftyCharacterErrorMsg);
    }
  };

  const handleTitle = (event: any) => {
    let value = event.target.value;
    setShowJobTitleError("");
    titleRef.current.className = commonInputWithoutErrorClass;
    candidate.jobPosition = value;
    setCandidate(candidate);
    if (value.length > 50) {
      titleRef.current.className = commonInputErrorClass;
      setShowJobTitleError(FiftyCharacterErrorMsg);
    }
  };

  const handleCurrentSalary = (event: any) => {
    let currentSalary = event.target.value;
    setIsCurrentSalaryCorrect(true);
    setCurrentSalaryError(false);
    // @ts-ignore
    currentSalaryRef.current.className = commonInputWithoutErrorClass;
    if (currentSalary > 999999) {
      currentSalary = shortenNumber(currentSalary);
      setCurrentSalary(currentSalary);
    }
    if (currentSalary < 1000) {
      setCurrentSalaryError(true);
      setIsCurrentSalaryCorrect(false);
    }
    if (currentSalary < 0) {
      currentSalary = Math.abs(currentSalary) > 0 ? Math.abs(currentSalary) : 0;
      setCurrentSalary(currentSalary);
      setIsCurrentSalaryCorrect(false);
      return false;
    }
    if (/^0/.test(currentSalary) || /^-0/.test(currentSalary)) {
      return false;
    }
    if (currentSalary.toString().length > 6) {
      setIsCurrentSalaryCorrect(true);
      return false;
    }

    setCurrentSalary(currentSalary);
    candidate.currentSalary = currentSalary;
    setCandidate(candidate);
  };

  const handleExpectedSalary = (event: any) => {
    let salary = event.target.value;
    setIsExpectedSalaryCorrect(true);
    setExpectedSalaryError(false);
    // @ts-ignore
    expectedSalaryRef.current.className = commonInputWithoutErrorClass;
    if (salary > 999999) {
      salary = shortenNumber(salary);
      setExpectedSalary(salary);
    }
    if (salary < 1000) {
      setExpectedSalaryError(true);
      setIsExpectedSalaryCorrect(false);
    }
    if (salary < 0) {
      salary = Math.abs(salary) > 0 ? Math.abs(salary) : 0;
      setExpectedSalary(salary);
      setIsExpectedSalaryCorrect(false);
      return false;
    }
    if (/^0/.test(salary) || /^-0/.test(salary)) {
      return false;
    }
    if (salary.toString().length > 6) {
      return false;
    }
    setExpectedSalary(salary);
    candidate.expectedSalary = salary;
    setCandidate(candidate);
  };

  const shortenNumber = (num: number): number => {
    const numStr = num.toString();
    if (numStr.length > 6) {
      return parseInt(numStr.substring(0, numStr.length - 1), 10);
    }
    return num;
  };

  const handleEnglishLevels = (value: any) => {
    //@ts-ignore
    englishLevelRef.current.className = commonInputWithoutErrorClass;
    setSelectedEnglishLevel(value);
    if (value) {
      candidate.englishLevelId = value.value;
    } else {
      candidate.englishLevelId = "";
    }

    setCandidate(candidate);

    //defaultEnglishLevel = value;
  };

  const handleIndustry = (selectedData: any) => {
    setSelectedIndustries(selectedData);
    let selectedIndustry: Industry[] = [];
    if (selectedData && selectedData.length > 0) {
      selectedData.map((item: any) => {
        let tempItem = new Industry();
        tempItem["id"] = item.value;
        tempItem["name"] = item.label;
        selectedIndustry.push(tempItem);
      });
    } else {
      console.log("empty");
    }
    candidate.preferredIndustries = selectedIndustry;
    setCandidate(candidate);
  };

  const handleExtraSkill = (selectedData: any) => {
    setSelectedExtraSkill(selectedData);
    let selectedExtraSkill: Skill[] = [];
    if (selectedData && selectedData.length > 0) {
      selectedData.map((item: any) => {
        let tempItem = new Skill();
        tempItem["id"] = item.value;
        tempItem["name"] = item.label;
        selectedExtraSkill.push(tempItem);
      });
    } else {
      console.log("empty");
    }
    candidate.extraSkills = selectedExtraSkill;
    setCandidate(candidate);
  };

  const handleWorkExperience = (event: any) => {
    setShowWorkExperienceError("");
    let value = event.target.value;
    setWorkExperienceHighlight(value);
    workExperienceRef.current.className = commonTextareWithoutErrorClass;
    candidate.workExperience = value;
    setCandidate(candidate);
    if (value.length > 1000) {
      workExperienceRef.current.className = commonTextareErrorClass;
      workExperienceRef.current.scrollIntoView();
      setShowWorkExperienceError(ThousandCharacterErrorMsg);
    }
  };

  const handleAchievements = (event: any) => {
    setAchievementError("");
    let value = event.target.value;
    setAchievements(value);
    achievementRef.current.className = commonTextareWithoutErrorClass;
    candidate.achievements = value;
    setCandidate(candidate);
    if (value.length > 1000) {
      achievementRef.current.className = commonTextareErrorClass;
      achievementRef.current.scrollIntoView();
      setAchievementError(ThousandCharacterErrorMsg);
    }
  };

  const handleLinkedInProfile = (event: any) => {
    setLinkedinProfile(event.target.value);
    candidate.linkedinProfile = event.target.value;
    setCandidate(candidate);
  };

  const handleWhatsappNumber = (event: any) => {
    setWhatsappNumber(event.target.value);
    candidate.whatsappNumber = event.target.value;
    setCandidate(candidate);
  };

  const handleGithubProfile = (event: any) => {
    setGithubProfile(event.target.value);
    candidate.githubProfile = event.target.value;
    setCandidate(candidate);
  };

  const handleComment = (event: any) => {
    let value = event.target.value;
    setShowCommentError("");
    commentRef.current.className = commentTextareWithoutErrorClass;
    candidate.comment = value;
    setComment(value);
    setCandidate(candidate);
    if (value.length > 1000) {
      commentRef.current.className = commentTextareErrorClass;
      setShowCommentError(ThousandCharacterErrorMsg);
    }
  };

  let addFormFields = () => {
    let ifContainsEmptyValue = false;
    formValues.map((item: any, index: any) => {
      if (!item.skill || !item.experience) {
        ifContainsEmptyValue = true;
        return;
      }
    });

    if (availableSkillNumber <= 0 || ifContainsEmptyValue) {
      return false;
    }
    setAvailableSkillNumber(availableSkillNumber - 1);
    //@ts-ignore
    setFormValues([
      ...formValues,
      { skill: null, experience: null, currently_working: false },
    ]);

    if (availableSkillNumber <= 1) {
      setShowAddMoreOption(false);
    }
    //forcefully set enable proceed button to false
    //checkIfEnableToProceed("set_false");
  };

  let removeFormFields = (i: any) => {
    setAvailableSkillNumber(availableSkillNumber + 1);
    setShowAddMoreOption(true);

    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);

    let tempSelectedSkills: Skill[] = [];
    if (newFormValues && newFormValues.length > 0) {
      newFormValues.map((item: any) => {
        tempSelectedSkills.push(item.skill);
      });
    }

    setSelectedSkills(tempSelectedSkills);
    setSkills(
      storage.disabledSkillOptionWithSpecificSkills(skills, tempSelectedSkills)
    );
  };

  const validateForm = () => {
    setShowNameError("");
    setShowJobTitleError("");
    setShowWorkExperienceError("");
    setAchievementError("");
    setShowCommentError("");

    nameRef.current.className = commonInputWithoutErrorClass;
    commentRef.current.className = commentTextareWithoutErrorClass;
    workExperienceRef.current.className = commonTextareWithoutErrorClass;
    achievementRef.current.className = commonTextareWithoutErrorClass;
    currentSalaryRef.current.className = commonInputWithoutErrorClass;
    expectedSalaryRef.current.className = commonInputWithoutErrorClass;
    titleRef.current.className = commonInputWithoutErrorClass;
    englishLevelRef.current.className = commonInputWithoutErrorClass;

    let isValid = false;

    if (
      candidate.name &&
      candidate.jobPosition &&
      isExpectedSalaryCorrect &&
      isCurrentSalaryCorrect &&
      selectedEnglishLevel &&
      selectedEnglishLevel.value
    ) {
      isValid = true;
    }

    let candidateJobType: any = [];
    selectedJobTypes.map((item: any) => {
      if (item && item != null) {
        let tempItem: any = {};
        tempItem.id = item;
        candidateJobType.push(tempItem);
      }
    });
    candidate.jobType = candidateJobType;
    setCandidate(candidate);

    if (!selectedEnglishLevel || !selectedEnglishLevel.value) {
      isValid = false;
      // @ts-ignore
      englishLevelRef.current.className = commonInputErrorClass;
      //@ts-ignore
      englishLevelRef.current.scrollIntoView();
    }
    // @ts-ignore
    if (
      !isCurrentSalaryCorrect ||
      !currentSalaryInputRef?.current?.value ||
      currentSalaryInputRef?.current?.value <= 999
    ) {
      isValid = false;
      // @ts-ignore
      currentSalaryRef.current.className = commonInputErrorClass;
      // @ts-ignore
      currentSalaryRef.current.scrollIntoView();
    }
    // @ts-ignore
    if (
      !isExpectedSalaryCorrect ||
      !expectedSalaryInputRef.current.value ||
      expectedSalaryInputRef.current.value <= 999
    ) {
      isValid = false;
      // @ts-ignore
      expectedSalaryRef.current.className = commonInputErrorClass;
      // @ts-ignore
      expectedSalaryRef.current.scrollIntoView();
    }

    if (candidate.comment && candidate.comment.length > 1000) {
      isValid = false;
      setShowCommentError(ThousandCharacterErrorMsg);
      commentRef.current.scrollIntoView();
    }

    if (candidate.achievements && candidate.achievements.length > 1000) {
      isValid = false;
      setAchievementError(ThousandCharacterErrorMsg);
      achievementRef.current.scrollIntoView();
    }

    if (candidate.workExperience && candidate.workExperience.length > 1000) {
      isValid = false;
      setShowWorkExperienceError(ThousandCharacterErrorMsg);
      workExperienceRef.current.scrollIntoView();
    }

    if (!candidate.jobPosition || !titleInputRef.current.value) {
      isValid = false;
      setShowJobTitleError(RequiredFieldCommonErrorMsg);
      titleRef.current.className = commonInputErrorClass;
      titleRef.current.scrollIntoView();
    } else {
      if (candidate.jobPosition.length > 50) {
        isValid = false;
        setShowJobTitleError(FiftyCharacterErrorMsg);
        titleRef.current.className = commonInputErrorClass;
        titleRef.current.scrollIntoView();
      }
    }

    if (!candidate.name || !nameInputRef.current.value) {
      isValid = false;
      setShowNameError(RequiredFieldCommonErrorMsg);
      nameRef.current.className = commonInputErrorClass;
      nameRef.current.scrollIntoView();
    } else {
      if (candidate.name.length > 50) {
        isValid = false;
        setShowNameError(FiftyCharacterErrorMsg);
        nameRef.current.className = commonInputErrorClass;
        nameRef.current.scrollIntoView();
      }
    }

    return isValid;
  };

  const handleSave = () => {
    setShowErrorMsg("");
    setShowSuccessMsg("");
    const isValid = validateForm();
    if (!isValid) {
      console.error("Invalid Form!");
      return false;
    }

    if (formValues && formValues.length > 0) {
      let primarySkill: Skill[] = [];
      formValues.map((item: any) => {
        let tempSkill = new Skill();
        if (item.skill && item.skill.value && item.skill.label) {
          tempSkill.id = item.skill.value ?? "";
          tempSkill.name = item.skill.label;
          tempSkill.currently_working = item.currently_working ? "Yes" : "No";
          if (item.experience && item.experience.value) {
            tempSkill.experience = item.experience.value;
          } else {
            tempSkill.experience = "";
          }

          primarySkill.push(tempSkill);
        }
      });
      candidate.primarySkill = primarySkill;
      setCandidate(candidate);
    }
    let saveProfileChanges = async () => {
      let candidateData = candidate;
      let response = await updateCandidateProfile(candidateData);
      if (response && response.success) {
        setShowSuccessMsg("Updated Successfully");
      } else {
        let errorText = "Something went wrong";
        if (response.data) {
          errorText = "";
          let errorMsgFromApi = response.data;
          if (errorMsgFromApi.achievements) {
            errorMsgFromApi.achievements.forEach((item: any) => {
              errorText += item + "\n";
            });
          }
          if (errorMsgFromApi.workExperience) {
            errorMsgFromApi.workExperience.forEach((item: any) => {
              errorText += item + "\n";
            });
          }
          if (errorMsgFromApi.comment) {
            errorMsgFromApi.comment.forEach((item: any) => {
              errorText += item + "\n";
            });
          }

          if (errorMsgFromApi.name) {
            errorMsgFromApi.name.forEach((item: any) => {
              errorText += item + "\n";
            });
          }

          if (errorMsgFromApi.jobPosition) {
            errorMsgFromApi.jobPosition.forEach((item: any) => {
              errorText += item + "\n";
            });
          }
        }

        setShowErrorMsg(errorText ?? "Something went wrong");
      }
    };
    saveProfileChanges();
  };

  const handleJobType = (event: any, index: any) => {
    let temp: any = selectedJobTypes;
    let key = event.target.value - 1;
    if (event.target.checked) {
      temp[key] = event.target.value;
    } else {
      temp[key] = null;
    }
    setSelectedJobTypes(temp);
  };

  const handleExclusionInterest = (event: any, index: any) => {
    let temp: any = selectedExclusionInterests;
    let key = event.target.value - 1;
    if (event.target.checked) {
      temp[key] = event.target.value;
    } else {
      temp[key] = null;
    }

    setSelectedExclusionInterests(temp);
    let candidateExclusionInterest: any = [];
    temp.map((item: any) => {
      if (item && item != null) {
        let tempItem: any = {};
        tempItem.id = item;
        candidateExclusionInterest.push(tempItem);
      }
    });
    candidate.exclusionInterest = candidateExclusionInterest;
    setCandidate(candidate);
  };

  const handlePrimarySkill: any = (index: any, skillData: any) => {
    let newFormValues: any = [...formValues];
    newFormValues[index]["skill"] = skillData;
    setFormValues(newFormValues);

    let tempSelectedSkills: Skill[] = [];
    if (newFormValues && newFormValues.length > 0) {
      newFormValues.map((item: any) => {
        tempSelectedSkills.push(item.skill);
      });
    }
    setSelectedSkills(tempSelectedSkills);
    setSkills(
      storage.disabledSkillOptionWithSpecificSkills(skills, tempSelectedSkills)
    );
  };

  const handleExperience: any = (index: any, experienceData: any) => {
    let newFormValues: any = [...formValues];
    newFormValues[index]["experience"] = experienceData;
    setFormValues(newFormValues);
  };

  const handleCurrentlyWorking = (index: any, event: any) => {
    let newFormValues: any = [...formValues];
    newFormValues[index]["currently_working"] = event.target.checked;
    setFormValues(newFormValues);
    //storage.storeCurrentlyWorking(index, event.target.checked);
    //checkIfEnableToProceed();
  };

  function checkIfEnableToSave() {
    if (isCurrentSalaryCorrect && isExpectedSalaryCorrect) {
      setEnableSaveButton(true);
    } else {
      setEnableSaveButton(false);
    }
  }

  return (
    <>
      <section className="bg-light">
        <div className="container">
          <h3 className="py-6 pb-md-7 mb-md-5 text-dark">
            Hello {prevName} 👋
          </h3>
          <div className="row">
            <div className="col-12 col-md-2 border-end pe-0">
              <nav>
                <div
                  className="nav nav-tabs flex-row flex-md-column user-nav"
                  id="nav-tab"
                  role="tablist"
                >
                  <button
                    className="nav-link active rounded-0 p-3 fw-semibold text-start me-4 me-md-0"
                    id="nav-profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-profile"
                    type="button"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="true"
                  >
                    <i className="bi bi-person me-1 d-none d-md-inline-block"></i>
                    Profile
                  </button>
                </div>
              </nav>
            </div>
            <div className="col-12 col-md-10">
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active pt-0 spacious-section"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  {/*  General: Starts */}
                  <div className="row mb-6 mb-md-7 mt-6 mt-md-0">
                    <div className="col-12 col-md-4">
                      <div className="ps-0 ps-md-5 pb-6">
                        <h5 className="text-dark mb-1">General</h5>
                        <p className="small mb-0">
                          Basic info about you & your current work
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-8 px-0 px-md-3">
                      <div className="card profile-card shadow">
                        <form>
                          <div className="row gx-0 gx-md-3 gy-4 gy-md-6 px-4 px-md-6 py-6">
                            <div className="col-12 col-md-6" ref={nameRef}>
                              <label htmlFor="inputName" className="form-label">
                                Full name
                              </label>
                              <input
                                type="text"
                                className="form-control profile-page-input-error-field"
                                id="inputName"
                                placeholder="Name"
                                defaultValue={name}
                                onChange={handleName}
                                autoComplete="off"
                                ref={nameInputRef}
                              />
                              <InputFieldErrorMessage error={showNameError} />
                            </div>
                            <div className="col-12 col-md-6" ref={titleRef}>
                              <label
                                htmlFor="inputTitle"
                                className="form-label"
                              >
                                Title
                              </label>
                              <input
                                type="text"
                                className="form-control profile-page-input-error-field"
                                id="inputTitle"
                                placeholder={"Job Title"}
                                defaultValue={jobTitle}
                                onChange={handleTitle}
                                autoComplete="off"
                                ref={titleInputRef}
                              />
                              <InputFieldErrorMessage
                                error={showJobTitleError}
                              />
                            </div>
                            <div
                              className="col-12 col-md-6"
                              ref={currentSalaryRef}
                            >
                              <label htmlFor="inputName" className="form-label">
                                Current salary
                              </label>
                              <input
                                min="0"
                                type="number"
                                className="form-control profile-page-input-error-field"
                                //className={currentSalaryError ?  "form-control error-input" : "form-control"}
                                aria-label="Amount here"
                                placeholder="Amount here"
                                //defaultValue={currentSalary}
                                value={currentSalary}
                                onChange={handleCurrentSalary}
                                autoComplete="off"
                                onWheel={(e) =>
                                  (e.target as HTMLElement).blur()
                                }
                                ref={currentSalaryInputRef}
                              />
                              <SalaryInputError show={currentSalaryError} />
                              <ConditionalRequiredFieldError
                                show={!currentSalaryError}
                              />
                            </div>
                            <div
                              className="col-12 col-md-6"
                              ref={expectedSalaryRef}
                            >
                              <label
                                htmlFor="inputTitle"
                                className="form-label"
                              >
                                Expected salary, BDT
                              </label>
                              <input
                                min="0"
                                type="number"
                                className="form-control profile-page-input-error-field"
                                id="inputTitle"
                                aria-label="Amount here"
                                placeholder="Amount here"
                                value={expectedSalary}
                                onChange={handleExpectedSalary}
                                autoComplete="off"
                                onWheel={(e) =>
                                  (e.target as HTMLElement).blur()
                                }
                                ref={expectedSalaryInputRef}
                              />
                              <SalaryInputError show={expectedSalaryError} />
                              <ConditionalRequiredFieldError
                                show={!expectedSalaryError}
                              />
                            </div>
                            <div
                              className="col-12 col-md-6 "
                              ref={englishLevelRef}
                            >
                              <label
                                htmlFor="englishLevel"
                                className="form-label"
                              >
                                English level
                              </label>
                              <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={false}
                                name="englishLevels"
                                options={englishLevels}
                                defaultValue={selectedEnglishLevel}
                                value={selectedEnglishLevel}
                                placeholder={<>Select</>}
                                onChange={handleEnglishLevels}
                                components={{ SingleValue, Option }}
                              />
                              <ConditionalRequiredFieldError show={true} />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/*  General: Ends */}

                  {/* Skills & Achievements: Starts */}
                  <div className="row mt-6">
                    <div className="col-12 col-md-4">
                      <div className="ps-0 ps-md-5 pb-6">
                        <h5 className="text-dark mb-1">
                          Skills & achievements
                        </h5>
                        <p className="small mb-0">
                          Skills you’ve earned so far with your preferences of
                          work type
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-8 px-0 px-md-3">
                      <div className="card profile-card shadow">
                        <div className="row gx-0 gx-md-3 gy-4 gy-md-6 px-4 px-md-6 py-6">
                          <form>
                            {formValues.map((element: any, index: any) => (
                              <div className="form-inline" key={index}>
                                <div>
                                  <div className="row g-3 mb-6">
                                    <div className="col-12 col-md-8">
                                      <label
                                        htmlFor="primarySkill"
                                        className="form-label"
                                      >
                                        Primary Skill{" "}
                                        {NumberOfPrimarySkills[index]}
                                      </label>
                                      <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={false}
                                        name="skill"
                                        options={skills}
                                        defaultValue={element.skill}
                                        value={element.skill}
                                        placeholder={<>Select Skills</>}
                                        onChange={(e) =>
                                          handlePrimarySkill(index, e)
                                        }
                                      />
                                    </div>
                                    <div className="col-12 col-md-4">
                                      <label
                                        htmlFor="experience"
                                        className="form-label"
                                      >
                                        Experience (yrs)
                                      </label>
                                      <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={false}
                                        name="experience"
                                        options={experiences[index]}
                                        placeholder={<>e.g. 3</>}
                                        onChange={(e) =>
                                          handleExperience(index, e)
                                        }
                                        defaultValue={element.experience}
                                        value={element.experience}
                                      />
                                    </div>
                                    <div className="col-12">
                                      <div className="row justify-content-between align-items-center">
                                        <div className="col">
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="currentlyWorking{{ i }}"
                                              defaultChecked={
                                                element.currently_working
                                              }
                                              value={element.currently_working}
                                              onChange={(e) =>
                                                handleCurrentlyWorking(index, e)
                                              }
                                            />
                                            <label className="form-check-label small">
                                              Currently working
                                            </label>
                                          </div>
                                        </div>
                                        {index ? (
                                          <div
                                            className="col-auto"
                                            onClick={() =>
                                              removeFormFields(index)
                                            }
                                          >
                                            <p className="mb-0 text-danger fw-semibold small cursor-pointer">
                                              - Remove skill
                                            </p>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}

                            <div className="row justify-content-between align-items-center small ">
                              {showAddMoreOption ? (
                                <>
                                  <div
                                    className="col-auto"
                                    onClick={() => addFormFields()}
                                  >
                                    <p className="mb-0 text-theme fw-semibold cursor-pointer">
                                      + Add more skills
                                    </p>
                                  </div>
                                  <div className="col-auto">
                                    <p
                                      className="mb-0"
                                      style={{
                                        float: "right",
                                        color: "#667085",
                                      }}
                                    >
                                      * {availableSkillNumber} more can be added
                                    </p>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </form>
                          {/*  Add Primary Skills: Ends */}

                          <div className="col-12">
                            <label
                              htmlFor="inputIndustry"
                              className="form-label"
                            >
                              Industry
                            </label>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isClearable={true}
                              isSearchable={true}
                              name="experience"
                              options={industry}
                              placeholder={
                                <>Industry experience (One or more)</>
                              }
                              onChange={handleIndustry}
                              defaultValue={selectedIndustries}
                              value={selectedIndustries}
                              isMulti={true}
                            />
                          </div>

                          <div className="col-12">
                            <label
                              htmlFor="inputExtraSkills"
                              className="form-label"
                            >
                              Extra skills
                            </label>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isClearable={true}
                              isSearchable={true}
                              name="extraSkill"
                              options={extraSkill}
                              placeholder={<>Extra skills (One or more)</>}
                              onChange={handleExtraSkill}
                              defaultValue={selectedExtraSkill}
                              value={selectedExtraSkill}
                              isMulti={true}
                            />
                          </div>

                          <div className="col-12" ref={workExperienceRef}>
                            <label
                              htmlFor="inputWorkExperience"
                              className="form-label"
                            >
                              Work experience highlights
                            </label>
                            <textarea
                              value={workExperienceHighlight}
                              onChange={handleWorkExperience}
                              className="form-control profile-page-input-error-field"
                              id="inputWorkExperience"
                              placeholder="Write Here"
                            ></textarea>
                            <InputFieldErrorMessage
                              error={showWorkExperienceError}
                            />
                          </div>

                          <div className="col-12" ref={achievementRef}>
                            <label
                              htmlFor="inputAchievements"
                              className="form-label"
                            >
                              Achievements / certifications
                            </label>
                            <textarea
                              className="form-control profile-page-input-error-field"
                              id="inputAchievements"
                              placeholder="Write here"
                              value={achievements}
                              onChange={handleAchievements}
                            ></textarea>
                            <InputFieldErrorMessage
                              error={showAchievementError}
                            />
                          </div>

                          <div className="col-12">
                            <label
                              htmlFor="inputJobType"
                              className="form-label mb-4"
                            >
                              Job types I am considering
                            </label>
                            <div className="row row-cols-1 row-cols-md-1 row-cols-lg-3 g-3">
                              {allJobTypes.map((item: any, index) => {
                                return (
                                  <div className="col" key={index}>
                                    <div className="form-check">
                                      <input
                                        defaultChecked={item.checked}
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`custom-checkbox-${index}`}
                                        value={item.id}
                                        onClick={(e) => handleJobType(e, index)}
                                      />
                                      <label
                                        htmlFor={`custom-checkbox-${index}`}
                                      >
                                        {item.name}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <div className="col-12">
                            <label
                              htmlFor="jobNotInterest"
                              className="form-label mb-4"
                            >
                              Not interested in any work related to
                            </label>
                            <div className="row row-cols-1 row-cols-md-1 row-cols-lg-3 g-3">
                              {allExclusionInterests.map((item: any, index) => {
                                return (
                                  <div className="col" key={index}>
                                    <div className="form-check">
                                      <input
                                        defaultChecked={item.checked}
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`custom-checkbox-${index}`}
                                        value={item.id}
                                        onClick={(e) =>
                                          handleExclusionInterest(e, index)
                                        }
                                      />
                                      <label
                                        htmlFor={`custom-checkbox-${index}`}
                                      >
                                        {item.name}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  Skills & Achievements: Ends */}

                  {/*  Others: Starts */}
                  <div className="row mt-6">
                    <div className="col-12 col-md-4">
                      <div className="ps-0 ps-md-5 pb-6">
                        <h5 className="text-dark mb-1">Others</h5>
                        <p className="small mb-0">Social links & CV</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-8 px-0 px-md-0">
                      <div className="card profile-card shadow">
                        <form>
                          <div className="row gx-0 gx-md-3 gy-4 gy-md-6 px-4 px-md-6 py-6">
                            <div
                              className="col-12 col-lg-6 order-lg-2"
                              style={{ marginBottom: 30 }}
                            >
                              <label
                                htmlFor="inputWhatsapp"
                                className="form-label"
                              >
                                Upload your CV
                              </label>
                              <div className="border rounded-3 h-100 w-100 cv-upload">
                                <FileUploader
                                  label={fileUploadLabel}
                                  handleChange={handleCvUpload}
                                  name="file"
                                  types={fileTypes}
                                  disabled={
                                    !showFileUploadFirstPart ? true : false
                                  }
                                  maxSize={5}
                                  onSizeError={handleMaxSizeError}
                                >
                                  <BeforeUpload
                                    loading={uploading}
                                    show={showFileUploadFirstPart}
                                  />
                                  <UploadError
                                    name={uploadedFileName}
                                    handleTryAgain={handleTryAgainBtn}
                                    show={showFileUploadErrorPart}
                                    handleDelete={handleCrossBtn}
                                  />
                                  <CommonUploadError
                                    text="Maximum file size of 5MB exceeded. Please reduce the file size and try again"
                                    name={uploadedFileName}
                                    show={showFileUploadCommonError}
                                    handleDelete={handleCrossBtn}
                                  />
                                  <AfterUpload
                                    name={uploadedFileName}
                                    size={uploadedFileSize}
                                    url={cv}
                                    show={showFileUploadSuccessPart}
                                    handleDelete={handleDeleteBtn}
                                    disbaleDeleteBtn={disableDeleteBtn}
                                  />
                                  <StartUpload
                                    name={uploadedFileName}
                                    size={uploadedFileSize}
                                    show={uploading}
                                    completed={uploadCompletePercent}
                                  />
                                </FileUploader>
                              </div>
                            </div>
                            <div className="col-12 col-lg-6 order-lg-1">
                              <div className="row g-4 g-md-6">
                                <div className="col-12">
                                  <label
                                    htmlFor="inputLinkedin"
                                    className="form-label"
                                  >
                                    Your LinkedIn profile
                                  </label>
                                  <input
                                    type="url"
                                    pattern="https://.*"
                                    className="form-control social-input linkedin"
                                    id="inputLinkedin"
                                    value={linkedinProfile}
                                    onChange={handleLinkedInProfile}
                                    autoComplete="off"
                                  />
                                </div>
                                <div className="col-12">
                                  <label
                                    htmlFor="inputGithub"
                                    className="form-label"
                                  >
                                    Your GitHub profile
                                  </label>
                                  <input
                                    type="url"
                                    pattern="https://.*"
                                    className="form-control social-input github"
                                    id="inputGithub"
                                    value={githubProfile}
                                    onChange={handleGithubProfile}
                                    autoComplete="off"
                                  />
                                </div>
                                <div className="col-12">
                                  <label
                                    htmlFor="inputWhatsapp"
                                    className="form-label"
                                  >
                                    Whatsapp number
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="inputWhatsapp"
                                    aria-describedby="inputWhatsappHelp"
                                    placeholder="e.g. 01700000000"
                                    value={whatsappNumber}
                                    onChange={handleWhatsappNumber}
                                    autoComplete="off"
                                  />
                                  <div
                                    id="inputWhatsappHelp"
                                    className="form-text"
                                  >
                                    This number will be used if we have job
                                    offer that matches your profile. You can
                                    delete it anytime.
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-12 order-lg-3" ref={commentRef}>
                              <label
                                htmlFor="inputWorkExperience"
                                className="form-label"
                              >
                                Comment
                              </label>
                              <textarea
                                value={comment}
                                onChange={handleComment}
                                className="form-control profile-page-input-error-field"
                                id="inputWorkExperience"
                              ></textarea>
                              <InputFieldErrorMessage
                                error={showCommentError}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/*  Others: Ends */}

                    {/* Save Button: Starts */}
                    <div className="row mt-6">
                      <div className="col-12 col-md-8 offset-md-4">
                        {showErrorMsg ? (
                          <span
                            style={{
                              padding: 10,
                              float: "left",
                              width: "100%",
                              fontSize: 11,
                              color: "red",
                            }}
                          >
                            {showErrorMsg}
                          </span>
                        ) : (
                          ""
                        )}
                        {showSuccessMsg ? (
                          <span
                            style={{
                              padding: 10,
                              float: "left",
                              width: "100%",
                              fontSize: 11,
                              color: "green",
                            }}
                          >
                            {showSuccessMsg}
                          </span>
                        ) : (
                          ""
                        )}

                        {enableSaveButton ? (
                          <button
                            className="btn btn-primary w-100 w-md-auto"
                            onClick={handleSave}
                            style={{ marginLeft: 10, marginTop: 10 }}
                            type="submit"
                          >
                            Save Changes
                          </button>
                        ) : (
                          <button
                            disabled
                            className="btn btn-primary w-100 w-md-auto"
                            style={{ marginLeft: 10, marginTop: 10 }}
                            type="submit"
                          >
                            Save Changes
                          </button>
                        )}
                      </div>
                    </div>
                    {/* Save Button: Ends */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateProfile;