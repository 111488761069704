import axios from 'axios';
import {AppStorageService} from "./AppStorageService";
class AxiosService {
    instance:any;
    constructor() {
        const instance:any = axios.create();
        instance.interceptors.response.use(this.handleSuccess, this.handleError);
        this.instance= instance;
    }

    handleSuccess(response:any) {
        return response;
    }


    handleError = (error:any) => {
        console.log(error)
        return Promise.reject(error)
    }

    redirectTo = (document:any, path:string) => {
        document.location = path
    }


    async get(url:string, params:any,type:any='unsecure') {
        let header = getAuthorizationHeader();
        if(type=='secure'){
            header = getAuthorizationHeader();
        }
        return await this.instance.get(url,header)
                .then((response:any)=>{
                    return (response.status, response.data)
                }).catch((error:any)=>{
                    console.log()
                // @ts-ignore
                return ('failed', {

                })
             });

    }

    async post(url:string, body:any, config:any,type:any='json') {
        let header =   getAuthorizationHeader(type);
        return await this.instance.post(url, body, header)
                .then((response:any)=>{
                    return (response.status, response.data)
                }).catch((error:any)=>{
                    let rsult = {};
                    if(error && error.response && error.response.data){
                        rsult = error.response.data;
                    }
                    // @ts-ignore
                    return ('failed', rsult)
                });


    }

    async put(url:string, body:any) { console.log("body",body)
        return await this.instance.put(url, body)
            .then((response:any)=>{
                return (response.status, response.data)
            }).catch((error:any)=>{
                // @ts-ignore
                return ('failed', {

                })
            });
    }

    async delete(url:string,body:any) {
        let header = getAuthorizationHeader();
        return await this.instance.delete(url,header)
            .then((response:any)=>{
                return (response.status, response.data)
            }).catch((error:any)=>{
                console.log()
                // @ts-ignore
                return ('failed', {

                })
            });
    }
}
function getCommonHeader(){
    return   {
        headers: {
            'Content-type':'application/json',
            'Access-Control-Allow-Origin':'*',
        }
    };
}

function getAuthorizationHeader(type:any='json'){
    let storage = new AppStorageService();
    let contentType = 'application/json';
    if(type=='file'){
        contentType = 'multipart/form-data'
    }
    return   {
        headers: {
            "content-type": contentType,
            'Access-Control-Allow-Origin':'*',
            'Cache-Control':'no-store,no-cache,must-revalidate',
            'Authorization':'Bearer '+storage.getToken()
        }
    };
}

export default new AxiosService();