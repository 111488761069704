import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import backgroundPattern from "../../../assets/images/background-pattern.svg";
import ExperienceEarningsChart from "../../../components/charts/ExperienceEarningsChart";
import MultipleSkillError from "../../../components/errors/MultipleSkillError";
import { AppStorageService } from "../../../services/AppStorageService";
import {
  getCandidateProfile,
  updateCandidatePreference,
} from "../../../services/CandidateService";
import {
  getChartDataByComparisonId,
  getUserPositionChart,
  prepareChart,
  shareToSocialMedia,
  storeSalaryFeedback,
  updateSalaryFeedback,
} from "../../../services/SalaryReportService";
import { getExtraSkill, getIndustries } from "../../../services/SkillService";
import { Candidate } from "../../../types/Candidate";
import { Industry } from "../../../types/IndustryClass";
import { ReactSelect } from "../../../types/ReactSelect";
import { SalaryFeedback } from "../../../types/SalaryFeedback";
import { SalaryReport as SalaryReportObject } from "../../../types/SalaryReport";
import { Skill } from "../../../types/Skill";
import {
  CommonErrorMsg,
  EmptyFeedbackErrorMsg,
} from "../../../utils/CommonConstants";
import {
  CANDIDATE_PROFILE_PAGE,
  HOME_PAGE,
} from "../../../utils/RouteNamingConfig";

const SalaryReport = () => {
  let storage = new AppStorageService();
  let candidateObject = new Candidate();
  let salaryFeedbackObject = new SalaryFeedback();
  let salaryReportFromStorage = storage.getSalaryReport();
  let salaryReportObject = new SalaryReportObject();
  let navigate = useNavigate();

  const [rangeLower, setRangeLower] = useState(0);
  const [rangeHigher, setRangeHiger] = useState(0);
  const [salaryReport, setSalaryReport] = useState(
    !salaryReportFromStorage ? salaryReportObject : salaryReportFromStorage
  );
  const [feedBack, setFeedBack] = useState("");
  const [showChart, setShowChart] = useState(false);
  const [chartData, setChartData] = useState({});
  const [chartUserData, setChartUserData] = useState({});
  const [showSalaryFeedBackModal, setShowSalaryFeedBackModal] = useState(false);
  const [showSalaryFeedBackMsg, setShowSalaryFeedBackMsg] = useState(
    storage.getFeedBackSubmitted()
  );
  const [errorMsg, setErrorMsg] = useState("");
  const [showPreferenceBtn, setShowPreferenceBtn] = useState(false);
  const [preferenceErrorMsg, setPreferenceErrorMsg] = useState("");
  const [industry, setIndustry] = useState([]);
  const [extraSkill, setExtraSkill] = useState([]);
  const [candidate, setCandidate] = useState(candidateObject);
  const [salaryFeedback, setSalaryFeedback] = useState(salaryFeedbackObject);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedIndustriesFromApi, setSelectedIndustriesFromApi] = useState<any>(
      []
  );
  const [selectedExtraSkill, setSelectedExtraSkill] = useState([]);
  const [selectedExtraSkillFromApi, setSelectedExtraSkillFromApi] = useState<any>(
      []
  );
  let color = "#eaecf0";
  if (showSalaryFeedBackMsg) {
    color = "#12B76A";
  }
  const [bgcolor, setBgColor] = useState(color);

  const click = (bgcolor: any) => {
    setBgColor(bgcolor);
  };

  let low = 0;
  let high = 0;

  let industrySalary =
    salaryReport.industry_salary ?? salaryReport.industry_salary;
  if (industrySalary) {
    low = parseInt(industrySalary.split(" - ")[0]);
    high = parseInt(industrySalary.split(" - ")[1]);
  }

  let getIndustryFromServer = async () => {
    let dataFromAPI = await getIndustries("");
    if (dataFromAPI && dataFromAPI.data) {
      let options: any = [];
      dataFromAPI.data.map((item: any) => {
        let itemArr = new ReactSelect();
        itemArr["value"] = item.id;
        itemArr["label"] = item.name;
        options.push(itemArr);
      });
      setIndustry(options);
    }
  };

  let getExtraSkillFromServer = async () => {
    let dataFromAPI = await getExtraSkill("");
    if (dataFromAPI && dataFromAPI.data) {
      let options: any = [];
      dataFromAPI.data.map((item: any) => {
        let itemArr = new ReactSelect();
        itemArr["value"] = item.id;
        itemArr["label"] = item.name;
        options.push(itemArr);
      });
      setExtraSkill(options);
    }
  };

  const getProfileData = async () => {
    const apiResult = await getCandidateProfile();
    if (apiResult && apiResult.data) {
      let profileData: Candidate = apiResult.data;
      setCandidate(profileData);
      setSelectedIndustriesFromApi(profileData.preferredIndustries);
      setSelectedExtraSkillFromApi(profileData.extraSkills);
    }
  };
  const getMainSalaryInfo = async () => {
    let dataFromApi = await getChartDataByComparisonId(
      salaryReportFromStorage.comparison_id
    );
    if (dataFromApi && dataFromApi.data) {
      let processedData = prepareChart(dataFromApi.data);
      let processedUserData = getUserPositionChart(
        processedData.processedExpericenPoint,
        salaryReportFromStorage.evaluated_experience,
        salaryReportFromStorage.salary
      );
      setShowChart(true);
      setChartData(processedData);
      setChartUserData(processedUserData);
    }
  };

  useEffect(() => {
    if (salaryReportFromStorage == null) {
      navigate(HOME_PAGE);
    }
    setRangeLower(low);
    setRangeHiger(high);
    if (salaryReportFromStorage && salaryReportFromStorage.comparison_id) {
      salaryFeedback.comparison_id = salaryReportFromStorage.comparison_id;
      setSalaryFeedback(salaryFeedback);
      getMainSalaryInfo();
    }
    getIndustryFromServer();
    getExtraSkillFromServer();
    getProfileData();
  }, []);

  useEffect(() => {
    checkIfEnableToSavePreference();
  }, [selectedIndustry, selectedExtraSkill]);

  useEffect(() => {
    const accurate = document.getElementById("accurate");
    // @ts-ignore
    accurate.style.backgroundColor = bgcolor;
  }, [bgcolor]);

  useEffect(() => {
    if (selectedIndustriesFromApi && selectedIndustriesFromApi.length > 0) {
      let industryData: any = [];
      selectedIndustriesFromApi.map((item: any) => {
        let tempArr: any = [];
        tempArr.value = item.id;
        tempArr.label = item.name;
        industryData.push(tempArr);
      });
      setSelectedIndustry(industryData);
    }
  }, [selectedIndustriesFromApi]);

  useEffect(() => {
    if (selectedExtraSkillFromApi && selectedExtraSkillFromApi.length > 0) {
      let extraSkillData: any = [];
      selectedExtraSkillFromApi.map((item: any) => {
        let tempArr: any = [];
        tempArr.value = item.id;
        tempArr.label = item.name;
        extraSkillData.push(tempArr);
      });
      setSelectedExtraSkill(extraSkillData);
    }
  }, [selectedExtraSkillFromApi]);

  const handleSocialMedia = (type: any) => {
    shareToSocialMedia(type);
  };

  const handleFeedback = (type: any) => {
    setPreferenceErrorMsg("");
    if (type == "accurate") {
      salaryFeedback.feedback_accuracy_id = 1;
      setSalaryFeedback(salaryFeedback);
      feedbackDone();
    } else {
      if (type == "high") {
        salaryFeedback.feedback_accuracy_id = 2;
      } else {
        salaryFeedback.feedback_accuracy_id = 3;
      }
      setSalaryFeedback(salaryFeedback);
      setShowSalaryFeedBackModal(true);
    }
    storeFeedBack();
  };

  const feedbackDone = () => {
    storage.setFeedBackSubmitted(true);
    setShowSalaryFeedBackMsg(true);
  };

  const handleModalClose = () => {
    setErrorMsg("");
    setShowSalaryFeedBackModal(false);
  };

  const storeFeedBack = () => {
    setErrorMsg("");
    let finalFeedbackObject = salaryFeedback;
    let responseFromApi = async () => {
      let responseFromApi = await storeSalaryFeedback(finalFeedbackObject);
      if (responseFromApi && responseFromApi.success) {
      } else {
      }
    };
    responseFromApi();
  };

  const handleSendFeedBack = () => {
    setErrorMsg("");
    setPreferenceErrorMsg("");
    let finalFeedbackObject = salaryFeedback;
    if (!feedBack) {
      setErrorMsg(EmptyFeedbackErrorMsg);
      return;
    }
    let responseFromApi = async () => {
      finalFeedbackObject.feedback = feedBack;
      let responseFromApi = await updateSalaryFeedback(finalFeedbackObject);
      if (responseFromApi && responseFromApi.success) {
        feedbackDone();
        setShowSalaryFeedBackModal(false);
        setBgColor("#12B76A");
      } else {
        setErrorMsg(CommonErrorMsg);
      }
    };
    responseFromApi();
  };

  const handleIndustry = (selectedData: any) => {
    let selectedIndustry: Industry[] = [];
    setSelectedIndustry(selectedData);
    if (selectedData && selectedData.length > 0) {
      selectedData.map((item: any) => {
        let tempItem = new Industry();
        tempItem["id"] = item.value;
        tempItem["name"] = item.label;
        selectedIndustry.push(tempItem);
      });
    } else {
      console.log("empty");
    }
    candidate.preferredIndustries = selectedIndustry;
    setCandidate(candidate);
  };

  const handleExtraSkill = (selectedData: any) => {
    let selectedExtraSkill: Skill[] = [];
    setSelectedExtraSkill(selectedData);
    if (selectedData && selectedData.length > 0) {
      selectedData.map((item: any) => {
        let tempItem = new Skill();
        tempItem["id"] = item.value;
        tempItem["name"] = item.label;
        selectedExtraSkill.push(tempItem);
      });
    } else {
      console.log("empty");
    }
    candidate.extraSkills = selectedExtraSkill;
    setCandidate(candidate);
  };

  const handleSavePreference = () => {
    setPreferenceErrorMsg("");
    setShowPreferenceBtn(false);
    let savePreference = async () => {
      let candidateData = candidate;
      let response = await updateCandidatePreference(candidateData);
      if (response.success) {
        setPreferenceErrorMsg("success");
        navigate(CANDIDATE_PROFILE_PAGE);
        return;
      } else {
        setPreferenceErrorMsg("error");
        setShowPreferenceBtn(true);
      }
    };

    savePreference();
  };

  const checkIfEnableToSavePreference = () => {
    if (selectedIndustry.length > 0 || selectedExtraSkill.length > 0) {
      setShowPreferenceBtn(true);
    } else {
      setShowPreferenceBtn(false);
    }
  };

  return (
    <>
      <section className="spacious-section bg-theme overflow-hidden position-relative pb-0">
        <div className="container position-relative z-2">
          <div className="row">
            <div className="col">
              <h2 className="text-start text-md-center text-white display-2 fw-semibold mb-6">
                {salaryReport && salaryReport.role ? salaryReport.role : ""}
              </h2>
              <p
                className="text-start text-md-center mb-7 pb-4 lead fw-normal"
                style={{ color: "#d0d5dd" }}
              >
                * Role is generated based on your selected skills
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-11 col-lg-10">
              <div className="rounded-4" id="accurate">
                <div className="shadow-sm rounded-3 bg-white p-7">
                  <div className="row text-center text-md-start">
                    <div className="col-12 col-md-5 mb-7 mb-md-0">
                      <p className="fs-5 mb-3">Average salary</p>
                      <h3 className="text-dark fw-bold display-4 mb-0">
                        {salaryReport.avg_salary.toLocaleString() + " "}
                        {/*{ salaryReport.salary | number : "1.0" : "en-us" }*/}
                        BDT
                        <span className="fw-normal fs-5 d-block d-md-inline-block">
                          / month
                        </span>
                      </h3>
                    </div>
                    <div className="col-12 col-md-7">
                      <p className="fs-5 mb-3">
                        Typical salary range for this position
                      </p>
                      <h3
                        className="fw-bold display-4 mb-0"
                        style={{ color: "#667085" }}
                      >
                        {rangeLower.toLocaleString() + " - "}
                        {rangeHigher.toLocaleString() + " "}
                        {/* {{ rangeLower | number : "1.0" : "en-us" }} -*/}
                        {/* {{ rangeHigher | number : "1.0" : "en-us" }} */}
                        BDT
                        <span className="fw-normal fs-5 d-block d-md-inline-block">
                          / month
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>

                {!showSalaryFeedBackMsg ? (
                  <div className="row justify-content-center align-items-center g-3 py-3">
                    <div className="col-auto">
                      <p className="mb-0 text-semidark">
                        How accurate does this salary look?
                      </p>
                    </div>

                    <div className="col-auto">
                      <div className="row gx-2 gx-md-3">
                        <div
                          className="col-auto"
                          onClick={() => click("#12B76A")}
                        >
                          <button
                            className="btn btn-sm bg-white border"
                            type="button"
                            onClick={(e) => handleFeedback("accurate")}
                          >
                            <img
                              src={
                                require("../../../assets/images/icons/check.svg")
                                  .default
                              }
                              className="img-fluid "
                              width="16"
                              alt=""
                            />
                            <span className="ps-3">Accurate</span>
                          </button>
                        </div>
                        <div className="col-auto">
                          <button
                            className="btn btn-sm bg-white border"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#inAccurateSalaryFeedbackModal"
                            onClick={(e) => handleFeedback("high")}
                          >
                            <img
                              src={
                                require("../../../assets/images/icons/arrow-narrow-up.svg")
                                  .default
                              }
                              className="img-fluid"
                              width="16"
                              alt=""
                            />
                            <span className="ps-3">High</span>
                          </button>
                        </div>
                        <div className="col-auto">
                          <button
                            className="btn btn-sm bg-white border"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#inAccurateSalaryFeedbackModal"
                            onClick={(e) => handleFeedback("low")}
                          >
                            <img
                              src={
                                require("../../../assets/images/icons/arrow-narrow-down.svg")
                                  .default
                              }
                              className="img-fluid"
                              width="16"
                              alt=""
                            />
                            <span className="ps-3">Low</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {showSalaryFeedBackMsg ? (
                  // <div className="d-flex justify-content-center align-items-center py-4">
                  //   <p className="d-inline-block mb-0 text-white">
                  //     🙏 Thanks for your feedback! It will help us improve
                  //     report accuracy even more.
                  //   </p>
                  // </div>
                  <div className="py-4 px-1">
                    <p className="mb-0 text-white text-center">
                      🙏 Thanks for your feedback! It will help us improve
                      report accuracy even more.
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white position-absolute start-0 end-0 bottom-0 z-0 background-pattern">
          <img
            style={{ width: "100%" }}
            src={backgroundPattern}
            className="object-fit-cover"
            alt=""
          />
        </div>
      </section>

      <Modal
        show={showSalaryFeedBackModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="inAccurateSalaryFeedbackModal"
      >
        <Modal.Header
          closeButton
          className="border-0"
          onHide={handleModalClose}
        >
          <div>
            <h6 className="text-dark mb-0 display-6 fw-semibold pb-1">
              Why do you feel this is not accurate?{" "}
            </h6>
            <p className="small mb-0">
              Your feedback will help us improve the report
            </p>
          </div>
        </Modal.Header>
        <Modal.Body className="px-6 py-0">
          <form>
            <div>
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label d-none"
              >
                Example textarea
              </label>
              <textarea
                defaultValue={feedBack}
                onChange={(e) => setFeedBack(e.target.value)}
                rows={4}
                className="form-control"
                placeholder="Salary feels inaccurate because ..."
                id="exampleFormControlTextarea1"
              ></textarea>
            </div>
          </form>
          {errorMsg ? <MultipleSkillError error={errorMsg} /> : ""}
        </Modal.Body>
        <Modal.Footer className="border-0 px-6 py-6">
          <button
            onClick={handleSendFeedBack}
            type="button"
            className="btn btn-primary order-md-2 w-100 w-md-auto"
          >
            Submit feedback
          </button>
          <button
            onClick={handleModalClose}
            type="button"
            className="btn text-semidark order-md-1 w-100 w-md-auto"
            style={{ border: "1px solid #D0D5DD" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <section className="spacious-section pb-0">
        <div className="container">
          {showChart ? (
            <ExperienceEarningsChart
              processedData={chartData}
              processedUserData={chartUserData}
            />
          ) : (
            ""
          )}
          <div
            className="row row-cols-1 row-cols-md-2 g-6"
            style={{ textAlign: "center", alignContent: "center" }}
          ></div>

          <div className="row justify-content-center align-items-center g-6 spacious-section">
            <div className="col-auto">
              <h2 className="mb-0">Share the report on</h2>
            </div>
            <div className="col-auto">
              <div className="row justify-content-start align-items-center gx-4 gx-md-3 text-white lead">
                <div className="col-auto">
                  <div
                    className="d-flex justify-content-center align-items-center rounded-2 cursor-pointer"
                    style={{
                      width: 56,
                      height: 56,
                      backgroundColor: "#667085",
                    }}
                    onClick={(e) => handleSocialMedia("email")}
                  >
                    <i className="bi bi-envelope"></i>
                  </div>
                </div>

                <div className="col-auto">
                  <div
                    className="d-flex justify-content-center align-items-center rounded-2 cursor-pointer"
                    style={{
                      width: 56,
                      height: 56,
                      backgroundColor: "#1da1f2",
                    }}
                    onClick={(e) => handleSocialMedia("twitter")}
                  >
                    <i className="bi bi-twitter"></i>
                  </div>
                </div>

                <div className="col-auto">
                  <div
                    className="d-flex justify-content-center align-items-center rounded-2 cursor-pointer"
                    style={{
                      width: 56,
                      height: 56,
                      backgroundColor: "#4267b2",
                    }}
                    onClick={(e) => handleSocialMedia("facebook")}
                  >
                    <i className="bi bi-facebook"></i>
                  </div>
                </div>

                <div className="col-auto">
                  <div
                    className="d-flex justify-content-center align-items-center rounded-2 cursor-pointer"
                    style={{
                      width: 56,
                      height: 56,
                      backgroundColor: "#0077b5",
                    }}
                    onClick={(e) => handleSocialMedia("linkedin")}
                  >
                    <i className="bi bi-linkedin"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="spacious-section">
        <div className="container">
          <div className="report-cta bg-theme rounded-5 px-6 p-md-7">
            <div className="row">
              <div className="col-lg">
                <div className="px-0 pt-7 p-md-7 mt-4 mt-md-0">
                  <h2 className="text-white">
                    Would you like to be contacted with new job offers?
                  </h2>
                  <p className="lead fw-normal" style={{ color: "#e9d7fe" }}>
                    Benefit from discovering more opportunities!
                  </p>
                  <form>
                    <div className="row g-6">
                      <div className="col-12">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          name="experience"
                          options={industry}
                          placeholder={<>Industry (Select one or more)</>}
                          onChange={handleIndustry}
                          defaultValue={selectedIndustry}
                          value={selectedIndustry}
                          isMulti={true}
                        />
                      </div>

                      <div className="col-12">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          name="extraSkill"
                          options={extraSkill}
                          placeholder={<>Extra skills (Select one or more)</>}
                          onChange={handleExtraSkill}
                          defaultValue={selectedExtraSkill}
                          value={selectedExtraSkill}
                          isMulti={true}
                        />
                      </div>

                      <div className="row">
                        <div className="col-12 col-md-8 offset-md-4">
                          {preferenceErrorMsg == "error" ? (
                            <span
                              style={{
                                padding: 10,
                                float: "left",
                                width: "100%",
                                fontSize: 11,
                                color: "red",
                              }}
                            >
                              {CommonErrorMsg}
                            </span>
                          ) : (
                            ""
                          )}
                          {preferenceErrorMsg == "success" ? (
                            <span
                              style={{
                                padding: 10,
                                float: "left",
                                width: "100%",
                                fontSize: 11,
                                color: "green",
                              }}
                            >
                              Successfully Saved!
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="col-12">
                        {showPreferenceBtn ? (
                          <button
                            onClick={handleSavePreference}
                            className="btn btn-primary w-100"
                            type="button"
                          >
                            Save preferences
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary w-100"
                            type="button"
                            disabled
                          >
                            Save preferences
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg">
                <div className="px-0 py-7 p-md-7 my-5 my-md-0">
                  <img
                    src={require("../../../assets/images/report-cta-1.png")}
                    className="img-fluid"
                    alt=""
                    width="522"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SalaryReport;
