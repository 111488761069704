import { create } from "zustand";

export const useCandidateDetails = create((set) => {
    return {
        paymentStatus: '',
        showPaymentModal: false,
        isHired: false,
        setPaymentStatus: (status:boolean) => set((state:any) => ({ paymentStatus: status})),
        setShowPaymentModal: (status:boolean) => set((state:any) => ({ showPaymentModal: status})),
        setIsHired: (status:boolean) => set((state:any) => ({ isHired: status})),
    };
});