import AllCadidates from "./AllCadidate";
import Pagenation from "./Pagenation";
import SavedCandidate from "./SavedCandidate";
import NoCandidateFound from "./NoCandidateFound";
import SavedCandidateWrapper from "./SavedCandidateWrapper";

const SearchTabContent = (props:any) => {
    let tabObject = props.tabObject;
    let candidateObject = props.candidateObject;
    let candidates = candidateObject.allDataFromApi.data;
    let totalCandidate = candidateObject.totalCandidate;
    let isResultLoaded = candidateObject.isResultLoaded;
    let activeClass = "tab-pane fade show active";
    let inActiveClass = "tab-pane fade";

  return (
    <div className="tab-content" id="nav-tabContent">
      <div
          className={tabObject.showAllCandidateTab ? activeClass:inActiveClass}
        id="nav-home"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
        tabIndex={0}
      >
        <div className="py-6">
          <div className="row gy-4 gy-md-3">
              {candidates && candidates.map((item:any,index:any) => (
                  <AllCadidates candidate={item} key={index}/>
              ))}
              {(!totalCandidate && isResultLoaded) ?
                  <NoCandidateFound />:''
              }
          </div>
        </div>
      </div>
      <div
          className={!tabObject.showAllCandidateTab ? activeClass:inActiveClass}
        id="nav-profile"
        role="tabpanel"
        aria-labelledby="nav-profile-tab"
        tabIndex={0}
      >
        <div className="py-6">
          <div className="row gy-4 gy-md-3">
              <SavedCandidateWrapper candidateObject={candidateObject}/>
          </div>
        </div>
      </div>
      <Pagenation candidateObject={candidateObject}/>
    </div>
  );
};

export default SearchTabContent;
