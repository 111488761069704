
type props= {
    type:string,
}
const RecruiterStatusHeading = (props:props) => {
    let type = props.type;
    return (
        <>
            {type=='new' ?
                <span className="small fw-semibold px-3 py-1 incompleteProfile d-none d-md-inline-block mb-1">
                  New
                </span>
            :''
            }
            {type=='under_review' ?
                <span className="small fw-semibold px-3 py-1 profile-review d-none d-md-inline-block mb-1">
                  Under Review
                </span>
                :''
            }
            {type=='rejected' ?
                <span className="small fw-semibold px-3 py-1 profile-reject d-none d-md-inline-block mb-1">
                  Rejected
                </span>
                :''
            }

        </>
    );
}

export default RecruiterStatusHeading;