import payment from "../../../../assets/images/icons/payment.svg"
import PaymentStatus from "./PaymentStatus";
import CandidateExtraDetails from "./CandidateExtraDetails";
import React from "react";
import {useCandidateDetails} from "../../../../context/useCandidateDetails";

const HiredCandidatePaymentStatus = (props:any) => {
  let extraDetails = props.extraDetails;
  let detailsFunctionalities = props.detailsFunctionalities;
  let paymentStatus = props.paymentStatus;
  const setShowPaymentModal = useCandidateDetails((state:any)=>state.setShowPaymentModal);

  const handleShowPaymentModal = () => {
    setShowPaymentModal(true);
  }
  return (
    <div
      className="p-6 rounded-2"
      style={{ backgroundColor: "#ECFDF3", border: "1px solid #039855" }}
    >
      <div className="row gy-4 pb-3">
        <div className="col-12">
            <PaymentStatus paymentStatus={paymentStatus}/>
        </div>
        <div className="col-12">
          <p
            className="mb-0 common-text-size fw-semibold"
            style={{ color: "#054f31" }}
          >
            🎉 You’ve hired this candidate
          </p>
        </div>
        <CandidateExtraDetails extraDetails={extraDetails}/>
      </div>
      <div className="pt-4 d-flex flex-column gap-3">
        {paymentStatus == 'unpaid' ?
            <button className="btn w-100 greenBtn" onClick={handleShowPaymentModal}>
              <img src={payment} alt="" className="img-fluid"/>
              <span className="ps-2">Pay</span>
            </button>
            : ''
        }
        <button className="btn w-100 whiteBtn">Go to the conversation</button>
      </div>
    </div>
  );
};

export default HiredCandidatePaymentStatus;
