import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import NextButton from "../../../components/buttons/NextButton";
import MultipleSkillError from "../../../components/errors/MultipleSkillError";
import MultipleSkillsPageHeading from "../../../components/headings/MultipleSkillsPageHeading";
import ProgressBar from "../../../components/progress-bar/ProgressBar";
import { AppStorageService } from "../../../services/AppStorageService";
import {
  checkForValidExperience,
  disableSelectedSkills,
  getAllSkills,
  getExperiencesBySkillId,
} from "../../../services/SkillService";
import { Skill } from "../../../types/Skill";
import {
  GoogleLoginSignInMsg,
  NumberOfPrimarySkills,
} from "../../../utils/CommonConstants";
import {
  HOME_PAGE,
  OTHER_DETAILS_PAGE,
} from "../../../utils/RouteNamingConfig";
import useMultipleSkill from "./useMultipleSkill";
import {log10} from "chart.js/helpers";


const MultipleSkills = () => {
  const {loginErrorMsg,setLoginErrorMsg,googleLoginButtonLoad} = useMultipleSkill();
  let title = " Let’s start with your expertise";
  let subTitle =
    "Primary skill is the number one skill you have & others are secondary skills";
  let classWithError = "col-12 col-md-8 select__control_error";
  let classWithoutError = "col-12 col-md-8";

  let expClassWithError = "col-12 col-md-4 select__control_error";
  let expClassWithoutError = "col-12 col-md-4";

  let storage = new AppStorageService();
  let navigate = useNavigate();

  let allSkillsFromStorage = storage.getAllSkills();
  let allSelectedSkillsFromStorage = storage.getSelectedSkills();
  let allSelectedExperienceFromStorage = storage.getSelectedExperience();
  let allSelectedCurrentlyWorkingFromStorage = storage.getCurrentlyWorking();
  let userDetails = storage.getUserDetails();
  let userName = userDetails.name ? userDetails.name : "";

  let initialFormValue: any = [];

  if (allSelectedSkillsFromStorage && allSelectedSkillsFromStorage.length > 0) {
    let counter = 0;
    allSelectedSkillsFromStorage.forEach((item) => {
      let arr: any = [];
      arr["skill"] = item;
      arr["currently_working"] = allSelectedCurrentlyWorkingFromStorage[counter]
        ? allSelectedCurrentlyWorkingFromStorage[counter]
        : false;
      arr["experience"] = allSelectedExperienceFromStorage[counter]
        ? allSelectedExperienceFromStorage[counter]
        : null;
      initialFormValue.push(arr);
      counter++;
    });
  }
  const skillRef: any = useRef([]);
  const experienceRef: any = useRef([]);
  const [formValues, setFormValues] = useState(initialFormValue);
  const [userLogInStatus, setUserLogInStatus] = useState(
    storage.userLoggedIn()
  );
  const [userLogInMsg, setUserLogInMsg] = useState(storage.loginWelcomeMessage);
  const [isTermsChecked, setTermsChecked] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [skills, setSkills] = useState<any[]>(allSkillsFromStorage);
  const [experiences, setExperience] = useState<any[]>([]);

  const [selectedSkills, setSelectedSkills] = useState(
    allSelectedSkillsFromStorage
  );
  const [availableSkillNumber, setAvailableSkillNumber] = useState(
    5 - selectedSkills.length
  );
  const [selectedExperiences, setSelectedExperiences] = useState(
    allSelectedExperienceFromStorage
  );
  const [showAddMoreOption, setShowAddMoreOption] = useState(true);

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(false);

  const getSkills = async () => {
    const skillsFromServer = await getAllSkills();
    let skillOptions: any = [];
    if (skillsFromServer && skillsFromServer.data) {
      skillsFromServer.data.map((item: any) => {
        let temp: any = {};
        temp.value = item.id;
        temp.label = item.name;
        skillOptions.push(temp);
      });
      storage.storeAllSkills(skillOptions);
      setSkills(skillOptions);
    } else {
      console.log("No skills found from server");
    }
  };

  useEffect(() => {
    if (
      !allSelectedSkillsFromStorage ||
      allSelectedSkillsFromStorage.length <= 0
    ) {
      navigate(HOME_PAGE);
    }
    setFormValues(initialFormValue);
    storage.setLoginWelcomeMessage(false);
    googleLoginButtonLoad();

    if (allSkillsFromStorage && allSkillsFromStorage.length > 0) {
      console.log("storage");
    } else {
      getSkills();
    }
  }, []);

  useEffect(() => {
    let experienceList: any = [];
    if (selectedSkills && selectedSkills.length > 0) {
      selectedSkills.map((item: Skill) => {
        experienceList.push(getExperiencesBySkillId(item.value));
      });
    }
    setExperience(experienceList);
    if (skills && skills.length > 0 && selectedSkills) {
      setSkills(disableSelectedSkills(skills, selectedSkills));
    }
  }, [selectedSkills]);

  useEffect(() => {
    if (formValues.length > 0 && experiences.length > 0) {
      formValues.map((item: any, index: any) => {
        if (item.experience && experiences[index]) {
          if (checkForValidExperience(item.experience, experiences[index])) {
          } else {
            let newFormValues = [...formValues];
            newFormValues[index].experience = null;
            setFormValues(newFormValues);
            let experienceListArr = allSelectedExperienceFromStorage;
            // @ts-ignore
            experienceListArr[index] = {};
            storage.storeSelectedExperience(experienceListArr);
          }
        }
      });
    }
  }, [experiences]);

  useEffect(()=>{
    setErrorMsg(loginErrorMsg);
  },[loginErrorMsg])

  const handleGoogleBtn = (click: any) => {
    if (!isTermsChecked) {
      setErrorMsg("Please agree to the Terms and Conditions");
    }
  };

  const handleCheckClick = (event: any) => {
    if (event.target.checked) {
      setTermsChecked(true);
      setErrorMsg("");
    } else {
      setTermsChecked(false);
    }
  };

  let addFormFields = () => {
    let ifContainsEmptyValue = false;
    formValues.map((item: any, index: any) => {
      if (!item.skill || !item.experience) {
        ifContainsEmptyValue = true;
        return;
      }
    });

    if (availableSkillNumber <= 0 || ifContainsEmptyValue) {
      return false;
    }
    setAvailableSkillNumber(availableSkillNumber - 1);
    //@ts-ignore
    setFormValues([
      ...formValues,
      { skill: null, experience: null, currently_working: false },
    ]);

    if (availableSkillNumber <= 1) {
      setShowAddMoreOption(false);
    }
  };

  let removeFormFields = (i: any) => {
    setAvailableSkillNumber(availableSkillNumber + 1);
    setShowAddMoreOption(true);

    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    storage.clearSpecificSelectedSRow(i);
    setFormValues(newFormValues);

    let newExperiences = [...experiences];
    newExperiences.splice(i, 1);
    setExperience(newExperiences);
  };

  const handlePrimarySkill: any = (index: any, skillData: any) => {
    if (skillRef && skillRef.current[index]) {
      skillRef.current[index].className = classWithoutError;
    }

    let newFormValues: any = [...formValues];
    newFormValues[index]["skill"] = skillData;
    setFormValues(newFormValues);
    let skillsArr: Skill[] = allSelectedSkillsFromStorage;
    if (skillData) {
      skillsArr[index] = skillData;
      storage.storeSelectedSkills(skillsArr);
    } else {
      skillsArr.splice(index, 1);
      storage.storeSelectedSkills(skillsArr);
    }
    setSelectedSkills(skillsArr);
  };

  const handleExperience: any = (index: any, experienceData: any) => {
    if (experienceRef && experienceRef.current[index]) {
      experienceRef.current[index].className = expClassWithoutError;
    }
    let newFormValues: any = [...formValues];
    newFormValues[index]["experience"] = experienceData;
    setFormValues(newFormValues);

    let experienceListArr = allSelectedExperienceFromStorage;
    if (experienceData) {
      experienceListArr[index] = experienceData;
      storage.storeSelectedExperience(experienceListArr);
    } else {
      //storage.clearSpecificSelectedExperience(index);
    }

    setSelectedExperiences(experienceData);
  };

  const handleCurrentlyWorking = (index: any, event: any) => {
    let newFormValues: any = [...formValues];
    newFormValues[index]["currently_working"] = event.target.checked;
    setFormValues(newFormValues);
    storage.storeCurrentlyWorking(index, event.target.checked);
  };

  const checkIfEnableToProceed = (status: string = "", data: any = []) => {
    let isAllFieldsFilledUp = true;
    formValues.map((item: any, index: any) => {
      if (!item.skill || !item.experience) {
        isAllFieldsFilledUp = false;

        if (!item.skill) {
          if (skillRef && skillRef.current[index]) {
            skillRef.current[index].className = classWithError;
          }
        }
        if (!item.experience) {
          if (experienceRef && experienceRef.current[index]) {
            experienceRef.current[index].className = expClassWithError;
          }
        }
      }
    });

    if (userLogInStatus && isAllFieldsFilledUp) {
      return true;
    }
    return false;
  };

  const handleNextButtonAction = () => {
    if (!userLogInStatus) {
      setErrorMsg(GoogleLoginSignInMsg);
    }
    if (checkIfEnableToProceed()) {
      navigate(OTHER_DETAILS_PAGE);
    }
  };

  return (
    <>
      <div className="container position-relative z-2">
        <div className="row">
          <MultipleSkillsPageHeading title={title} description={subTitle} />
        </div>
        <div className="row justify-content-center mb-7 pb-7">
          <div className="col-12 col-md-10 col-lg-5">
            <div className="shadow rounded-3 bg-white p-7 border border-2 border-primary">
              <ProgressBar step={"first"} />

              <form>
                {formValues.map((element: any, index: any) => (
                  <div className="form-inline" key={index}>
                    <div>
                      <div className="row g-3 mb-6">
                        <div
                          className="col-12 col-md-8"
                          ref={(el) => (skillRef.current[index] = el)}
                        >
                          <label htmlFor="primarySkill" className="form-label">
                            Primary Skill {NumberOfPrimarySkills[index]}
                          </label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={isClearable}
                            isSearchable={isSearchable}
                            name="skill"
                            options={skills}
                            defaultValue={element.skill}
                            value={element.skill}
                            placeholder={<>Select Skills</>}
                            onChange={(e) => handlePrimarySkill(index, e)}
                          />
                          <span className="multiple-skill-error small pt-1 error-text">
                            This is required
                          </span>
                        </div>
                        <div
                          className="col-12 col-md-4"
                          ref={(el) => (experienceRef.current[index] = el)}
                        >
                          <label htmlFor="experience" className="form-label">
                            Experience (yrs)
                          </label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={isClearable}
                            isSearchable={isSearchable}
                            name="experience"
                            options={experiences[index]}
                            placeholder={<>e.g. 3</>}
                            onChange={(e) => handleExperience(index, e)}
                            defaultValue={element.experience}
                            value={element.experience}
                          />
                          <span className="multiple-skill-error small pt-1 error-text">
                            This is required
                          </span>
                        </div>
                        <div className="col-12">
                          <div className="row justify-content-between align-items-center">
                            <div className="col">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="currentlyWorking{{ i }}"
                                  checked={element.currently_working}
                                  onChange={(e) =>
                                    handleCurrentlyWorking(index, e)
                                  }
                                />
                                <label className="form-check-label small">
                                  Currently working
                                </label>
                              </div>
                            </div>
                            {index ? (
                              <div
                                className="col-auto"
                                onClick={() => removeFormFields(index)}
                              >
                                <p className="mb-0 text-danger fw-semibold small cursor-pointer">
                                  - Remove skill
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="row justify-content-between align-items-center small mb-6">
                  {showAddMoreOption ? (
                    <>
                      <div className="col-auto" onClick={() => addFormFields()}>
                        <p className="mb-0 text-theme fw-semibold cursor-pointer">
                          + Add more skills
                        </p>
                      </div>
                      <div className="col-auto">
                        <p
                          className="mb-0"
                          style={{ float: "right", color: "#667085" }}
                        >
                          * {availableSkillNumber} more can be added
                        </p>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </form>
              {!userLogInStatus ? (
                <div className="row mb-6">
                  <div className="col">
                    <div className="bg-light rounded-2 p-4">
                      <form>
                        <div className="form-check mb-4">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            onChange={(e) => handleCheckClick(e)}
                          />
                          <label
                            className="form-check-label small"
                            htmlFor="flexCheckDefault"
                          >
                            I agree to the
                            <Link to={"/terms"} target="_blank">
                              {" "}
                              <span className="border-bottom">
                                terms and conditions
                              </span>
                            </Link>
                          </label>
                        </div>
                      </form>

                      <div
                        id="googleLoaderButton"
                        className=""
                        style={{ position: "relative", display: "none" }}
                      >
                        <a
                          className="btn w-100"
                          style={{
                            color: "#334054",
                            backgroundColor: "#ffffff",
                            border: "1px solid #d0d5de",
                          }}
                        >
                          <img
                            style={{ marginRight: 6 }}
                            src={require("../../../assets/images/icons/loading.png")}
                            className="spinner"
                            width="28"
                            alt=""
                          />
                          Please wait ...
                        </a>
                      </div>

                      <div
                        id="googleLoginBtnDiv"
                        className=""
                        style={{ position: "relative" }}
                      >
                        <a
                          onClick={handleGoogleBtn}
                          className="btn w-100"
                          style={{
                            color: "#334054",
                            backgroundColor: "#ffffff",
                            border: "1px solid #d0d5de",
                          }}
                        >
                          <img
                            style={{ marginRight: 6 }}
                            src={
                              require("../../../assets/images/icons/google_icon.svg")
                                .default
                            }
                            className="img-fluid"
                            width="28"
                            alt=""
                          />
                          Sign up with Google
                        </a>
                        <button
                          disabled={!isTermsChecked}
                          className="btn btn-secondary w-100"
                          id="googleLoginBtn"
                          style={{
                            padding: 0,
                            border: "1px solid #d0d5dd",
                            textAlign: "center",
                            margin: "0 auto",
                            background: "#ffffff",
                            position: "absolute",
                            left: 0,
                            opacity: 0,
                          }}
                        >
                          Sign up with Google
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {userLogInMsg && userLogInStatus ? (
                <div className="row">
                  <div className="col">
                    <div
                      className="p-4 mb-6 rounded"
                      style={{ background: "#f9fafb" }}
                    >
                      <p
                        className="text-center mb-0"
                        style={{ color: "#027a48" }}
                      >
                        🎉 Hello <b>{userName}</b>, Thanks for signing up
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row">
                <div className="col px-0">
                  {errorMsg ? <MultipleSkillError error={errorMsg} /> : ""}
                  <div className="px-4">
                    <NextButton
                      onClick={handleNextButtonAction}
                      text={"Next"}
                    />
                    <p
                      className="small text-center mt-3 mb-0"
                      style={{ color: "#667085" }}
                    >
                      Report accuracy will be higher if more info is provided
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultipleSkills;
