import {useEffect, useState} from "react";
import {CredentialResponse} from "google-one-tap";
import {LoginService} from "../../../services/LoginService";
import {AppStorageService} from "../../../services/AppStorageService";
import {CommonErrorMsg} from "../../../utils/CommonConstants";
import {prepareAllErrorMsg} from "../../../services/RecruiterAuthenticationService";

export const useRecruiterHeader = () => {
    const [loginErrorMsg,setLoginErrorMsg] = useState('');
    const [swalProps, setSwalProps] = useState({});
    const logingLodder: boolean = false;
    const test = true;

    const loginService = new LoginService();
    let storage = new AppStorageService();

    const renderGoogleLoginButtonAuto = (id:string) => {
        // @ts-ignore
        window.onGoogleLibraryLoad = () => {
            // @ts-ignore
            google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_TOKEN
                    ? process.env.REACT_APP_GOOGLE_TOKEN
                    : "",
                callback: handleCredentialResponse.bind(this),
                auto_select: false,
                cancel_on_tap_outside: true,
            });
            // @ts-ignore
            google.accounts.id.renderButton(
                // @ts-ignore
                document.getElementById(id),
                { theme: "outline", size: "large" }
            );
        };
    };

    const renderGoogleLoginButtonOnClick = (id:string) => {
        // @ts-ignore
        google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_TOKEN
                ? process.env.REACT_APP_GOOGLE_TOKEN
                : "",
            callback: handleCredentialResponse.bind(this),
            auto_select: false,
            cancel_on_tap_outside: true,
        });
        // @ts-ignore
        google.accounts.id.renderButton(
            // @ts-ignore
            document.getElementById(id),
            { type: "standard", theme: "filled_blue", size: "large", width:'1000px' }
        );
    }

    function handleCredentialResponse(response: CredentialResponse) {
        //console.log(response);
        userLoggedIn(response);
    }

    async function userLoggedIn(response: CredentialResponse) {
        let user = loginService.parseJwt(response.credential);
        let result = await fetchValidatedGoogleLogin(response.credential);
        if (result === "success") {
            user = loginService.parseJwt(response.credential);
            storage.setGoogleToken(response.credential);
            storage.storeUserDetails(user);
            storage.setUserLoggedIn(true);
            //storage.setLoginWelcomeMessage(true);
            window.location.reload();
        } else {
            console.log("Google Login Failed");
        }
    }

    const fetchValidatedGoogleLogin = async (token: string) => {
        let response = await loginService.validateGoogleToken(token);
        if (response && response.success && response.data && response.data.token) {
            storage.setToken(response.data.token);
            return "success";
        } else {
            let errorText = prepareAllErrorMsg(response);
            console.log(errorText);
            console.log("here");
            setSwalProps({
                show: true,
                icon: 'error',
                title: 'Oops!',
                text: errorText,
            });
            return "failed";
        }
    };

    return {loginErrorMsg,setLoginErrorMsg,renderGoogleLoginButtonAuto,renderGoogleLoginButtonOnClick,swalProps,setSwalProps};
}