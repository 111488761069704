import React, {useEffect, useRef, useState} from "react";
import WhiteButton from "../../../../components/buttons/WhiteButton";
import {CandidateSearch} from "../../../../types/CandidateSearch";
import Select from "react-select";
import {AppStorageService} from "../../../../services/AppStorageService";
import MultiRangeSlider from "multi-range-slider-react";
import InputFieldErrorMessage from "../../../../components/errors/InputFieldErrorMessage";
import {FromSalaryBiggerError, SalaryLengthError} from "../../../../utils/CommonConstants";
const { forwardRef, useImperativeHandle } = React;

const FilterOption = forwardRef((props:any,ref:any) => {
  let storage = new AppStorageService();
  let filterObject = props.filterObject;
  let englishLevels = filterObject.englishLevels;
  let extraSkill = filterObject.extraSkill;
  let skills = filterObject.skills;
  let isFilterDataLoading = filterObject.isFilterDataLoading;
  let searchObject = new CandidateSearch();
  const [search,setSearch] = useState<any>(searchObject);
  const [expectedSalaryFrom,setExpectedSalaryFrom] = useState('');
  const [expectedSalaryTo,setExpectedSalaryTo] = useState('');
  const [expectedSalaryFromError,setExpectedSalaryFromError] = useState('');
  const [expectedSalaryToError,setExpectedSalaryToError] = useState('');
  const [isInvalidSalary,setIsInvalidSalary] = useState(false);
  const [englishLevelId,setEnglishLevelId] = useState(null);
  const [disableFilter,setDisableFilter] = useState(true);
  const [keywords,setKeywords] = useState("");
  const [selectedExtraSkills,setSelectedExtraSkills] = useState<any>([]);
  const [selectedEnglishLevel,setSelectedEnglishLevel] = useState<any>(null);
  const [selectedMainPrimarySkill,setSelectedMainPrimarySkill] = useState<any>(null);
  const [formValues, setFormValues] = useState<any>([]);
  const [availableSkillNumber, setAvailableSkillNumber] = useState(4);
  const [showAddSkill, setShowAddSkill] = useState(false);

  let activeTab = "nav-link active border-top border-bottom border-start px-4 py-2";
  let inActiveTab = "nav-link border-top border-bottom border-start px-4 py-2";
  let salaryErrorClass = "form-control error-input";
  let salaryWithOutErrorClass = "form-control";
  //for primary range
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(10);
  const [minValue2, setMinValue2] = useState(0);
  const [maxValue2, setMaxValue2] = useState(0);

  let andRef = useRef<any>([]);
  let orRef = useRef<any>([]);
  let expectedSalaryFromRef = useRef<any>(null);

  useEffect(()=>{
    handlePreviousFilterData();
  },[]);



  useImperativeHandle(ref, () => ({
    callResetFilter() {
      resetFilter();
    }
  }));

  const handlePreviousFilterData = (updatedData:any=null) => {

    setExpectedSalaryToError('');
    setExpectedSalaryFromError('');
    setIsInvalidSalary(false);
    
    let filterData = storage.getAllSelectedFilterData();
    let updatedSearch = updatedData;
    if(!updatedData) {
       updatedSearch = {...search};
    }


    if(filterData && filterData.salaryExpectationFrom){
      setExpectedSalaryFrom(filterData.salaryExpectationFrom);
      updatedSearch.salaryExpectationFrom = filterData.salaryExpectationFrom ;
    }else{
      setExpectedSalaryFrom('');
    }


    if(filterData && filterData.salaryExpectationTo){
      setExpectedSalaryTo(filterData.salaryExpectationTo);
      updatedSearch.salaryExpectationTo = filterData.salaryExpectationTo ;
    }else{
      setExpectedSalaryTo('');
    }

    if(filterData && filterData.selectedEnglishLevel){
      setSelectedEnglishLevel(filterData.selectedEnglishLevel);
      updatedSearch.selectedEnglishLevel = filterData.selectedEnglishLevel ;
      updatedSearch.minimumEnglishLevel = filterData.selectedEnglishLevel?.value ?? "" ;
    }else{
      setSelectedEnglishLevel(null);
    }


    if(filterData && filterData.extraSkills){
      setSelectedExtraSkills(filterData.extraSkills)
      updatedSearch.extraSkills = filterData.extraSkills ;
    }else{
      setSelectedExtraSkills([]);
    }

    if(filterData && filterData.keywords){
      setKeywords(filterData.keywords)
      updatedSearch.keywords = filterData.keywords ;
    }else{
      setKeywords('');
    }

    if(filterData){
      setSelectedMainPrimarySkill(filterData.mainPrimarySkill ?? null)
      setMinValue(filterData?.mainPrimarySkill?.min ?? 0)
      setMaxValue(filterData?.mainPrimarySkill?.max ?? 10)
      updatedSearch.mainPrimarySkill = filterData.mainPrimarySkill  ?? null;
      if(filterData.mainPrimarySkill) {
        setShowAddSkill(true);
      }
    }else{
      setSelectedMainPrimarySkill( null)
      setMinValue( 0)
      setMaxValue(10)
      setShowAddSkill(false);
    }

    if(filterData && filterData.otherPrimarySkill && filterData?.otherPrimarySkill.length>0) {
      setFormValues(filterData.otherPrimarySkill);
      updatedSearch.otherPrimarySkill = filterData.otherPrimarySkill ?? [];
      setAvailableSkillNumber(availableSkillNumber-filterData.otherPrimarySkill.length);
    }else{
      setFormValues([]);
      setAvailableSkillNumber(availableSkillNumber);
    }

    setSearch(updatedSearch);
    let shouldEnable = checkProperties(updatedSearch);
    setDisableFilter(!shouldEnable);
  }

  function checkProperties(obj:any) {
    for (var key in obj) {
      if(key=='mainPrimarySkill' || key=='otherPrimarySkill' ){
        if(obj['mainPrimarySkill']['skill']){
          return true;
        }
      } else if (obj[key] != null && obj[key] != "" )
        return true;
    }
    return false;
  }

  const checkIfValidSalary = (currentSalary: any) => {
    if (currentSalary > 999999) {
      return false;
    }
    if (currentSalary < 1000) {
      return false;
    }

    if (/^0/.test(currentSalary) || /^-0/.test(currentSalary)) {
      return false;
    }
    if (currentSalary.toString().length > 6) {
      return false;
    }
    return true;
  };

  useEffect(()=>{
    if(expectedSalaryFrom || expectedSalaryTo) {
      setDisableFilter(true);
      let isValidFrom = true;
      let isValidTo = true;

      if(expectedSalaryFrom && !checkIfValidSalary(expectedSalaryFrom)){
        isValidFrom = false;
      }
      if(expectedSalaryTo && !checkIfValidSalary(expectedSalaryTo)){
        isValidTo = false;
      }

      if(expectedSalaryFrom && expectedSalaryTo){
        if(isValidFrom){
          if(parseInt(expectedSalaryFrom)>= parseInt(expectedSalaryTo)){
            setExpectedSalaryFromError(FromSalaryBiggerError);
            isValidFrom = false;
            isValidTo = false;
          }else{
            setExpectedSalaryFromError('');
          }
        }

      }
      if(isValidFrom && isValidTo) {
        const updatedSearch = {...search};
        updatedSearch.salaryExpectationFrom = expectedSalaryFrom;
        updatedSearch.salaryExpectationTo = expectedSalaryTo;
        setSearch(updatedSearch)
        let shouldEnable = checkProperties(updatedSearch);
        setDisableFilter(!shouldEnable);
      }

    }
  },[expectedSalaryFrom,expectedSalaryTo]);

  const handleExpectedSalaryFrom = (event:any) => {
    setExpectedSalaryFromError('');
    setIsInvalidSalary(false);
    let value = event.target.value;
    if(value) {
      value = shortenNumber(value);
    }
    setExpectedSalaryFrom(value);
    if(value) {
      let result = checkIfValidSalary(value);
      if(!result) {
        setExpectedSalaryFromError(SalaryLengthError);
        setIsInvalidSalary(true);
      }
      //For if expected salary from is greater than expected salary to
      if(expectedSalaryTo){
        if(value>=expectedSalaryTo){
          setExpectedSalaryFromError(FromSalaryBiggerError);
          setIsInvalidSalary(true);
        }
      }
    }
  }

  const handleExpectedSalaryTo = (event:any) => {
    setExpectedSalaryToError('');
    setIsInvalidSalary(false);
    let value = event.target.value;
    if(value) {
      value = shortenNumber(value);
    }
    setExpectedSalaryTo(value);
    if(value) {
      let result = checkIfValidSalary(value);
      if(!result) {
        setExpectedSalaryToError(SalaryLengthError);
        setIsInvalidSalary(true);
      }
      //For if expected salary from is greater than expected salary to
      if(expectedSalaryFrom){
        if(value<=expectedSalaryFrom){
          setExpectedSalaryFromError(FromSalaryBiggerError);
          setIsInvalidSalary(true);
        }
      }
    }
  }

  const shortenNumber = (num: number): number => {
    const numStr = num.toString();
    if (numStr.length > 6) {
      return parseInt(numStr.substring(0, numStr.length - 1), 10);
    }
    return num;
  };

  const handleEnglishLevels = (values: any) => {
    if (values) {
      setEnglishLevelId(values?.value);
      setSelectedEnglishLevel(values);
    } else {
      setEnglishLevelId(null);
      setSelectedEnglishLevel(null);
    }
    const updatedSearch = {...search};
    updatedSearch.minimumEnglishLevel = values?.value ?? null;
    updatedSearch.selectedEnglishLevel = values;
    setSearch(updatedSearch)
    let shouldEnable = checkProperties(updatedSearch);
    setDisableFilter(!shouldEnable);
  };

  const handleExtraSkill = (selectedData: any) => {
    setSelectedExtraSkills(selectedData);
    const updatedSearch = {...search};
    updatedSearch.extraSkills = selectedData;
    setSearch(updatedSearch);
    let shouldEnable = checkProperties(updatedSearch);
    setDisableFilter(!shouldEnable);
  };

  const handleKeywords = (event: any) => {
    let value = event.target.value;
    setKeywords(value);
    const updatedSearch = {...search};
    updatedSearch.keywords = value;
    setSearch(updatedSearch);
    let shouldEnable = checkProperties(updatedSearch);
    setDisableFilter(!shouldEnable);
  }

  const handleSetSearch = (value:any) => {
    const updatedSearch = {...search};
    updatedSearch.mainPrimarySkill = value;
    setSearch(updatedSearch)
    let shouldEnable = checkProperties(updatedSearch);
    setDisableFilter(!shouldEnable);
  }


  const handlePrimarySkill = (values: any) => {
    let newSelectedObject = {...selectedMainPrimarySkill};
    if (values && values?.value) {
      newSelectedObject.skill = values;
      setShowAddSkill(true);

    } else {
      setShowAddSkill(false);
      newSelectedObject.skill = null;
    }
    setSelectedMainPrimarySkill(newSelectedObject);
    const updatedSearch = {...search};
    updatedSearch.mainPrimarySkill = newSelectedObject;
    setSearch(updatedSearch)
    let shouldEnable = checkProperties(updatedSearch);
    setDisableFilter(!shouldEnable);
  };

  const handlePrimaryRange = (e:any) => {
    setMinValue(e.minValue);
    setMaxValue(e.maxValue);
    let newObject = {...search?.mainPrimarySkill};
    newObject.min = e.minValue;
    newObject.max = e.maxValue;
    const updatedSearch = {...search};
    updatedSearch.mainPrimarySkill = newObject;
    setSearch(updatedSearch);
  };

  const applyFiler =  () => {
    filterObject.setIsFilterDataLoading();
    storage.storeAllSelectedFilterData(search);
    filterObject.handleSearchObject(search);
  }

  const handleResetFilter = () => {
    resetFilter();
    filterObject.handleNextPage();
  }

  const resetFilter = () => {
    storage.clearAllSelectedFilterData();
    setSearch(searchObject);
    handlePreviousFilterData({"reset_filter":'yes'});
    setDisableFilter(true);

    //window.location.reload();
    //filterObject.handleSearchObject(search);
  }

  let addFormFields = () => {
    let ifContainsEmptyValue = false;
    formValues.map((item: any, index: any) => {
      if (!item.skill) {
        ifContainsEmptyValue = true;
        return;
      }
    });

    if (availableSkillNumber <= 0 || ifContainsEmptyValue) {
      return false;
    }
    setAvailableSkillNumber(availableSkillNumber - 1);

    let newFormValues:any = [...formValues];

    newFormValues.push({skill:null,type:'and'});
    setFormValues(newFormValues);

    const updatedSearch = {...search};
    updatedSearch.otherPrimarySkill = newFormValues;
    setSearch(updatedSearch);

    if (availableSkillNumber <= 1) {
      setShowAddSkill(false);
    }
  };

  let removeFormFields = (i: any) => {
    setAvailableSkillNumber(availableSkillNumber + 1);
    setShowAddSkill(true);
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);

    const updatedSearch = {...search};
    updatedSearch.otherPrimarySkill = newFormValues;
    setSearch(updatedSearch);
    let shouldEnable = checkProperties(updatedSearch);
    setDisableFilter(!shouldEnable);

  };

  let handleOtherPrimarySkill: any = (index: any, skillData: any) => {
    let newFormValues: any = [...formValues];
    newFormValues[index]["skill"] = skillData;
    setFormValues(newFormValues);

    const updatedSearch = {...search};
    updatedSearch.otherPrimarySkill = newFormValues;
    setSearch(updatedSearch);
    let shouldEnable = checkProperties(updatedSearch);
    setDisableFilter(!shouldEnable);
  }

  const handleOtherPrimaryRange = (index:any,e:any) => {
    let newFormValues: any = [...formValues];
    newFormValues[index]["min"] = e.minValue;
    newFormValues[index]["max"] = e.maxValue;
    setFormValues(newFormValues);

    const updatedSearch = {...search};
    updatedSearch.otherPrimarySkill = newFormValues;
    setSearch(updatedSearch);
  };

  const handleQueryType = (type:string,index:any) => {
    let newFormValues: any = [...formValues];
    newFormValues[index]["type"] = type;
    setFormValues(newFormValues);

    const updatedSearch = {...search};
    updatedSearch.otherPrimarySkill = newFormValues;
    setSearch(updatedSearch);
    let shouldEnable = checkProperties(updatedSearch);
    setDisableFilter(!shouldEnable);

    if(type==='or') {
      andRef.current[index].className = inActiveTab;
      orRef.current[index].className = activeTab;
    }else{
      andRef.current[index].className = activeTab;
      orRef.current[index].className = inActiveTab;
    }
  }
  return (
    <form action="">
      <div className="py-6">
        <div className="row gy-6">
          <div className="col-12">
            <label htmlFor="" className="form-label">
              Salary expectation (BDT)
            </label>
            <div className="row gx-3">
              <div className="col-6">
                <input
                  type="number"
                  className={expectedSalaryFromError ? "form-control error-input":"form-control" }
                  placeholder="From"
                  value={expectedSalaryFrom}
                  onWheel={(e) =>
                      (e.target as HTMLElement).blur()
                  }
                  onChange={handleExpectedSalaryFrom}
                  ref={expectedSalaryFromRef}
                />
                <InputFieldErrorMessage error={expectedSalaryFromError}/>
              </div>
              <div className="col-6">
                <input
                    type="number"
                    className={expectedSalaryToError ? "form-control error-input":"form-control" }
                    placeholder="To"
                    value={expectedSalaryTo}
                    onWheel={(e) =>
                        (e.target as HTMLElement).blur()
                    }
                    onChange={handleExpectedSalaryTo}
                    min={100}
                />
                <InputFieldErrorMessage error={expectedSalaryToError}/>
              </div>
            </div>
          </div>
          <div className="col-12">
            <label htmlFor="" className="form-label">
              Minimum English level
            </label>
            <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                isSearchable={false}
                name="englishLevels"
                options={englishLevels}
                value={selectedEnglishLevel}
                placeholder={<>Minimum English level</>}
                onChange={handleEnglishLevels}
                //components={{ SingleValue, Option }}
            />
          </div>
          <div className="col-12">
            <label htmlFor="" className="form-label">
              Primary skill 1
            </label>
            <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                isSearchable={false}
                name="color"
                options={skills}
                value={selectedMainPrimarySkill?.skill ?? null}
                placeholder={<>Primary Skill 1</>}
                onChange={handlePrimarySkill}
            />
          </div>
          <div className="col-12">
            <label htmlFor="customRange2" className="form-label">
              Years of experience
            </label>
            <MultiRangeSlider
                baseClassName="multi-range-slider-black"
                step={1}
                min={0}
                max={10}
                minValue={minValue}
                maxValue={maxValue}
                onInput={(e) => {
                  handlePrimaryRange(e)
                }}
            ></MultiRangeSlider>
          </div>

          {formValues && formValues.map((element: any, index: any) => (

              <div className="col-12" key={index}>
                <div className="d-flex gap-4">
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <button
                          className= {element.type=='and' ? activeTab:inActiveTab}
                          style={{
                            borderRadius: "8px 0 0 8px",
                            borderColor: "#D0D5DD",
                          }}
                          id="nav-home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-home"
                          type="button"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                          onClick={()=>handleQueryType('and',index)}
                          ref={(el) => (andRef.current[index] = el)}
                      >
                        AND
                      </button>
                      <button
                          className= {element.type=='or' ? activeTab:inActiveTab}

                          style={{
                            borderRadius: "0 8px 8px 0 ",
                            borderColor: "#D0D5DD",
                          }}
                          id="nav-profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-profile"
                          type="button"
                          role="tab"
                          aria-controls="nav-profile"
                          aria-selected="false"
                          onClick={()=>handleQueryType('or',index)}
                          ref={(el) => (orRef.current[index] = el)}
                      >
                        OR
                      </button>
                    </div>
                  </nav>
                  <div className="align-self-center">
                    <p onClick={()=>removeFormFields(index)} className="mb-0 text-danger fw-semibold small cursor-pointer">
                      - Remove skill
                    </p>
                  </div>
                </div>
                <div className="tab-content border-start" id="nav-tabContent">
                  <div
                      className="tab-pane fade show active"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                      tabIndex={0}
                  >
                    <div className="row gy-4 py-4 ps-5">
                      <div className="col-12">
                        <label htmlFor="" className="form-label">
                          Primary skill {index+2}
                        </label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={true}
                            isSearchable={false}
                            name="color"
                            options={skills}
                            value={element.skill ?? null}
                            placeholder={"Primary skill "+(index+2)}
                            onChange={(e) => handleOtherPrimarySkill(index, e)}
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="customRange2" className="form-label">
                          Years of experience
                        </label>
                        <MultiRangeSlider
                            baseClassName="multi-range-slider-black"
                            step={1}
                            min={0}
                            max={10}
                            minValue={element.min ?? 0}
                            maxValue={element.max ?? 10}
                            onInput={(e) => {
                              handleOtherPrimaryRange(index,e)
                            }}
                        ></MultiRangeSlider>
                      </div>
                    </div>
                  </div>
                  <div
                      className="tab-pane fade"
                      id="nav-profile"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                      tabIndex={0}
                  >
                    ...
                  </div>
                </div>
              </div>

          ))}

          {showAddSkill ?
              <p onClick={addFormFields} className="mb-0 small text-theme  cursor-pointer">
                + Add skills
              </p>
              :''
          }



          <div className="col-12">
            <label htmlFor="" className="form-label">
              Must have extra skills
            </label>
            <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                isSearchable={true}
                name="extraSkill"
                options={extraSkill}
                placeholder={<>Select one or more</>}
                onChange={handleExtraSkill}
                defaultValue={selectedExtraSkills}
                value={selectedExtraSkills}
                isMulti={true}
            />
          </div>

          <div className="col-12">

            <label htmlFor="" className="form-label">
              Should have keywords
            </label>
            <textarea
              className="w-100 form-control"
              placeholder="Write one or more (comma separated)"
              onChange={handleKeywords}
              value={keywords}
            />
          </div>
        </div>
      </div>
      <hr className="my-0" />
      <div className="py-4">
        <div className="row gx-3">
          <div className="col-6">
            <WhiteButton onclick={handleResetFilter} disabled={disableFilter || isFilterDataLoading} type="button" text={"Reset"} className="w-100" />
          </div>
          <div className="col-6">
            <button
                disabled={disableFilter || isFilterDataLoading || isInvalidSalary}
                type="button"
                className="btn btn-primary text-white w-100"
                onClick={applyFiler}
            >
              {isFilterDataLoading ? 'Processing.. ':'Apply filter'}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
});

export default FilterOption;
