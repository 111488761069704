import React, { useEffect, useState } from "react";
import announcement from "../../../assets/images/icons/announcement.svg";
import WhiteButton from "../../../components/buttons/WhiteButton";
import {
  setCookie,
  getCookie,
} from "../../../services/RecruiterAuthenticationService";

const Announcement = (props: any) => {
  const [show, setShow] = useState(false);
  let activeClass = "tab-pane fade show pt-0 active";
  useEffect(() => {
    if (getCookie("announce")) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, []);

  const handleClick = () => {
    props.setClassForAnnouncement(activeClass);
    setCookie("announce", "set", 365);
    setShow(false);
  };
  return (
    <>
      {show ? (
        <div className="col-12 col-md-10 offset-0 offset-md-2">
        <div className="px-md-5 position-relative">
          <div className="bg-white p-3 rounded-3 border mb-6 toaster">
            <div className="row">
              <div className="col-12 col-lg-10 ">
                <div className="d-block d-md-flex">
                <img src={announcement} alt="" className="img-fluid pe-4" />
                <p className="mb-0 py-3 py-lg-0">
                  By contacting candidate you agree to pay <span className="text-primary fw-semibold">500 $ (approx. 53,047
                  ৳)</span> per each candidate hired. We need this to keep the site
                  running
                </p>
                </div>
              </div>
  
              <div className="col-12 col-lg-2">
                <WhiteButton
                onClick={handleClick}
                  text="I understand"
                  type="submit"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Announcement;
