import { Link } from "react-router-dom";
import companyLogo from "../../../assets/images/icons/infrastructure.png";
import global from "../../../assets/images/icons/globe.svg";
import backArrow from "../../../assets/images/icons/arrow-left.svg";
import recruiter from "../../../assets/images/recruiter-image.png";
import linkedIn from "../../../assets/images/icons/linkedin.svg";
import message from "../../../assets/images/icons/message.svg";
import avater from "../../../assets/images/user-avater.png";
import WhiteButton from "../../../components/buttons/WhiteButton";
import ContactedCandidate from "./components/ContactedCandidate";
import submitButton from "../../../assets/images/icons/submit-button.svg"
import useRecruiterProfilePublicView from "./useRecruiterProfilePublicView";
import {useEffect} from "react";
import HiredCandidate from "./components/HiredCandidate";
import RecruiterDetails from "./components/RecruiterDetails";
import ReportSuccessAlert from "../../recruiter/candidate-profile/component/ReportSuccessAlert";
import InputFieldErrorMessage from "../../../components/errors/InputFieldErrorMessage";

const RecruiterProfilePublicView = (props:any) => {
  let userId = props.userId;
  const {recruiterDetails,getDetails,extraDetails,showReportModal, setShowReportModal,
    showReportButton, setShowReportButton,verificationStatus,isLoading,isContacted,
  isHired,error} = useRecruiterProfilePublicView();

  useEffect(() => {
    getDetails(userId);
  }, []);



  let firstLetter = "R";
  if (recruiterDetails && recruiterDetails.first_letter) {
    if (recruiterDetails.first_letter != "-") {
      firstLetter = recruiterDetails.first_letter;
    }
  }

  const handleShowReportModal = () => {
    setShowReportModal(true);
  };
  const handleHideReportModal = () => {
    setShowReportModal(false);
  };

  const handleHideReportButton = () => {
    setShowReportButton(false);
  };

  let detailsFunctionalities = {
    showReportModal: showReportModal,
    handleShowReportModal: handleShowReportModal,
    handleHideReportModal: handleHideReportModal,
    showReportButton: showReportButton,
    setShowReportButton: handleHideReportButton,
    verificationStatus: verificationStatus,
    isLoading:isLoading
  };

  return (
    <div>
      <div className="company-banner">
        <div className="company-banner-2">
          <div className="py-4">
            <div className="container">
              <div className="pb-3 pb-md-5">
                <button
                  className="btn px-3 py-2 d-flex"
                  style={{ backgroundColor: "#F8F9FC", color: "#363F72" }}
                >
                  <img src={backArrow} className="img-fluid" alt="" />
                  <span className="ps-2 small fw-semibold">Go Back</span>
                </button>
              </div>
              <div className="d-flex">
                <div className="">
                  <div className="">
                    <img src={companyLogo} className="image-fluid" alt="" />
                  </div>
                </div>
                <div className="w-100 align-self-center">
                  <div className="ps-3">
                    <div className="row">
                      <div className="col-12 col-md-8">
                        <div className="text-white ">
                          <h4 className="text-white pb-1">{recruiterDetails?.company_name ?? ''}</h4>
                          {/*<p className="mb-0" style={{ color: "#EAECF0" }}>
                            We find and prescreen, you choose. Hire faster, grow
                            faster, save costs along the way.
                          </p>*/}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="text-md-center pt-1 pt-md-0">
                          <img src={global} alt="" className="img-fluid" />
                          <Link
                            to={recruiterDetails?.company_site ?? ''}
                            className="ps-2 text-decoration-underline"
                            style={{ color: "#F9FAFB" }}
                            target="_blank"
                          >
                            {recruiterDetails?.company_site ?? ''}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="recruiter-spacious-section">
        <div className="container">
          <div className="row gy-6 gy-lg-0">
            <div className="col-12 col-lg-4 order-lg-2">
              {isLoading ? '':
                  (<>
                    {isHired ? <HiredCandidate extraDetails={extraDetails} /> :
                        (isContacted ? <ContactedCandidate extraDetails={extraDetails} />:
                                (<RecruiterDetails detailsFunctionalities={detailsFunctionalities} extraDetails={extraDetails}/>)
                        )
                    }
              <ReportSuccessAlert
                  detailsFunctionalities={detailsFunctionalities}
              />
                    <InputFieldErrorMessage error={error}/>
                  </>)
              }
            </div>
            <div className="col-12 col-lg-8 order-lg-1 ">
              <div className="px-2">
                <div className="row gy-7">
                  <div className="col-12">
                    <p className="mb-0 fw-semibold common-text-size common-text pb-3">
                      Overview
                    </p>
                    <p className="mb-0 preserve-paragraph">
                      {recruiterDetails?.company_description ?? ''}
                    </p>
                  </div>
                  <div className="col-12">
                    <p className="mb-0 fw-semibold common-text-size common-text pb-3">
                      Contact person
                    </p>
                    <div className="d-flex">
                      <div>
                        <div
                            className="d-flex justify-content-center align-items-center bg-danger rounded-2"
                            style={{ width: "56px", height: "56px" }}
                        >
                          <p className="mb-0 text-uppercase text-white">{firstLetter}</p>
                        </div>
                      </div>
                      <div className="ps-4">
                        <div className="d-flex">
                          <p
                            className="mb-0 fw-semibold pe-2"
                            style={{ fontSize: "1.125rem" }}
                          >
                            {recruiterDetails?.name ?? ''}
                          </p>
                          {recruiterDetails?.linkedin_profile ?
                              (<Link to={recruiterDetails?.linkedin_profile ?? ''} target="_blank">
                                <img
                                    src={linkedIn}
                                    className="img-fluid"
                                    alt=""
                                    height="24px"
                                    width="24px"
                                />
                              </Link>):
                              ''}
                        </div>
                        <p className="mb-0 pt-1">
                          <span>{recruiterDetails?.job_title ?? ''}</span>
                          <span>,</span> <span>{recruiterDetails?.company_name ?? ''}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <p className="mb-0 fw-semibold common-text-size common-text pb-3">
                      Opportunities we offer
                    </p>
                    <p className="mb-0 preserve-paragraph">
                      {recruiterDetails?.company_opportunity_description ?? ''}
                    </p>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruiterProfilePublicView;