import React from 'react'

type props = {
    total:string|number|null
}
const TotalCandidateFound = (props:props) => {
    let total = props.total;
  return (
    <div className="row">
          <div className="col-12">
            <div className="pt-6 pb-5">
              <p
                className="mb-0 fw-semibold text-dark pb-1"
                style={{ fontSize: "30px" }}
              >
                  {total} candidates found
              </p>
              <p className="mb-0 text-secondary">
                You can narrow down your search using available filters
              </p>
            </div>
          </div>
        </div>
  )
}

export default TotalCandidateFound
