import {useState} from "react";
import {CredentialResponse} from "google-one-tap";
import {LoginService} from "../../../services/LoginService";
import {AppStorageService} from "../../../services/AppStorageService";
import {CommonErrorMsg} from "../../../utils/CommonConstants";

const useMultipleSkill = () => {
    const [loginErrorMsg,setLoginErrorMsg] = useState('');
    const ALL_SKILLS = "all_skills";
    const SELECTED_SKILLS = "selected_skills";

    const googleLoginButtonLoad = () => {
        // @ts-ignore
        window.onGoogleLibraryLoad = () => {
            // @ts-ignore
            google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_TOKEN ? process.env.REACT_APP_GOOGLE_TOKEN :'',
                callback: handleCredentialResponse.bind(this),
                auto_select: false,
                cancel_on_tap_outside: true
            });
            // @ts-ignore
            google.accounts.id.renderButton(
                // @ts-ignore
                document.getElementById("googleLoginBtn"),
                {theme: "outline", size: "large", width: "600px"}
            );
            // @ts-ignore
            //google.accounts.id.prompt((notification: PromptMomentNotification) => {});


        };
    }


    function handleCredentialResponse(response: CredentialResponse) {
        userLoggedIn(response);
    }

    const loginService = new LoginService();
    let storage = new AppStorageService;
    async function userLoggedIn(response: CredentialResponse) {
        setLoginErrorMsg('');
        // @ts-ignore
        document.getElementById('googleLoaderButton').style = "display: block";
        // @ts-ignore
        document.getElementById('googleLoginBtnDiv').style = "display: none";
        let user = loginService.parseJwt(response.credential);
        let result = await fetchValidatedGoogleLogin(response.credential);

        if (result === 'success') {
            user = loginService.parseJwt(response.credential);
            storage.setGoogleToken(response.credential);
            storage.storeUserDetails(user);
            storage.setUserLoggedIn(true);
            storage.setLoginWelcomeMessage(true);
            window.location.reload();
        } else {
            // @ts-ignore
            document.getElementById('googleLoaderButton').style = "display: none";
            // @ts-ignore
            document.getElementById('googleLoginBtnDiv').style = "display: block";
            console.log('Failed')

        }

    }

    const fetchValidatedGoogleLogin = async (token: string) => {
        let response = await loginService.validateGoogleToken(token);
        if (response && response.success && response.data && response.data.token) {
            storage.setToken(response.data.token);
            return 'success';
        } else {
            let errTxt = response.message ?? CommonErrorMsg;
            setLoginErrorMsg(errTxt);
            return 'failed';
        }

    };


    return {loginErrorMsg,setLoginErrorMsg,googleLoginButtonLoad};
}

export default useMultipleSkill;