import TotalCandidateFound from "./component/TotalCandidateFound";
import FilterOption from "./component/FilterOption";
import SearchNavTabs from "./component/SearchNavTabs";
import FilterDataForMobile from "./component/FilterDataForMobile";
import SearchTabContent from "./component/SearchTabContent";
import useSearchCandidate from "./useSearchCandidate";
import {useRef} from "react";

const SearchCandidate = () => {
    const {searchCandidate,showAllCandidateTab, setShowAllCandidateTab,candidates,totalCandidate,allDataFromApi,
        searchObject,setSearchObject,paginationCurrentPage,setPaginationCurrentPage,englishLevels,
        extraSkill,filterData,headingRef,isFilterDataLoading,setIsFilterDataLoading,skills,
        isResultLoaded,savedCandidateList,setSavedCandidateList,searchSavedCandidate,showSavedTab,hideSavedTab,
        setTotalCandidate} = useSearchCandidate();

    const filterRef = useRef<any>(null);

    const handleShowAllCandidateTab = async () => {
        hideSavedTab();
        setShowAllCandidateTab(true);
        filterRef?.current?.callResetFilter();
        await searchCandidate({})

    }

    const handleHideAllCandidateTab = async () => {
        showSavedTab();
        setShowAllCandidateTab(false);
        filterRef?.current?.callResetFilter();
        await searchSavedCandidate({})

    }

    const handleNextPage = async (pageObject:any) => {
        if(!showAllCandidateTab) {
            await searchSavedCandidate(pageObject);
        }else{
            await searchCandidate(pageObject)
        }

    }

    const handleNewSearchObject = async (searchObject:any) => {

        setPaginationCurrentPage(1);
        if(!showAllCandidateTab) {
            await searchSavedCandidate(searchObject);
        }else{
            await searchCandidate(searchObject)
        }
    }

    const handleSetFilterDataLoading = () => {
        setIsFilterDataLoading(true);
    }

    const handleSavedCandidateList = (event:any) => {
        setSavedCandidateList(event);
    }

    const tabObject = {
        showAllCandidateTab: showAllCandidateTab,
        showTab: handleShowAllCandidateTab,
        hideTab: handleHideAllCandidateTab
    }

    const candidateObject = {
        allDataFromApi:allDataFromApi,
        totalCandidate:totalCandidate,
        setTotalCandidate:setTotalCandidate,
        handleNextPage: handleNextPage,
        paginationCurrentPage:paginationCurrentPage,
        isFilterDataLoading:isFilterDataLoading,
        isResultLoaded:isResultLoaded,
        savedCandidateList:savedCandidateList,
        handleSavedCandidateList:handleSavedCandidateList
    }

    const filterObject = {
        searchObject:searchObject,
        handleSearchObject:handleNewSearchObject,
        englishLevels:englishLevels,
        extraSkill:extraSkill,
        isFilterDataLoading:isFilterDataLoading,
        setIsFilterDataLoading:handleSetFilterDataLoading,
        handleNextPage: handleNextPage,
        skills:skills,
        resetFilter:filterRef?.current?.callResetFilter

    }

    return(
        <>
            <section className="padding-bottom">
                <div className="container">
                    <TotalCandidateFound total={totalCandidate} />
                    <div className="pt-7">
                        <div className="row">
                            <div className="col-3 d-none d-lg-inline-block">
                                <div className="bg-light rounded-3">
                                    <div className="px-6 pt-6">
                                        <p
                                            className="mb-0 fw-semibold text-dark pb-1"
                                            style={{ fontSize: "20px" }}
                                        >
                                            Filters
                                        </p>
                                        <p className="mb-0 text-secondary">
                                            Apply filters to search results
                                        </p>
                                        <FilterOption  filterObject={filterObject} ref={filterRef}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-9" ref={headingRef}>
                                <div className="px-0 px-md-6">
                                    <SearchNavTabs tabObject={tabObject} />
                                    <FilterDataForMobile filterObject={filterObject} />
                                    <SearchTabContent tabObject={tabObject} candidateObject={candidateObject}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SearchCandidate;