import backArrow from "../../../assets/images/icons/arrow-left-purple.svg";
import save from "../../../assets/images/icons/save-button.svg";
import Announcement from "./component/Announcement";
import CandidateDetails from "./component/CandidateDetails";
import { Link } from "react-router-dom";
import { RECRUITER_SEARCH_CANDIDATE } from "../../../utils/RouteNamingConfig";
import React, { useEffect } from "react";
import useCandidateProfilePublicView from "./useCandidateProfilePublicView";
import CandidateReport from "./component/CandidateReport";
import ReportSuccessAlert from "./component/ReportSuccessAlert";
import CandidateSave from "./component/CandidateSave";
import CandidatePrivateInfo from "./component/CandidatePrivateInfo";
import CandidateDetailsAfterContacted from "./component/CandidateDetailsAfterContacted";
import HiredCandidatePaymentStatus from "./component/HiredCandidatePaymentStatus";
import HiredCandidate from "../../candidate/recruiter-profile/components/HiredCandidate";
import ContactedCandidate from "../../candidate/recruiter-profile/components/ContactedCandidate";
import RecruiterDetails from "../../candidate/recruiter-profile/components/RecruiterDetails";
import InputFieldErrorMessage from "../../../components/errors/InputFieldErrorMessage";
import PaymentModal from "../../../components/recruiter/modals/PaymentModal";
import {useCandidateDetails} from "../../../context/useCandidateDetails";

const CandidateProfilePublicView = (props: any) => {
  let userId = props.userId;
  const {
    candidateDetails,
    getDetails,
    extraDetails,
    showReportModal,
    setShowReportModal,
    showReportButton,
    setShowReportButton,
    verificationStatus,
    candidatePrivateDetails,
    isLoading,
    isHired,
    isContacted,
      error,
    paymentStatus,
    setPaymentStatus
  } = useCandidateProfilePublicView();
  useEffect(() => {
    getDetails(userId);
  }, []);

  const showPaymentModal = useCandidateDetails((state:any)=>state.showPaymentModal);
  const setShowPaymentModal = useCandidateDetails((state:any)=>state.setShowPaymentModal);

  const handleShowReportModal = () => {
    setShowReportModal(true);
  };
  const handleHideReportModal = () => {
    setShowReportModal(false);
  };

  const handleHideReportButton = () => {
    setShowReportButton(false);
  };

  let detailsFunctionalities = {
    showReportModal: showReportModal,
    handleShowReportModal: handleShowReportModal,
    handleHideReportModal: handleHideReportModal,
    showReportButton: showReportButton,
    setShowReportButton: handleHideReportButton,
    verificationStatus: verificationStatus,
  };

  let saveObject = {
    user: userId,
    isSaved: candidateDetails?.save_list,
  };

  return (
    <>
      <section className="candidate-banner">
        <div className="candidate-banner-2">
          <div className="py-6">
            <div className="container">
              <div className="pb-3 pb-md-5">
                <Link to={RECRUITER_SEARCH_CANDIDATE}>
                  <button
                    className="btn text-primary py-2 px-3"
                    style={{ backgroundColor: "#F8F9FC" }}
                  >
                    <img src={backArrow} className="img-fluid" alt="" />
                    <span className="ps-2 small fw-semibold">
                      Back to the search
                    </span>
                  </button>
                </Link>
              </div>
              <div className="d-md-flex justify-content-between">
                <div>
                  <div className="d-flex">
                    <p className="mb-0 fw-semibold text-white me-3 fs-3">
                      {candidateDetails?.job_title ?? ""}
                    </p>
                    <CandidateSave saveObject={saveObject} />
                  </div>
                  <p className="mb-0 pt-1" style={{ color: "#EAECF0" }}>
                    {candidateDetails?.primary_skills &&
                      candidateDetails?.primary_skills.map(
                        (item: any, index: any) => {
                          return (
                            <span key={index}>
                              <span>
                                {index ? ", " : ""}
                                {item.name}{" "}
                                {item.experience
                                  ? item.experience + " years"
                                  : ""}
                                {item.currently_working == "Yes"
                                  ? " (using now)"
                                  : ""}
                              </span>{" "}
                            </span>
                          );
                        }
                      )}
                  </p>
                </div>
                <div className="text-end">
                  <p className="mb-0 ">
                    <span className="text-white pe-2 fs-3">
                      {" "}
                      {candidateDetails?.expected_salary
                        ? candidateDetails.expected_salary.toLocaleString()
                        : 0.0}{" "}
                    </span>
                    <span style={{ color: "#F9FAFB" }}>BDT/month</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="recruiter-spacious-section">
        <div className="container">
          <div className="row gy-6 gy-lg-0 position-relative">
            
            <div className="col-12 col-lg-8">
              <div className="row">
                <div className="col-12">
                  <Announcement />
                </div>
                <div className="col-12">
                  <CandidatePrivateInfo
                    candidateDetails={candidateDetails}
                    candidatePrivateDetails={candidatePrivateDetails}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 candidate-profile-position">

              {isLoading ? '' :

                  (<>
                    {isHired ? <HiredCandidatePaymentStatus paymentStatus={paymentStatus} extraDetails={extraDetails}
                                                                          detailsFunctionalities={detailsFunctionalities}/>:
                        (isContacted ? <CandidateDetailsAfterContacted extraDetails={extraDetails}
                                                                       detailsFunctionalities={detailsFunctionalities}/> :
                                (<CandidateDetails
                                    extraDetails={extraDetails}
                                    detailsFunctionalities={detailsFunctionalities}
                                />)
                        )
                    }
                    <ReportSuccessAlert
                        detailsFunctionalities={detailsFunctionalities}
                    />
                    <InputFieldErrorMessage error={error}/>
                  </>)
              }
            </div>
            <div className="col-12 col-lg-8">
              <div className="row gy-6 gy-md-7">
                <div className="col-12">
                  <p className="mb-0 common-text-size common-text fw-semibold pb-3">
                    Overview
                  </p>
                  <div className="d-flex flex-column gap-5">
                    <p className="mb-0 preserve-paragraph">
                      {candidateDetails?.work_experience ?? ""}
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <p className="mb-0 common-text-size common-text fw-semibold pb-3">
                    Extra skills
                  </p>
                  <div className="d-flex flex-wrap gap-1">
                    {candidateDetails?.extra_skills &&
                      candidateDetails.extra_skills.map(
                        (item: any, index: any) => {
                          return (
                            <p
                              key={index}
                              className="small mb-0 candidate-skill py-1 px-3 rounded-4"
                            >
                              {item.name}
                            </p>
                          );
                        }
                      )}
                  </div>
                </div>
                <div className="col-12">
                  <p className="mb-0 common-text-size common-text fw-semibold pb-3">
                    Achievements/certificates
                  </p>
                  <p className="mb-0 preserve-paragraph">{candidateDetails?.achievements ?? ""}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaymentModal extraDetails={extraDetails}/>
      </section>
    </>
  );
};

export default CandidateProfilePublicView;