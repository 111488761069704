import checkedIcon from "../../../../assets/images/icons/check-icon.svg";

const NoCandidateFound = () => {
  return (
    <div className="py-6">
    <div className="bg-light p-4 rounded-3">
      <p
        className="mb-0 fw-semibold text-black pb-4"
        style={{ fontSize: "20px" }}
      >
        {" "}
        You can consider these suggestions:
      </p>
      <div className="d-flex pb-2">
        <img
          src={checkedIcon}
          alt=""
          className="img-fluid align-self-start"
        />
        <p className="mb-0 text-secondary ps-2">
          Adjust different filters (remove a few if needed)
        </p>
      </div>
      <div className="d-flex pb-2">
        <img
          src={checkedIcon}
          alt=""
          className="img-fluid align-self-start"
        />
        <p className="mb-0 text-secondary ps-2">
          Make sure all words are spelt correctly
        </p>
      </div>
      <div className="d-flex pb-2">
        <img
          src={checkedIcon}
          alt=""
          className="img-fluid align-self-start"
        />
        <p className="mb-0 text-secondary ps-2">
          Use AND ,OR operator carefully{" "}
        </p>
      </div>
    </div>
  </div>
  )
}

export default NoCandidateFound
