import Modal from "react-bootstrap/Modal";
import {useState} from "react";
import tick from "../../../assets/images/icons/tick-green.svg";

const EmailVerificationConfirmationModal = (props:any) => {
    let clearMsg = props.clearMsg;
    const [modalShowForEmail,setModalShowForEmail] = useState(props.show);
    const handleClick = () => {
        clearMsg();
        setModalShowForEmail(false)

    }

    return(
        <>
            <Modal
                show={modalShowForEmail}
                onHide={() => setModalShowForEmail(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton onClick={clearMsg}>
                    <Modal.Title id="contained-modal-title-vcenter" className="p-2">
                        <div className="d-flex">
                            <img src={tick} className="img-fluid" alt="" />
                            <div className="ps-4">
                                <h3 className="pb-1" style={{ fontSize: "20px" }}>
                                    Thanks, your email is verified now
                                </h3>
                                <p
                                    className="mb-0"
                                    style={{ fontWeight: "normal", fontSize: "14px" }}
                                >
                                    Last thing you need to do is to complete your profile.
                                </p>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <button
                        onClick={() => handleClick()}
                        className="btn btn-primary text-white"
                    >
                        {" "}
                        Got it
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EmailVerificationConfirmationModal;