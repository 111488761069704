import {useState} from "react";
import {prepareAllErrorMsg, signIn as recruiterSignIn} from "../../../services/RecruiterAuthenticationService";
import {RECRUITER_PROFILE, RECRUITER_SEARCH_CANDIDATE} from "../../../utils/RouteNamingConfig";
import {CommonErrorMsg} from "../../../utils/CommonConstants";
import {getCandidateProfileDetails} from "../../../services/RecruiterService";
import {useNavigate} from "react-router-dom";
import {useAuthContext} from "../../../context/useAuthContext";
import {useMessenger} from "../../../context/useMessenger";
import {useCandidateDetails} from "../../../context/useCandidateDetails";

const useCandidateProfilePublicView = () => {
    const [candidateDetails, setCandidateDetails] = useState<any>(null);
    const [candidatePrivateDetails, setCandidatePrivateDetails] = useState<any>([]);
    const [extraDetails, setExtraDetails] = useState<any>(null);
    const [showReportModal, setShowReportModal] = useState<any>(false);
    const [showReportButton, setShowReportButton] = useState<any>(false);
    const [verificationStatus, setVerificationStatus] = useState<any>('');
    const [isContacted, setIsContacted] = useState<any>(false);
    const [isLoading, setIsLoading] = useState<any>(false);
    const [error, setError] = useState<any>('');
    let navigate = useNavigate();
    const {dispatch}:any= useAuthContext();
    const paymentStatus = useCandidateDetails((state:any)=>state.paymentStatus);
    const setPaymentStatus = useCandidateDetails((state:any)=>state.setPaymentStatus);
    const isHired = useCandidateDetails((state:any)=>state.isHired);
    const setIsHired = useCandidateDetails((state:any)=>state.setIsHired);


    const getDetails = async (userId:any) => {
        let object = {
            user: userId
        }
        setIsLoading(true);
        const response = await getCandidateProfileDetails(object);
        if(response && response.success && response.data){
            setCandidateDetails(response.data.candidate_details);
            setShowReportButton(response.data.candidate_details.show_report_button);
            let extraDetailsObject = {
                userId:userId,
                englishLevel:  response.data.candidate_details?.english_level ?? {},
                jobTypes:  response.data.candidate_details?.job_types ?? {},
                exclusionInterest:  response.data.candidate_details?.exclusion_interest ?? {},
                setIsContacted:setIsContacted,
                setError:setError

            };
            setExtraDetails(extraDetailsObject);
            setVerificationStatus(response.data.user_details?.status);
            if(response.data.should_show_private_details) {
                setCandidatePrivateDetails(response.data.candidate_private_details);
            }



            //updating the latest status after submission
            let userObjectFromStorage = localStorage.getItem('user') ?? '';
            if(userObjectFromStorage){
                let useInJson =  JSON.parse(userObjectFromStorage);
                useInJson = {...useInJson,
                    status:response?.data?.user_details?.status,
                };
                localStorage.setItem('user',JSON.stringify(useInJson));
                dispatch({type:'LOGIN',payload:useInJson});
            }
            setIsContacted(response.data.is_contacted);
            setIsHired(response.data.is_hired);
            setPaymentStatus(response.data.payment_status);
            setIsLoading(false);
        }else{
            setIsLoading(false);
            setError(prepareAllErrorMsg(response));
        }
    }

    return {candidateDetails,getDetails,extraDetails,showReportModal, setShowReportModal,
        showReportButton, setShowReportButton,verificationStatus,candidatePrivateDetails,isLoading,isHired,isContacted,
    error,paymentStatus,setPaymentStatus};
}
export default useCandidateProfilePublicView;