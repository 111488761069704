import {useNavigate, useSearchParams} from "react-router-dom";
import {signUp as recruiterSignUp, verify} from "../../../services/RecruiterAuthenticationService";
import {useAuthContext} from "../../../context/useAuthContext";
import {RECRUITER_PROFILE} from "../../../utils/RouteNamingConfig";
import {CommonErrorMsg} from "../../../utils/CommonConstants";
import {useEffect, useState} from "react";
import {useSignUpMsg} from "../../../context/useSignUpMsg";


type VerificationObject = {
    xde:string|null,
    udev:string|null
}

const Verification = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [error, setError] = useState('');
    let udev = searchParams.get("udev")
    let xde = searchParams.get("xde")
    let verificationObj:VerificationObject = {xde:xde,udev:udev};
    const {dispatch}:any= useAuthContext();
    const showSignUpMsg = useSignUpMsg((state:any) => state.setMsg);
    useEffect(()=>{
        if(udev && xde){
            process(verificationObj);
        }else{
            setError('Invalid!'+CommonErrorMsg)
        }

    },[])

    const process = async (verificationObj:VerificationObject) => {
        setError('');
        let updatedSearchParams = new URLSearchParams('');
        setSearchParams(updatedSearchParams.toString());
        let  response = await verify(verificationObj);
        if(response && response.success && response.data){
            showSignUpMsg();
            localStorage.setItem('user',JSON.stringify(response.data))
            localStorage.setItem('token',JSON.stringify(response.data.token ?? ''))
            dispatch({type:'LOGIN',payload:response.data})
            navigate(RECRUITER_PROFILE);

        }else{
            setError(response.message ?? CommonErrorMsg)
        }

    }
    return (
        <>
            <div style={{textAlign:"center",marginTop:"30vh"}}>

                {(error && error.length>0) ?
                    <>
                        {error}
                    </>
                    :

                    <>
                        <img
                        style={{ marginRight: 6 }}
                        src={require("../../../assets/images/icons/loading.png")}
                        className="spinner"
                        width="28"
                        alt=""
                    /> <p>Verifying, please wait..</p>
                    </>
                }


            </div>
        </>
    );
}
export default Verification;