import locationPointer from "../../../../assets/images/icons/location-pointer.svg";
import {RECRUITER_CANDIDATE_PROFILE_BASE_URL} from "../../../../utils/RouteNamingConfig";
import {Link, useNavigate} from "react-router-dom";
import CandidateSave from "../../candidate-profile/component/CandidateSave";

const AllCadidates = (props:any) => {
  let characterBeak = 222;
  let candidate = props.candidate;
  let expectedSalary = candidate.expected_salary ? candidate.expected_salary.toLocaleString(): 0.00;
  let workExperience = candidate.work_experience ?? '';
  if(workExperience) {
    if(workExperience.length > characterBeak) {
      workExperience=workExperience.substring(0,characterBeak)+'...';
    }

  }
  let jobTypesObject  = candidate.job_types ?? [];
  let extraSkillObject  = candidate.extra_skills ?? [];
  let primarySkillObject  = candidate.primary_skills ?? [];
  let extraSkillArr:any = [];
  let totalExtraSkill = extraSkillObject.length;
  let profileUrl = RECRUITER_CANDIDATE_PROFILE_BASE_URL+'/'+candidate.user_id;
  let navigate = useNavigate();

  for(let i=0;i < extraSkillObject.length;i++){
    if(i>2){
      extraSkillArr.push("+ "+(totalExtraSkill-i));
     break;
    }
    extraSkillArr.push(extraSkillObject[i].name);
  }

  let saveObject = {
    user:candidate.user_id,
    isSaved: candidate?.save_list ? candidate.save_list.length>0 :false
  };
  return (
      <>
          <div className="col-12 cursor" onClick={()=>navigate(profileUrl)}>
          <div className="border shadow-sm p-4 rounded-3">
            <div className="row gy-4">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <div>
                    <p
                         className="mb-0 pb-1 fw-semibold"
                         style={{ fontSize: "20px",color:"#6941C6" }}
                    >
                      {candidate.job_title}
                    </p>
                    <p className="mb-0 text-gray">
                      {primarySkillObject  && primarySkillObject.map((item:any,index:any)=>{
                        return (<span key={index}>
                            <span>{(index ? ', ' : '')}{item.name}
                              {" "}{item.pivot.experience ? item.pivot.experience +' years':''}
                              {item.pivot.currently_working == 'Yes' ? " (using now)":''}

                              </span>{" "}
                          </span>)
                      })}

                    </p>
                  </div>
                  <div>
                    <CandidateSave saveObject={saveObject}/>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex gap-1">

                  {extraSkillArr && extraSkillArr.map((item:any,index:any)=>{
                    return (<span key={index}>
                      <p
                          className="small mb-0 px-2 py-1 text-semidark rounded-4"
                          style={{ backgroundColor: "#F2F4F7" }}
                      >{item}</p>
                          </span>)

                  })}
                </div>
              </div>
              <div className="col-12">
                <p
                    className="mb-0 "
                    style={{ color: "#667085" }}
                >
                  {workExperience}

                </p>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="d-flex gap-2">
                      <img
                          src={locationPointer}
                          alt=""
                          className="img-fluid align-self-start pt-1"
                      />
                      <p className="mb-0 text-gray">
                        {jobTypesObject && jobTypesObject.map((item:any,index:any)=>{
                          return (<span key={index}>
                            <span>{(index ? ', ' : '')}{item.name}</span> <span></span>{" "}
                          </span>)
                        })}

                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 ">
                    <div className="d-flex gap-2 justify-content-md-end pt-4 pt-md-0">
                      <p
                          className="mb-0 text-dark fw-semibold"
                          style={{ fontSize: "20px" }}
                      >
                        {expectedSalary}
                      </p>
                      <p className="mb-0 text-gray align-self-center">
                        BDT/month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

  )
}

export default AllCadidates
