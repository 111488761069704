import avatar from "../../../assets/images/avatar.png";
import icon from "../../../assets/images/icons/avatar-icon.svg";
import star from "../../../assets/images/icons/star-fill.svg";

const AuthPageSideBar=()=>{
    return (
        <div className="col-lg-6 d-none d-lg-flex align-items-center justify-content-center text-center bg-light ">
            <div className="pe-7 me-7">
                <h2 className="display-5 fw-medium text-dark pb-7">
                    RemoteDevs helped me build my dream team where I have found
                    quality talents which contributed to my companies growth
                </h2>
                <div className="position-relative d-inline-block pb-4">
                    <img
                        src={avatar}
                        className="img-fluid"
                        alt="avatar"
                        style={{ height: "64px", width: "64px" }}
                    />
                    <img
                        src={icon}
                        className="img-fluid position-absolute"
                        style={{ bottom: "13.6875px", right: "-3.3125px" }}
                        alt="avatar"
                    />
                </div>
                <p className="text-dark pb-1 mb-0 fw-semibold">Tania Mahzabin</p>
                <p className="small pb-7 mb-0">CEO, PLEX IT</p>
                <div>
                    <img src={star} alt="" className="img-fluid ps-1" />
                    <img src={star} alt="" className="img-fluid ps-1" />
                    <img src={star} alt="" className="img-fluid ps-1" />
                    <img src={star} alt="" className="img-fluid ps-1" />
                    <img src={star} alt="" className="img-fluid ps-1" />
                </div>
            </div>
        </div>
    );
}

export default AuthPageSideBar;