import {Link, useNavigate} from "react-router-dom";
import backArrow from "../../../assets/images/icons/arrow-left.svg";
import mobileLogo from "../../../assets/images/icons/logo-for-signup.svg";
import {RECRUITER_FORGOT_PASSWORD} from "../../../utils/RouteNamingConfig";
import {useEffect} from "react";
import {usePostSignUp} from "../post-sign-up/usePostSignUp";
import InputFieldErrorMessage from "../../../components/errors/InputFieldErrorMessage";
import SweetAlert2 from "react-sweetalert2";
import usePostForgotPassword from "./usePostForgotPassword";

const PostForgotPassword =  () => {
    let navigate = useNavigate();
    const email = sessionStorage.getItem('password_reset_email');
    const {handleResend,error,setError,loading,swalProps,setSwalProps} = usePostForgotPassword();

    useEffect(()=>{
        if(!email){
            navigate(RECRUITER_FORGOT_PASSWORD);
        }
    })

    const emailObject = {"email":email}
    return (
        <div className="col-lg-6">
            <div className="recruiter-spacious-section position-relative h-100 d-flex align-items-lg-center justify-content-center">
                <div className="position-absolute start-0 recruiter-sign-up-back-button">
                    <Link to={RECRUITER_FORGOT_PASSWORD}>
                        <img src={backArrow} className="img-fluid" alt="back arrow" />
                        <span className="small text-secondary ps-3"> Go back</span>
                    </Link>
                </div>
                <div>
                    <div className="row align-items-center justify-content-center ">
                        <div className="col-12 px-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className="text-center">
                                        <img
                                            src={mobileLogo}
                                            className="img-fluid d-md-none py-6 "
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="text-center">
                                        <h2 className="display-5 fw-medium text-dark pb-3">
                                            Please check your email
                                        </h2>
                                        <p className="mb-0">
                                            We’ve sent a password reset link to
                                        </p>
                                        <p className="mb-0 pb-7">
                        <span className="fw-semibold text-secondary">
                          {email}
                        </span>{" "}
                                        </p>
                                        <p className="mb-0 small">
                                            Please don’t forget to check spam folder as well if you
                                        </p>
                                        <p className="mb-0 pb-7 small">
                                            don’t receive the mail in your primary inbox
                                        </p>
                                        <p className="mb-0 text-secondary small">
                                            Didn’t get the mail?{" "}
                                            {
                                                loading ?
                                                    <a className="fw-semibold">Please Wait...</a>
                                                    :
                                                    <a onClick={event => handleResend(emailObject)} className="fw-semibold cursor-pointer">Resend</a>
                                            }{" "}
                                        </p>
                                        <InputFieldErrorMessage error={error}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SweetAlert2 {...swalProps}
                         didClose={() => {
                             setSwalProps({})
                         }}
            />
        </div>
    );
}

export default PostForgotPassword;