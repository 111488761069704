import {Link, useNavigate, useSearchParams} from "react-router-dom";
import backArrow from "../../../assets/images/icons/arrow-left.svg";
import mobileLogo from "../../../assets/images/icons/logo-for-signup.svg";
import {
    RECRUITER_FORGOT_PASSWORD,
    RECRUITER_PROFILE,
    RECRUITER_SEARCH_CANDIDATE,
    RECRUITER_SIGN_IN
} from "../../../utils/RouteNamingConfig";
import {useEffect, useState} from "react";
import {useAuthContext} from "../../../context/useAuthContext";


const ResetPasswordConfirm = () => {
    let tempUser = sessionStorage.getItem('temp_user_object');
    const [user,setUser] = useState<any>(null);
    let navigate = useNavigate();
    const {dispatch}:any= useAuthContext();

    useEffect(()=> {
        if(tempUser){
            setUser(JSON.parse(tempUser));
        }else{
            navigate(RECRUITER_SIGN_IN);
        }
    },[])

    const handleClick = () => {
        localStorage.setItem('user',JSON.stringify(user));
        dispatch({type:'LOGIN',payload:user});
        if(user.status && user.status=='verified') {
            navigate(RECRUITER_SEARCH_CANDIDATE);
        }else {
            navigate(RECRUITER_PROFILE);
        }
    }
    return(
        <div className="col-lg-6">
            <div className="position-relative h-100 d-flex align-items-md-center justify-content-center">
                <div className="position-absolute start-0 recruiter-sign-up-back-button">
                    <Link to={RECRUITER_SIGN_IN}>
                        <img src={backArrow} className="img-fluid" alt="back arrow" />
                        <span className="small text-secondary ps-3"> Go back</span>
                    </Link>
                </div>
                <div style={{ paddingTop: "4rem", paddingBottom: "4rem" }}>
                    <div className="row align-items-center justify-content-center ">
                        <div className="col-md-7 col-12 px-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className="text-center">
                                        <img
                                            src={mobileLogo}
                                            className="img-fluid d-md-none py-6 "
                                            alt=""
                                        />
                                        <h2 className="display-5 fw-semibold text-dark pb-3 pb-md-3">
                                            Password reset
                                        </h2>
                                        <p className="text-secondary mb-0 pb-7">
                                            Your password has been successfully reset. Continue to sign in automagically!
                                        </p>
                                        <div className="col-12 ">
                                            <button
                                                className="btn btn-primary w-100"
                                                onClick={handleClick}
                                            >
                                                Continue
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPasswordConfirm;