import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import BackButton from "../../../components/buttons/BackButton";
import NextButtonDynamic from "../../../components/buttons/NextButtonDynamic";
import RequiredFieldError from "../../../components/errors/RequiredFieldError";
import MultipleSkillsPageHeading from "../../../components/headings/MultipleSkillsPageHeading";
import ProgressBar from "../../../components/progress-bar/ProgressBar";
import Option from "../../../components/utils/Option";
import SalaryReportGenerationLoader from "../../../components/utils/SalaryReportGenerationLoader";
import SingleValue from "../../../components/utils/SingleValue";
import { AppStorageService } from "../../../services/AppStorageService";
import {
  getEnglishLevels,
  getSalaryReport,
  getWorkTypes,
} from "../../../services/SalaryDetailsService";
import * as routeNameList from "../../../utils/RouteNamingConfig";
import { SALARY_REPORT_PAGE } from "../../../utils/RouteNamingConfig";

const OtherDetails = () => {
  let storage = new AppStorageService();
  let selectedSkills = storage.getSelectedSkills();
  let selectedExperience = storage.getSelectedExperience();
  storage.setFeedBackSubmitted(false);
  let englishLevelClassWithError = "col select__control_error";
  let englishLevelClassWithoutError = "col";
  let workTypeClassWithError = "col-12 select__control_error";
  let workTypeClassWithoutError = "col-12 ";
  let salaryClassWithError = "input-group error-salary";
  let salaryClassWithoutError = "input-group";
  const englishLevelRef = useRef(null);
  const workTypeRef = useRef(null);
  const salaryRef = useRef(null);

  const [progressStep, setProgressStep] = useState("second");
  const [generatingLoader, setGeneratingLoader] = useState(false);
  const [title, setTitle] = useState("Just a few more details & we’re done");
  const [subTitle, setSubTitle] = useState(
    "A little more info will help us to generate better report for you"
  );
  const [numberOfCalculationDone, setNumberOfCalculationDone] = useState(0);
  const [salaryReportErrorMsg, setSalaryReportErrorMsg] = useState("");

  const [englishLevels, setEnglishLevels] = useState([]);
  const [selectedEnglishLevel, setSelectedEnglishLevel] = useState(
    storage.getSelectedEnglishLevel
  );
  const [workTypes, setWorkTypes] = useState([]);
  const [selectedWorkType, setSelectedWorkType] = useState(
    storage.getSelectedWorkType
  );
  const [currentSalary, setCurrentSalary] = useState("");
  const [currentSalaryError, setCurrentSalaryError] = useState(false);
  const [englishLevelError, setEnglishLevelError] = useState(false);
  const [salaryError, setSalaryError] = useState(false);
  const [workTypeError, setWorkTypeError] = useState(false);
  const [enableProceedButton, setEnableProceedButton] = useState(false);

  /*    const state:any =useSelector((state)=> state);
    const dipatch = useDispatch()
    const {depositMoney} = bindActionCreators(actionCreators,dipatch);
    console.log(depositMoney);
    console.log(state);*/

  let englishLevelsFromStorage = storage.getAllEnglishLevels();
  let workTypesFromStorage = storage.getAllWorkTypes();
  let currentSalaryFromStorage = storage.getCurrentSalary();

  let navigate = useNavigate();

  const fetchEnglishLevels = async () => {
    const englishLevelsFromServer = await getEnglishLevels();
    let englishLevelOptions: any = [];
    if (englishLevelsFromServer && englishLevelsFromServer.data) {
      englishLevelsFromServer.data.map((item: any) => {
        let temp: any = {};
        temp.value = item.id;
        temp.label = item.name;
        temp.title = item.name;
        temp.subTitle = item.display;
        englishLevelOptions.push(temp);
      });
      storage.storeAllEnglishLevels(englishLevelOptions);
      setEnglishLevels(englishLevelOptions);
    } else {
      console.log("Something went wrong");
    }
  };
  const fetchWorkTypes = async () => {
    const workTypesFromServer = await getWorkTypes();
    let workTypesOptions: any = [];
    if (workTypesFromServer && workTypesFromServer.data) {
      workTypesFromServer.data.map((item: any) => {
        let temp: any = {};
        temp.value = item.id;
        temp.label = item.name;
        workTypesOptions.push(temp);
      });
      storage.storeAllWorkTypes(workTypesOptions);
      setWorkTypes(workTypesOptions);
    } else {
      console.log("Something went wrong");
    }
  };

  useEffect(() => {
    if (
      !(
        selectedSkills &&
        selectedSkills.length > 0 &&
        selectedExperience &&
        selectedExperience.length > 0
      )
    ) {
      navigate("/");
    }
    if (currentSalaryFromStorage) {
      setCurrentSalary(currentSalaryFromStorage);
    }
    if (englishLevelsFromStorage != null) {
      setEnglishLevels(englishLevelsFromStorage);
    } else {
      fetchEnglishLevels();
    }

    if (workTypesFromStorage != null) {
      setWorkTypes(workTypesFromStorage);
    } else {
      fetchWorkTypes();
    }
  }, []);

  const changeFormInput = (event: any) => {
    let currentSalary = event.target.value;
    setCurrentSalaryError(false);
    //@ts-ignore
    salaryRef.current.className = salaryClassWithoutError;
    setSalaryError(false);
    if (currentSalary > 999999) {
      currentSalary = shortenNumber(currentSalary);
      setCurrentSalary(currentSalary);
    }
    if (currentSalary < 1000) {
      setCurrentSalaryError(true);
    }
    if (currentSalary < 0) {
      currentSalary = Math.abs(currentSalary) > 0 ? Math.abs(currentSalary) : 0;
      setCurrentSalary(currentSalary);
      validateProceedButton(
        currentSalary,
        selectedEnglishLevel,
        selectedWorkType
      );
      return false;
    }
    if (/^0/.test(currentSalary) || /^-0/.test(currentSalary)) {
      return false;
    }
    if (currentSalary.toString().length > 6) {
      validateProceedButton(
        currentSalary,
        selectedEnglishLevel,
        selectedWorkType
      );
      return false;
    }
    setCurrentSalary(currentSalary);
  };

  const shortenNumber = (num: number): number => {
    const numStr = num.toString();
    if (numStr.length > 6) {
      return parseInt(numStr.substring(0, numStr.length - 1), 10);
    }
    return num;
  };

  const handleEnglishLevels = (values: any) => {
    //@ts-ignore
    englishLevelRef.current.className = englishLevelClassWithoutError;
    setEnglishLevelError(false);
    setSelectedEnglishLevel(values);
    if (values) {
      storage.storeSelectedEnglishLevel(values);
    } else {
      storage.clearSelectedEnglishLevel();
    }
  };

  const handleWorkTypes = (values: any) => {
    //@ts-ignore
    workTypeRef.current.className = workTypeClassWithoutError;
    setWorkTypeError(false);
    setSelectedWorkType(values);
    if (values) {
      storage.storeSelectedWorkType(values);
    } else {
      storage.clearSelectedWorkType();
    }
  };

  const validateProceedButton = (
    salary: any,
    englishData: any,
    workTypeData: any
  ) => {
    setSalaryReportErrorMsg("");
    setEnglishLevelError(false);
    setWorkTypeError(false);
    setSalaryError(false);
    //@ts-ignore
    englishLevelRef.current.className = englishLevelClassWithoutError;
    //@ts-ignore
    workTypeRef.current.className = workTypeClassWithoutError;
    //@ts-ignore
    salaryRef.current.className = salaryClassWithoutError;

    let amount = salary;
    let validAmount = false;
    if (amount > 999 && amount <= 999999) {
      validAmount = true;
      storage.storeCurrentSalary(amount);
    } else {
      //@ts-ignore
      salaryRef.current.className = salaryClassWithError;
      setSalaryError(true);
    }
    //@ts-ignore
    if (
      validAmount &&
      englishData != null &&
      Object.entries(englishData).length > 0 &&
      workTypeData != null &&
      Object.entries(workTypeData).length > 0
    ) {
      return true;
      //setEnableProceedButton(true);
    } else {
      if (!englishData || Object.entries(englishData).length <= 0) {
        setEnglishLevelError(true);
        //@ts-ignore
        englishLevelRef.current.className = englishLevelClassWithError;
      }
      if (!workTypeData || Object.entries(workTypeData).length <= 0) {
        setWorkTypeError(true);
        //@ts-ignore
        workTypeRef.current.className = workTypeClassWithError;
      }
      return false;
      //setEnableProceedButton(false);
    }
  };

  const handleNext = () => {
    if (
      validateProceedButton(
        currentSalary,
        selectedEnglishLevel,
        selectedWorkType
      )
    ) {
      proceed();
    }
    return false;
  };

  function proceed() {
    setSalaryReportErrorMsg("");
    setProgressStep("third");
    setGeneratingLoader(true);
    setTitle("Hang in there ...");
    setSubTitle("We’re crafting the report right now");
    for (let j = 1; j < 4; j++) {
      setTimeout(() => {
        setNumberOfCalculationDone(j);
      }, 700 * j);
    }
    let selectedSkills = storage.getSelectedSkills();
    let selectedExperience = storage.getSelectedExperience();
    let selectedCurrentlyWorking = storage.getCurrentlyWorking();

    let engLvlDetails: any = selectedEnglishLevel;
    let engLvl = null;
    if (engLvlDetails && Object.entries(engLvlDetails).length > 0) {
      engLvl = engLvlDetails.value;
    }

    let workTypeDetails: any = selectedWorkType;
    let wrkTyp: any = null;
    if (selectedWorkType && Object.entries(selectedWorkType).length > 0) {
      wrkTyp = workTypeDetails.value;
    }

    let payload: any = {};
    payload["skill"] = [];
    payload["current_salary"] = currentSalary;
    payload["eng_lng_level"] = engLvl;
    payload["work_type"] = wrkTyp;
    payload["user_token"] = "";

    if (
      selectedSkills &&
      selectedSkills.length > 0 &&
      selectedExperience &&
      selectedExperience.length > 0
    ) {
      selectedSkills.map((item, index) => {
        let intermediateArr: any = {};
        intermediateArr["primary"] = "Yes";
        intermediateArr["currentlyWorkingAt"] = selectedCurrentlyWorking[index]
          ? selectedCurrentlyWorking[index]
          : false;
        intermediateArr["disabled"] = false;
        intermediateArr["id"] = item.value;
        intermediateArr["experience"] = selectedExperience[index]
          ? selectedExperience[index].value
          : "";
        intermediateArr["currently_working"] = intermediateArr[
          "currentlyWorkingAt"
        ]
          ? "Yes"
          : "No";
        payload["skill"].push(intermediateArr);
      });

      const res = async () => {
        const salaryReportFromServer = await getSalaryReport(
          JSON.stringify(payload)
        );
        if (
          salaryReportFromServer &&
          salaryReportFromServer.data &&
          salaryReportFromServer.data.avg_salary
        ) {
          storage.setSalaryReport(salaryReportFromServer.data);
          navigate(SALARY_REPORT_PAGE);
        } else {
          setProgressStep("second");
          setGeneratingLoader(false);
          setTitle("Just a few more details & we’re done");
          setSubTitle(
            "A little more info will help us to generate better report for you"
          );
          setSalaryReportErrorMsg("Something Went Wrong");
          console.log("invalid");
        }
      };
      setTimeout(() => {
        res();
      }, 3000);
    } else {
      console.log("Please go to home");
    }
  }
  return (
    <>
      <div className="container position-relative z-2">
        <div className="row">
          <MultipleSkillsPageHeading title={title} description={subTitle} />
        </div>

        <div className="row justify-content-center mb-7 pb-7">
          <div className="col-12 col-md-10 col-lg-5">
            <div className="position-relative shadow rounded-3 bg-white p-7 border border-2 border-primary">
              {/* Loader: Starts */}
              {/* Toggle between 'd-none' and 'd-block' classes to hide or show the loader */}
              {generatingLoader ? (
                <SalaryReportGenerationLoader
                  calculation={numberOfCalculationDone}
                />
              ) : (
                " "
              )}
              {/*<!-- Loader: Ends -->*/}

              <div
                className="position-relative"
                style={{ marginBottom: 20, zIndex: 999 }}
              >
                <ProgressBar step={progressStep} />
              </div>

              <form>
                <div className="row mb-6 gy-6">
                  <div className="col-12" ref={englishLevelRef}>
                    <label htmlFor="englishLevel" className="form-label">
                      English level
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={false}
                      name="englishLevels"
                      options={englishLevels}
                      defaultValue={selectedEnglishLevel}
                      placeholder={<>Select</>}
                      onChange={handleEnglishLevels}
                      components={{ SingleValue, Option }}
                    />
                    <RequiredFieldError show={englishLevelError} />
                  </div>
                  <div className="col-12 ">
                    <label htmlFor="primarySkill" className="form-label">
                      Current salary, BDT
                    </label>
                    <div className="input-group" ref={salaryRef}>
                      <span className="input-group-text cash-input pe-2 error-salary-input">
                        ৳
                      </span>
                      <input
                        min="0"
                        type="number"
                        className="form-control cash-input px-0 error-salary-input"
                        value={currentSalary}
                        //defaultValue={currentSalary}
                        onChange={changeFormInput}
                      />
                      <span className="input-group-text cash-input error-salary-input">
                        BDT
                      </span>
                    </div>
                    <RequiredFieldError
                      show={salaryError && !currentSalaryError}
                    />
                    {currentSalaryError ? (
                      <span className="small pt-1 error-text">
                        4 to 6 digit number is allowed
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-12" ref={workTypeRef}>
                    <label htmlFor="workType" className="form-label">
                      Work type
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={false}
                      name="workTypes"
                      options={workTypes}
                      defaultValue={selectedWorkType}
                      placeholder={<>Select</>}
                      onChange={handleWorkTypes}
                    />
                    <RequiredFieldError show={workTypeError} />
                  </div>
                </div>
                
              </form>

              {salaryReportErrorMsg ? (
                <span
                  style={{
                    padding: 10,
                    float: "left",
                    width: "100%",
                    textAlign: "center",
                    fontSize: 11,
                    color: "red",
                  }}
                >
                  {salaryReportErrorMsg}
                </span>
              ) : (
                ""
              )}
              <div className="row g-3 justify-content-between">
                <div className="col-md">
                  <NextButtonDynamic
                    show={true}
                    text={"Evaluate salary"}
                    onClick={handleNext}
                  />
                </div>
                <div className="col-md order-md-first">
                  <BackButton
                    goto={routeNameList.MULTIPLE_SKILLS_PAGE}
                    text={"Back"}
                  />
                </div>
              </div>
              <p className="small text-center mt-3 mb-0">
                Report accuracy will be higher if more info is provided
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OtherDetails;
