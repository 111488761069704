import React from 'react'

const WhiteButton = (props: any) => {
    const text = props.text;
    const type = props.type
    const className = props.className
    const disabled = props.disabled
  return (
    <button disabled={disabled} onClick={props.onclick} className={`${className} btn btn-secondary-gray`}
     type={type}>
        {text}
    </button>
  )
}

export default WhiteButton