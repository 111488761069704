import checkedIcon from "../../../../assets/images/icons/check-icon.svg";

const NoSavedCandidateFound = () => {
  return (
    <div className="py-6">
    <div className="bg-light p-4 rounded-3">
      <p
        className="mb-0 fw-semibold text-black pb-4"
        style={{ fontSize: "20px" }}
      >
        {" "}
        No candidates found
      </p>
    </div>
  </div>
  )
}

export default NoSavedCandidateFound;
