import {
    candidateRecruiterPrivateRoutes,
    candidateProfileRoutes,
    salaryReportRoutes,
    salaryDetailRoutes
} from "./routes/CandidateRoutes";
import {homeRoutes, inbox, notFound, privacyPolicyRoutes, termsRoutes} from "./routes/CommonRoutes";
import {
    recruiterAuthRoutes,
    recruiterRoutes,
    recruiterTermsRoutes,
    recruiterVerifyResetPasswordRoute,
    recruiterVerifyRoute
} from "./routes/RecruiterRoutes";
import Chat from "./views/chat/Chat";

const charRoute = {
    path: '/chat',
    element: <Chat/>
}

const allRoutes = [
    homeRoutes,
    termsRoutes,
    notFound,
    salaryDetailRoutes,
    salaryReportRoutes,
    candidateProfileRoutes,
    privacyPolicyRoutes,
    recruiterRoutes,
    recruiterVerifyRoute,
    recruiterTermsRoutes,
    recruiterAuthRoutes,
    recruiterVerifyResetPasswordRoute,
    candidateRecruiterPrivateRoutes,
    charRoute,
    inbox
];

export {allRoutes};