export const BASE_URL = "/";
export const HOME_PAGE = BASE_URL;
export const MULTIPLE_SKILLS_PAGE = BASE_URL+"multiple-skills"
export const OTHER_DETAILS_PAGE = BASE_URL+"other-details"
export const SALARY_REPORT_PAGE = BASE_URL+"salary-report"
export const TERMS_AND_CONDITIONS_PAGE = BASE_URL+"terms"
export const PRIVACY_POLICY_PAGE = BASE_URL+"privacy-policy"
export const CANDIDATE_PROFILE_PAGE = BASE_URL+"candidate/profile";
export const CANDIDATE_RECRUITER_PROFILE_BASE_URL = BASE_URL+"recruiter";
export const CANDIDATE_RECRUITER_PROFILE_PAGE_WITH_ID = CANDIDATE_RECRUITER_PROFILE_BASE_URL+"/:user/details";
export const RECRUITER_BASE_URL = BASE_URL+"recruiter";
export const RECRUITER_AUTH_BASE_URL = RECRUITER_BASE_URL+"/auth";
export const RECRUITER_SIGN_UP = RECRUITER_AUTH_BASE_URL+"/sign-up";
export const RECRUITER_POST_SIGN_UP = RECRUITER_BASE_URL+"/post-sign-up";
export const RECRUITER_SIGN_IN = RECRUITER_AUTH_BASE_URL+"/sign-in";
export const RECRUITER_FORGOT_PASSWORD = RECRUITER_AUTH_BASE_URL+"/forgot-password";
export const RECRUITER_POST_FORGOT_PASSWORD = RECRUITER_AUTH_BASE_URL+"/post-forgot-password";
export const RECRUITER_RESET_PASSWORD = RECRUITER_AUTH_BASE_URL+"/reset-password";
export const RECRUITER_RESET_PASSWORD_CONFIRM = RECRUITER_AUTH_BASE_URL+"/reset-password-confirm";
export const RECRUITER_PROFILE = RECRUITER_BASE_URL+"/profile";
export const RECRUITER_VERIFY_PAGE = RECRUITER_BASE_URL+"/verify";
export const RECRUITER_VERIFY_RESET_PASSWORD_PAGE = RECRUITER_BASE_URL+"/verify-reset-password";
export const RECRUITER_TERMS = RECRUITER_BASE_URL+"/terms";
export const RECRUITER_SEARCH_CANDIDATE = RECRUITER_BASE_URL+"/search-candidate";
export const RECRUITER_CANDIDATE_PROFILE_BASE_URL = RECRUITER_BASE_URL+"/candidate-profile";
export const RECRUITER_CANDIDATE_PROFILE_WITH_USER_ID = RECRUITER_CANDIDATE_PROFILE_BASE_URL+"/:user";
export const INBOX = BASE_URL+"inbox"
export const INBOX_PANEL = BASE_URL+"inbox/panel"

