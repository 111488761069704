import {
    CommonErrorMsg,
    InvalidEmailDomainErrorMsg,
    InvalidEmailDomainList,
    InvalidEmailErrorMsg, InvalidPasswordErrorMsg,
    RequiredFieldCommonErrorMsg
} from "../../../utils/CommonConstants";
import validator from "validator";
import {useState} from "react";
import {signIn as recruiterSignIn} from "../../../services/RecruiterAuthenticationService";
import {useAuthContext} from "../../../context/useAuthContext";
import {useNavigate} from "react-router-dom";
import {RECRUITER_PROFILE, RECRUITER_SEARCH_CANDIDATE} from "../../../utils/RouteNamingConfig";

type SignInObject = {
    email:string;
    password:string
}
export const useSignIn = () => {
    const [emailError,setEmailError] = useState<string|null>(null);
    const [passError,setPassError] = useState<string|null>(null);
    const [isLoading,setIsLoading] = useState<boolean | null>(false);
    const [error,setError] = useState<string|null>(null);
    const {dispatch}:any= useAuthContext();
    let navigate = useNavigate();


    const validateEmail = (email:string) =>{
        if(!email || email==''){
            setEmailError(RequiredFieldCommonErrorMsg);
            return false;
        }

        if(!validator.isEmail(email)){
            setEmailError(InvalidEmailErrorMsg);
            return false;
        }
        return true;

    }

    const validatePassword = (password:string) =>{
        if(!password || password==''){
            setPassError(RequiredFieldCommonErrorMsg);
            return false;
        }
        let re = /^(?=.*[a-zA-Z0-9])(?=.*[-_#$%&@!+=<>*{}():;"'|?/^~,.`\[\]\\\\]).{6,32}$/;
        if(!re.test(password)){
            setPassError(InvalidPasswordErrorMsg);
            return false;
        }
        return true;
    }

    const signIn = async (email:string, password:string) =>{
        setError(null);
        setIsLoading(true);
        let signInData:SignInObject = {
            email: email,
            password: password
        };

        const response = await recruiterSignIn(signInData);

        if(response && response.success && response.data){
            localStorage.setItem('user',JSON.stringify(response.data));
            dispatch({type:'LOGIN',payload:response.data})
            setIsLoading(false);

            if(response.data.status && response.data.status=='verified') {
                navigate(RECRUITER_SEARCH_CANDIDATE);
            }else {
                navigate(RECRUITER_PROFILE);
            }

        }else{
            setIsLoading(false);
            setError(response.message ?? CommonErrorMsg)
        }
    }

    return {emailError,setEmailError,passError,setPassError,
        validateEmail,validatePassword,signIn,isLoading,error,setError};
}