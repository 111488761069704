import {useRef, useState} from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import paymentIcon from "../../../assets/images/icons/payment-icon.svg";
import cardIcon from "../../../assets/images/icons/payment-method.svg";
import question from "../../../assets/images/icons/question.svg";
import searchCandidate from "../../../assets/images/icons/search-candidate-icon.svg";
import NavTabs from "../../../components/recruiter/profile/NavTabs";
import Announcement from  "../../../components/recruiter/profile/Announcement";
import useRecruiterProfile from "./useRecruiterProfile";
import EmailVerificationConfirmationModal
    from "../../../components/recruiter/modals/EmailVerificationConfirmationModal";
import Select from "react-select";
import InputFieldErrorMessage from "../../../components/errors/InputFieldErrorMessage";
import DisableEnableButton from "../../../components/buttons/DisableEnableButton";
import SuccessMessage from "../../../components/SuccessMessage";
import {RecruiterStatusCollection} from "../../../utils/CommonConstants";
import {useAuthContext} from "../../../context/useAuthContext";
import RecruiterStatusHeading from "../../../components/recruiter/RecruiterStatusHeading";
import RecruiterStatusHeadingInProfilePage from "../../../components/recruiter/RecruiterStatusHeadingInProfilePage";
import ProfileReview from "../../../components/recruiter/profile/ProfileReview";
import ProfileReject from "../../../components/recruiter/profile/ProfileReject";
import ProfileStatusAnnouncementModal from "../../../components/recruiter/profile/ProfileStatusAnnouncementModal";
import ProfileVerificationSubmitModal from "../../../components/recruiter/modals/ProfileVerificationSubmitModal";
import {submitRecruiterProfile} from "../../../services/RecruiterProfileService";
import {RECRUITER_SEARCH_CANDIDATE} from "../../../utils/RouteNamingConfig";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    dataLayer: {
        userId: '001',
        userProject: 'project',
        page: 'home'
    },
    dataLayerName: 'PageDataLayer'
}

function RecruiterProfile() {
    const {
        activeClass,showSignUpMsg,clearSignUpMsg,classForAnnouncement,setClassForAnnouncement,
        name,handleName,countries,jobTitle,handleJobTitle,linkedinProfile,handleLinedInProfile,mobileNumber,handleMobileNumber,
        whatsappNumber,handleWhatsAppNumber,sameAsMobileNumber,handleSameAsMobileNumber,recruiter,handleCountry,
        saveProfile,error,successMsg,isLoading,companyName,handleCompanyName,companySite,handleCompanySite,
        companyFoundingYear,handleCompanyFoundingYear, companyDescription,handleCompanyDescription,
        companyOpportunityDescription,handleCompanyOpportunityDescription,handleCompanyCountry,companyTypes,handleCompanyType,
        companyNumberOfEmployees,employeeRanges,handleNumberOfEmployee,selectedCountry,selectedCompanyCountry,
        selectedEmployeeRange,selectedCompanyType,saveAndSubmitProfile,nameError,validateFields,nameInputRef,
        jobTitleError,jobTitleInputRef, linkedInpProfileError,linkedInpProfileInputRef,
        mobileNumberError,mobileNumberInputRef,whatsAppNumberError,whatsAppNumberInputRef,
        companyNameError,companyNameInputRef,companySiteError,companySiteInputRef,companyFoundingYearError,
        companyFoundingYearInputRef,companyDescriptionError,companyDescriptionInputRef,countryError,countryInputRef,companyCountryError,companyCountryInputRef,companyNumberOfEmployeeError,
        companyNumberOfEmployeeInputRef,companyTypeError,companyTypeInputRef,showSubmissionModal,setShowSubmissionModal,
        isSubmitLoading,hideSubmitBtn,nameInHeading
    }
        = useRecruiterProfile();
    const {user}:any= useAuthContext();

    TagManager.dataLayer(tagManagerArgs)

    const hideModal = () => {
        setShowSubmissionModal(false);
    }

    const profileSubmissionModalObject =  {
        hideModal: hideModal,
        showModal: showSubmissionModal,
        submitForm: saveAndSubmitProfile
    }



    return (
        <>
            <EmailVerificationConfirmationModal show={showSignUpMsg} clearMsg={clearSignUpMsg}/>
            <ProfileStatusAnnouncementModal type={user.status}/>
            <section className="bg-light padding-bottom">
                <div className="container">
                    {/* recruiter name :start */}
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="pt-6 pb-4 pb-lg-6 pb-lg-7 mb-lg-5">
                                <RecruiterStatusHeadingInProfilePage type={user.status}/>
                                <h3 className="text-dark">Hello {nameInHeading} 👋</h3>
                            </div>
                        </div>

                        <div className="col-lg-6 col-12 text-lg-end ">
                            <div className="pt-0 pt-lg-6 pb-5 pb-lg-7 mb-lg-5 me-lg-7 d-flex d-lg-inline-block justify-content-between">
                                <button className="btn btn-secondary-gray small me-md-3 mb-0 mb-md-2 mb-lg-0">
                                    Contacted <span>(3)</span>
                                </button>
                                <Link to={RECRUITER_SEARCH_CANDIDATE} >
                                <button  className="btn btn-primary">
                                    <img src={searchCandidate} className="img-fluid" alt="" />
                                    <span className="small ps-2">Search for candidates</span>
                                </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* recruiter name :end */}

                    <hr className="d-block d-md-none" />

                    {/* Tabs:Start */}
                    <div className="row">
                        <Announcement setClassForAnnouncement={setClassForAnnouncement}/>
                        {/* tab-nav:start */}
                        <div className="col-12 col-md-2 border-end pe-0">
                            <NavTabs />
                        </div>
                        {/* tab-nav:end */}
                        {/* tab-container:start */}
                        <div className="col-12 col-md-10">
                            <div className="tab-content" id="nav-tabContent">
                                {/* profile:start */}
                                <div
                                    className={classForAnnouncement}
                                    id="nav-profile"
                                    role="tabpanel"
                                    aria-labelledby="nav-profile-tab"
                                >
                                    {/*  Personal: Starts */}
                                    <div className="row mb-6 mb-md-7 mt-6 mt-md-0 recruiter-profile-content" >
                                        <div className="col-12 col-md-4">
                                            <div className="ps-0 ps-md-5 pb-6">
                                                <h5 className="text-dark mb-1">Personal</h5>
                                                <p className="small mb-0">
                                                    Basic info about you as recruiter
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-8 px-0 px-md-3">
                                            <div className="card profile-card shadow">
                                                <form onSubmit={validateFields}>
                                                    <div className="row g-4 gx-md-3 gy-md-6 px-4 px-md-6 py-6">
                                                        <div className="col-12 col-lg-6">
                                                            <label htmlFor="inputName" className="form-label">
                                                                Full name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                ref={nameInputRef}
                                                                className={(nameError && nameError?.length>0) ?
                                                                    "form-control  error-input":"form-control"}
                                                                id="inputName"
                                                                autoComplete="off"
                                                                value={name}
                                                                placeholder="Enter your name"
                                                                onChange={(e)=>handleName(e)}
                                                            />
                                                            <InputFieldErrorMessage error={nameError}/>
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label
                                                                htmlFor="inputTitle"
                                                                className="form-label"
                                                            >
                                                                Position in company
                                                            </label>
                                                            <input

                                                                type="text"
                                                                ref={jobTitleInputRef}
                                                                className={(jobTitleError && jobTitleError?.length>0) ?
                                                                    "form-control  error-input":"form-control"}
                                                                id="inputTitle"
                                                                placeholder="e.g. HR"
                                                                autoComplete="off"
                                                                value={jobTitle}
                                                                onChange={(e)=>handleJobTitle(e)}
                                                            />
                                                            <InputFieldErrorMessage error={jobTitleError}/>
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label
                                                                htmlFor="inputTitle"
                                                                className="form-label"
                                                                ref={countryInputRef}
                                                            >
                                                                Country
                                                            </label>
                                                            <Select
                                                                className={(countryError && countryError?.length>0) ?
                                                                    "basic-single select__control_error":"basic-single"}
                                                                classNamePrefix="select"
                                                                isClearable={true}
                                                                isSearchable={false}
                                                                name="experiences"
                                                                options={countries}
                                                                placeholder={<>Country</>}
                                                                onChange={handleCountry}
                                                                value={selectedCountry}
                                                            />
                                                            <InputFieldErrorMessage error={countryError}/>
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label
                                                                htmlFor="inputLinkedin"
                                                                className="form-label"
                                                            >
                                                                Your LinkedIn profile
                                                            </label>
                                                            <input
                                                                type="url"
                                                                pattern="https://.*"
                                                                ref={linkedInpProfileInputRef}
                                                                className={(linkedInpProfileError && linkedInpProfileError?.length>0) ?
                                                                    "form-control social-input linkedin  error-input":"social-input linkedin form-control"}
                                                                id="inputLinkedin"
                                                                autoComplete="off"
                                                                placeholder="URL here"
                                                                value={linkedinProfile}
                                                                onChange={(e)=>handleLinedInProfile(e)}
                                                            />
                                                            <InputFieldErrorMessage error={linkedInpProfileError}/>
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label
                                                                htmlFor="inputTitle"
                                                                className="form-label"
                                                            >
                                                                Phone number
                                                            </label>
                                                            <input
                                                                type="number"
                                                                ref={mobileNumberInputRef}
                                                                className={(mobileNumberError && mobileNumberError?.length>0) ?
                                                                    "form-control  error-input":"form-control"}
                                                                id="inputNumber"
                                                                placeholder="e.g. 01700000000"
                                                                autoComplete="off"
                                                                value={mobileNumber}
                                                                onWheel={(e) =>
                                                                    (e.target as HTMLElement).blur()
                                                                }
                                                                onChange={(e)=>handleMobileNumber(e)}
                                                            />
                                                            <InputFieldErrorMessage error={mobileNumberError}/>
                                                        </div>

                                                        <div className="col-12 col-lg-6">
                                                            <label
                                                                htmlFor="inputTitle"
                                                                className="form-label"
                                                            >
                                                                Whatsapp number
                                                            </label>
                                                            <input
                                                                type="number"
                                                                ref={whatsAppNumberInputRef}
                                                                className={(whatsAppNumberError && whatsAppNumberError?.length>0) ?
                                                                    "form-control  error-input":"form-control"}
                                                                id="inputWhatsapp"
                                                                placeholder="e.g. 01700000000"
                                                                autoComplete="off"
                                                                value={whatsappNumber}
                                                                onWheel={(e) =>
                                                                    (e.target as HTMLElement).blur()
                                                                }
                                                                onChange={(e)=>handleWhatsAppNumber(e)}
                                                                disabled={sameAsMobileNumber}

                                                            />
                                                            <InputFieldErrorMessage error={whatsAppNumberError}/>
                                                            <div className="mt-1 d-flex">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={sameAsMobileNumber}
                                                                    onChange={(e)=>handleSameAsMobileNumber(e)}
                                                                />
                                                                <label
                                                                    className="form-check-label small ps-2 align-self-center"
                                                                    htmlFor="flexCheckDefault"
                                                                >
                                                                    Same as phone number
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  Personal: Ends */}
                                    {/*  Company: Starts */}
                                    <div className="row mt-6">
                                        <div className="col-12 col-md-4">
                                            <div className="ps-0 ps-md-5 pb-6">
                                                <h5 className="text-dark mb-1">Company</h5>
                                                <p className="small mb-0">Company related info </p>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-8 px-0 px-md-3">
                                            <div className="card profile-card shadow">
                                                <form onSubmit={validateFields}>
                                                    <div className="row g-4 gx-md-3 gy-md-6 px-4 px-md-6 py-6">
                                                        <div className="col-12 col-lg-6">
                                                            <label htmlFor="inputName" className="form-label">
                                                                Company name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                ref={companyNameInputRef}
                                                                className={(companyNameError && companyNameError?.length>0) ?
                                                                    "form-control  error-input":"form-control"}
                                                                id="inputName"
                                                                autoComplete="off"
                                                                placeholder="Company Name"
                                                                value={companyName}
                                                                onChange={(e)=>handleCompanyName(e)}
                                                            />
                                                            <InputFieldErrorMessage error={companyNameError}/>
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label
                                                                htmlFor="inputTitle"
                                                                className="form-label"
                                                            >
                                                                Website
                                                            </label>
                                                            <input
                                                                type="text"
                                                                ref={companySiteInputRef}
                                                                className={(companySiteError && companySiteError?.length>0) ?
                                                                    "form-control  error-input":"form-control"}
                                                                id="inputCompanySite"
                                                                placeholder="URL here"
                                                                autoComplete="off"
                                                                value={companySite}
                                                                onChange={(e)=>handleCompanySite(e)}
                                                            />
                                                            <InputFieldErrorMessage error={companySiteError}/>
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label
                                                                htmlFor="inputTitle"
                                                                className="form-label"
                                                                ref={companyCountryInputRef}
                                                            >
                                                                Country
                                                            </label>
                                                            <Select
                                                                className={(companyCountryError && companyCountryError?.length>0) ?
                                                                    "basic-single select__control_error":"basic-single"}
                                                                classNamePrefix="select"
                                                                isClearable={true}
                                                                isSearchable={false}
                                                                name="companyCountry"
                                                                options={countries}
                                                                placeholder={<>Country</>}
                                                                onChange={handleCompanyCountry}
                                                                value={selectedCompanyCountry}
                                                            />
                                                            <InputFieldErrorMessage error={companyCountryError}/>
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label
                                                                htmlFor="inputTitle"
                                                                className="form-label"
                                                            >
                                                                Founding year
                                                            </label>
                                                            <input
                                                                type="number"
                                                                ref={companyFoundingYearInputRef}
                                                                className={(companyFoundingYearError && companyFoundingYearError?.length>0) ?
                                                                    "form-control  error-input":"form-control"}
                                                                id="inputFoundingYear"
                                                                placeholder={"Founding Year"}
                                                                autoComplete="off"
                                                                value={companyFoundingYear}
                                                                onWheel={(e) =>
                                                                    (e.target as HTMLElement).blur()
                                                                }
                                                                onChange={(e)=>handleCompanyFoundingYear(e)}
                                                            />
                                                            <InputFieldErrorMessage error={companyFoundingYearError}/>
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label
                                                                htmlFor="inputTitle"
                                                                className="form-label"
                                                                ref={companyNumberOfEmployeeInputRef}
                                                            >
                                                                Number of employees
                                                            </label>
                                                            <Select
                                                                className={(companyNumberOfEmployeeError && companyNumberOfEmployeeError?.length>0) ?
                                                                    "basic-single select__control_error":"basic-single"}
                                                                classNamePrefix="select"
                                                                isClearable={true}
                                                                isSearchable={false}
                                                                name="employeeRange"
                                                                options={employeeRanges}
                                                                placeholder={"Number of Employees"}
                                                                onChange={handleNumberOfEmployee}
                                                                value={selectedEmployeeRange}
                                                            />
                                                            <InputFieldErrorMessage error={companyNumberOfEmployeeError}/>
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label
                                                                htmlFor="inputTitle"
                                                                className="form-label"
                                                                ref={companyTypeInputRef}
                                                            >
                                                                Company type
                                                            </label>
                                                            <Select
                                                                className={(companyTypeError && companyTypeError?.length>0) ?
                                                                    "basic-single select__control_error":"basic-single"}
                                                                classNamePrefix="select"
                                                                isClearable={true}
                                                                isSearchable={false}
                                                                name="companyTypes"
                                                                options={companyTypes}
                                                                placeholder={<>Company Type</>}
                                                                onChange={handleCompanyType}
                                                                value={selectedCompanyType}
                                                            />
                                                            <InputFieldErrorMessage error={companyTypeError}/>
                                                        </div>
                                                        <div className="col-12">
                                                            <label
                                                                htmlFor="inputWorkExperience"
                                                                className="form-label"
                                                            >
                                                                Description of the company
                                                            </label>
                                                            <textarea
                                                                ref={companyDescriptionInputRef}
                                                                className={(companyDescriptionError && companyDescriptionError?.length>0) ?
                                                                    "form-control  error-input":"form-control"}
                                                                id="inputWorkExperience"
                                                                placeholder="Write Here"
                                                                value={companyDescription}
                                                                onChange={(e)=>handleCompanyDescription(e)}
                                                            ></textarea>
                                                            <InputFieldErrorMessage error={companyDescriptionError}/>
                                                        </div>
                                                        <div className="col-12">
                                                            <label
                                                                htmlFor="inputWorkExperience"
                                                                className="form-label"
                                                            >
                                                                Description of the opportunity (optional)
                                                            </label>
                                                            <textarea
                                                                className="form-control"
                                                                id="inputWorkExperience"
                                                                placeholder="Write Here"
                                                                value={companyOpportunityDescription}
                                                                onChange={(e)=>handleCompanyOpportunityDescription(e)}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="row px-4 px-md-6 pb-6">
                                                        <div className="col-12">
                                                            <button
                                                                className="btn btn-primary w-100 w-md-auto"
                                                                type="button"
                                                                onClick={saveProfile}
                                                                disabled={isLoading}
                                                            >
                                                                {isLoading ? 'Processing..':'Save Changes'}
                                                            </button>
                                                            {hideSubmitBtn ? '':
                                                            <button
                                                                className="btn border w-100 w-md-auto mt-2 mt-lg-0 ms-lg-3"
                                                                type="submit"
                                                                disabled={isSubmitLoading}
                                                            >
                                                                {isSubmitLoading ? 'Processing..' : 'Save & Submit for verification'}
                                                            </button>
                                                            }
                                                        </div>
                                                    </div>

                                                    {(error && error.length>0) ? <InputFieldErrorMessage error={error} /> : ""}
                                                    {(successMsg && successMsg.length>0) ? <SuccessMessage msg={successMsg} /> : ""}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  Company: Ends */}
                                </div>
                                {/* profile:end */}
                            </div>
                        </div>
                        {/* tab-container:end */}
                    </div>
                    {/* Tabs:end */}
                </div>
            </section>
            <ProfileVerificationSubmitModal  profileSubmissionModalObject={profileSubmissionModalObject} />


        </>
    );
}

export default RecruiterProfile;