import AxiosService from "./AxiosService";
import {ApiBaseUrl} from "../utils/CommonConstants";
import {ApiEndPointService} from "./ApiEndPointService";

export async function getCandidateProfileDetails(userObject:any){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.RECRUITER_CANDIDATE_DETAILS,userObject,{});
}

export async function getRecruiterProfileDetails(userObject:any){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.RECRUITER_RECRUITER_DETAILS,userObject,{});
}

export async function reportCandidate(userObject:any){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.RECRUITER_CANDIDATE_REPORT,userObject,{});
}

export async function saveCandidate(userObject:any){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.RECRUITER_CANDIDATE_SAVE,userObject,{});
}

export async function unSaveCandidate(userObject:any){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.RECRUITER_CANDIDATE_UN_SAVE,userObject,{});
}

export async function markAsHired(userObject:any){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.RECRUITER_MARK_AS_HIRED,userObject,{});
}

export async function paymentReceived(userObject:any){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.RECRUITER_PAYMENT_RECEIVED,userObject,{});
}