type props = {
    msg: string|null
}
const SuccessMessage = (props:props) =>{
    let msg = props.msg;
    return (
        <>
            { msg && msg.length>0 ?
                <> <p className="small pt-1" style={{marginBottom:0,color:"green"}}>
                    {msg}
                </p>
                </>
                :
                ''}
        </>
    );
}

export default SuccessMessage;