import country from "../../../../assets/images/icons/globe-2.svg";
import flag from "../../../../assets/images/icons/flag.svg";
import user from "../../../../assets/images/icons/users-2.svg";
import companyType from "../../../../assets/images/icons/company-type.svg";
import WhiteButton from "../../../../components/buttons/WhiteButton";
import { Link } from "react-router-dom";
import CompanyDetails from "./CompanyDetails";

const HiredCandidate = (props: any) => {
  let extraDetails = props.extraDetails;
  return (
    <div
      className="p-6 rounded-2"
      style={{ backgroundColor: "#ECFDF3", border: "1px solid #039855" }}
    >
      <div className="row gy-4 pb-3">
        <div className="col-12">
          <p
            className="mb-0 common-text-size fw-semibold"
            style={{ color: "#054F31" }}
          >
            🎉 You were hired by this recruiter
          </p>
        </div>
        <CompanyDetails extraDetails={extraDetails} />
      </div>
      <div className="pt-4">
        <Link to="">
          <button className="w-100 btn whiteBtn">Go to the conversation</button>
        </Link>
      </div>
    </div>
  );
};

export default HiredCandidate;
