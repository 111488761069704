import React from "react";
import { Link } from "react-router-dom";
import WhiteButton from "../../../../components/buttons/WhiteButton";
import message from "../../../../assets/images/icons/message-text-square-02.svg";
import job from "../../../../assets/images/icons/job.svg";
import sadFace from "../../../../assets/images/icons/sad-face.svg";
import messageBtn from "../../../../assets/images/icons/message-white.svg";
import CandidateReport from "./CandidateReport";
import ContactCandidate from "./ContactCandidate";
import CandidateExtraDetails from "./CandidateExtraDetails";


const CandidateDetails = (props:any) => {
  let extraDetails = props.extraDetails;
  let detailsFunctionalities = props.detailsFunctionalities;

  return (
      <>
      {!extraDetails ? "":
    <div
      className="p-6 rounded-2"
      style={{ backgroundColor: "#F8FAFC" }}
    >
      <div className="row gy-4 pb-3">
        <CandidateExtraDetails extraDetails={extraDetails}/>
      </div>
      <div className="pt-4">
        <ContactCandidate detailsFunctionalities={detailsFunctionalities}/>
        <CandidateReport detailsFunctionalities={detailsFunctionalities}/>
      </div>
    </div>
}
      </>
  );
};

export default CandidateDetails;
