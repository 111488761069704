import { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
const CandidateProfileReport = () => {
  const [modalShowForReport, setModalShowForReport] = useState(false);



  const [val, setVal] = useState("  ");
  const textAreaRef: any = useRef(null);

  const resizeTextArea = () => {
   
    if(textAreaRef && textAreaRef.current){
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    }
  };

  useEffect(resizeTextArea, [val]);

  const onChange = (e: any) => {
    setVal(e.target.value);
  };
  const handelClick = (e:any) => {
    console.log(modalShowForReport);
    console.log("first")
    setModalShowForReport(true);
  }

  return (
    <>
      <button className="btn small align-self-center px-0" onClick={(e:any) => handelClick(e)}>Report profile </button>
      <Modal
        show={modalShowForReport}
        onHide={() => setModalShowForReport(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="px-6 pt-6 pb-0">
          <Modal.Title id="contained-modal-title-vcenter">
            <p
              className="mb-0"
              style={{ fontWeight: "600", fontSize: "20px", color: "#101828" }}
            >
              Found this profile inappropriate?
            </p>
            <p
              className="mb-0"
              style={{ fontWeight: "400", fontSize: "14px", color: "#475467" }}
            >
              Please report if you think it’s not real, insulting, spam, etc
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-5 px-6 pb-6">
          <form>
            <div className="row">
              <div className="col-12">
                <textarea
                  ref={textAreaRef}
                  value={val}
                  onChange={onChange}
                  rows={12}
                  style={{ resize: "none" }}
                  className="w-100 p-3 candidate-profile-report form-control"
                />
              </div>
              <div className="col-12">
                <div className="d-flex justify-content-end gap-3 pt-6">
                  <button
                    onClick={() => setModalShowForReport(false)}
                    className="btn border px-4 py-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => setModalShowForReport(false)}
                    className="btn btn-primary text-white px-4 py-2"
                  >
                    Submit the report
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CandidateProfileReport;
