import React from "react";

const TermsAndConditionForRecruiter = () => {
  return (
      <div className="container spacious-section pt-6">
        <div className="terms-conditions-holder">
          <h4 className="h3 fw-semibold mb-4 mb-md-6">
            Terms & conditions for recruiter
          </h4>
          <p className="mb-6">
            REMOTEDEVS.COM TERMS AND CONDITIONS FOR RECRUITERS
          </p>
          <section className="mb-7 pb-5">
            <h6 className="text-primary mb-6">Main rules:</h6>
            <div className="main-section d-flex mb-4">
              <p className="mb-0">
              <span className="fw-semibold">
                If you hire somebody using remotedevs.com you pay 500 USD for
                using our services.
              </span>
                <span>
              If a candidate works for less than one month in your company for any reason, we will cancel the fee in the form of credit for the next hire through RemoteDevs.com
              </span>
              </p>
            </div>
            <div className="main-section d-flex mb-4">
              <div>
                <p className="fw-semibold mb-0">
                  You promise to upload only the correct information and agree to
                  verification of it:
                </p>
                <p className="sub-section mb-0">
                  By registering your profile and sharing the information you
                  ensure that all the information you have provided is correct.
                </p>
                <p className="sub-section mb-0">
                  Please register with your work e-mail address and provide a link
                  to your LinkedIn profile. This will help us to ensure
                  high-quality job offers to the candidates. We might ask you to
                  provide company registration documents. We will do our best to
                  respond and verify your profile in 1 working day.
                </p>
              </div>
            </div>
            <div className="main-section d-flex mb-4">
              <div>
                <p className="fw-semibold mb-0">
                  You promise to behave correctly and professionally:
                </p>
                <p className="sub-section mb-0">
                  Only polite and friendly communication. No disrespect, swearing,
                  or threatening
                </p>
                <p className="sub-section mb-0">
                  No spam. You will not use this platform for uses other than
                  finding employees for your job opening.
                </p>
                <p className="mb-0 sub-section">
                  Only relevant offers. Please contact candidates with offers that
                  are relevant to them. Candidates may submit a complaint if you
                  do not follow this.
                </p>
                <p className="mb-0 sub-section">
                  Confidentiality. Things that candidates tell you might be
                  confidential. Do not share it anywhere.
                </p>
              </div>
            </div>

            <div className="main-section d-flex mb-4">
              <div>
                <p className="mb-0">
                <span className="fw-semibold">
                  You promise to report to us if you have hired somebody via our
                  platform.
                </span>
                  <br />
                  <span>
                  Please let us know if you have chosen the candidate and the
                  candidate accepted an offer from your company after
                  communicating via RemoteDevs.com no later than 1 day after
                  signing the offer or reaching an agreement.
                </span>
                </p>
              </div>
            </div>
            <p className="mb-0">
              If you do not follow these rules, you might be blocked from using
              our services.
            </p>
          </section>
          <h6 className="text-primary mb-4">Long list</h6>
          <section className="mb-7 pb-5">
            <div>
              <div className="main-section d-flex mb-4">
                <div>
                  <p className="fw-semibold mb-0">INTRODUCTION</p>
                  <p className="sub-section mb-0">
                    These terms and conditions ("Agreement") govern your use of
                    Remodevs.com ("Website") as a recruiter. By using the Website,
                    you agree to be bound by this Agreement.
                  </p>
                  <p className="sub-section mb-0">
                    We reserve the right to modify this Agreement at any time, and
                    the modifications will be effective upon posting on the
                    Website. Your continued use of the Website after such
                    modifications will constitute your acceptance of the updated
                    Agreement.
                  </p>
                </div>
              </div>
              <div className="main-section d-flex mb-4">
                <div>
                  <p className="fw-semibold mb-0">ACCOUNT REGISTRATION</p>
                  <p className="sub-section mb-0">
                    To use the Website as a recruiter, you must register for an
                    account and provide accurate and complete information.
                  </p>
                  <p className="sub-section mb-0">
                    You are responsible for maintaining the confidentiality of
                    your account login information and for all activities that
                    occur under your account.
                  </p>
                  <p className="sub-section mb-0">
                    You agree to notify us immediately of any unauthorized use of
                    your account or any other breach of security.
                  </p>
                </div>
              </div>

              <div className="main-section d-flex mb-4">
                <div>
                  <p className="fw-semibold mb-0">USE OF THE WEBSITE</p>
                  <p className="sub-section mb-0">
                    You agree to use the Website only for lawful purposes and in
                    compliance with all applicable laws, regulations, and industry
                    standards.
                  </p>
                  <p className="sub-section mb-0">
                    You agree to use the Website only to recruit candidates for
                    job openings that you are authorized to fill.
                  </p>
                  <p className="sub-section mb-0">
                    You agree to pay us a fee for each candidate hired through the
                    Website according to the fee schedule provided in this
                    Agreement and on the Website.
                  </p>
                  <p className="mb-0 sub-section">
                    You agree to comply with our policies and guidelines as posted
                    on the Website, including but not limited to our privacy
                    policy and community guidelines.
                  </p>
                </div>
              </div>

              <div className="main-section d-flex mb-4">
                <div>
                  <p className="fw-semibold mb-0">CONTENT</p>
                  <p className="sub-section mb-0">
                    You represent and warrant that any content you post or submit
                    to the Website, including but not limited to job postings and
                    candidate feedback, is accurate and not misleading.
                  </p>
                  <p className="sub-section mb-0">
                    You grant us a non-exclusive, worldwide, royalty-free, and
                    fully-paid license to use, reproduce, distribute, and display
                    the content on the Website for the purpose of providing the
                    services offered by the Website.
                  </p>
                  <p className="sub-section mb-0">
                    You agree to indemnify and hold us harmless from any claims,
                    damages, or liabilities arising from your content.
                  </p>
                </div>
              </div>

              <div className="main-section d-flex mb-4">
                <div>
                  <p className="fw-semibold mb-0">INTELLECTUAL PROPERTY</p>
                  <p className="sub-section mb-0">
                    The Website and all materials on the Website, including but
                    not limited to text, graphics, logos, images, and software,
                    are the property of us or our licensors and are protected by
                    copyright, trademark, and other intellectual property laws.
                  </p>
                  <p className="sub-section mb-0">
                    You may not use any of our intellectual property without our
                    prior written consent.
                  </p>
                </div>
              </div>

              <div className="main-section d-flex mb-4">
                <div>
                  <p className="fw-semibold mb-0">DISCLAIMER OF WARRANTIES</p>
                  <p className="sub-section mb-0">
                    THE WEBSITE IS PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY
                    KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, OR NON-INFRINGEMENT.
                  </p>
                  <p className="sub-section mb-0">
                    WE DO NOT WARRANT THAT THE WEBSITE WILL MEET YOU REQUIREMENTS
                    OR THAT THE OPERATION OF THE WEBSITE WILL BE UNINTERRUPTED OR
                    ERROR-FREE.
                  </p>
                </div>
              </div>
              <div className="main-section d-flex mb-4">
                <div>
                  <p className="fw-semibold mb-0">LIMITATION OF LIABILITY</p>
                  <p className="sub-section mb-0">
                    IN NO EVENT WILL WE BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL,
                    SPECIAL, OR INCIDENTAL DAMAGES, INCLUDING BUT NOT LIMITED TO
                    LOST PROFITS OR DATA, ARISING OUT OF OR IN CONNECTION WITH
                    THIS AGREEMENT OR YOUR USE OF THE WEBSITE, EVEN IF WE HAVE
                    BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                  </p>
                  <p className="sub-section mb-0">
                    OUR TOTAL LIABILITY TO YOU UNDER THIS AGREEMENT WILL BE
                    LIMITED TO THE AMOUNT PAID BY YOU TO US IN THE SIX MONTHS
                    PRECEDING THE EVENT GIVING RISE TO THE LIABILITY.
                  </p>
                </div>
              </div>
              <div className="main-section d-flex mb-4">
                <div>
                  <p className="fw-semibold mb-0">TERMINATION</p>
                  <p className="sub-section mb-0">
                    We may terminate this Agreement and your access to the Website
                    at any time, with or without cause or notice.
                  </p>
                  <p className="sub-section mb-0">
                    Upon termination, you must immediately cease using the Website
                    and delete all content you obtained through the Website.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <p className="mb-0">Thank you for using our website.</p>
        </div>
      </div>
  );
};

export default TermsAndConditionForRecruiter;
