export class ApiEndPointService {
    static SEARCH_SKILLS: string = "/search/skill";
    static GET_ALL_SKILLS: string = "/skills";
    static GET_ALL_COUNTRIES: string = "/countries";
    static GET_ALL_EMPLOYEE_RANGE: string = "/employee-range";

    static SEARCH_EXTRA_SKILL: string = "/extra-skill";
    static SEARCH_INDUSTRIES: string = "/industry";
    static SEARCH_JOB_TYPES: string = "/job-types";
    static GET_EXCLUSION_OF_INTREST: string = "/type/exclusion-interest";
  
    static GET_ENGLISH_LEVEL: string = "/type/language-proficiency-level";
    static GET_WORK_TYPES: string = "/type/work";
  
    static SSALARY_COMPARISON: string = "/salary-comparison";
    
    static GOOGLE_LOGIN: string = "/provider/login";
  
    static SALARY_FEEBACK_STORE: string = "/feedback-store";
    static SALARY_FEEBACK_UPDATE: string = "/feedback-update";
  
    static GET_YEARS_EXPERIENCE_CHART: string = "/chart/salary";

    static GET_USER_PROFILE: string = "/profile/get";
    static UPDATE_USER_PROFILE: string = "/profile";
    static UPLOAD_USER_CV: string = "/upload/cv";
    static DELETE_USER_CV: string = "/cv";
    static SAVE_PREFERENCES: string = "/preferences"
    static REPORT_BEING_HIRED: string = "/report-hired"

    static RECRUITER_SIGN_UP: string = "/recruiter/signup"
    static RECRUITER_SIGN_IN: string = "/recruiter/signin"
    static RECRUITER_VERIFY: string = "/recruiter/verify"
    static RECRUITER_SIGNUP_RESEND: string = "/recruiter/resend"
    static RECRUITER_PASSWORD_RESET_REQUEST: string = "/recruiter/password-reset-request"
    static RECRUITER_PASSWORD_RESET: string = "/recruiter/password-reset"
    static RECRUITER_PASSWORD_RESET_VERIFY: string = "/recruiter/password-reset-verify"
    static RECRUITER_PROFILE: string = "/recruiter/profile"
    static RECRUITER_PROFILE_SAVE_AND_SUBMIT: string = "/recruiter/profile-submit"
    static RECRUITER_SEARCH_CANDIDATE: string = "/recruiter/candidate-search"
    static RECRUITER_CANDIDATE_DETAILS: string = "/recruiter/candidate-details"
    static RECRUITER_RECRUITER_DETAILS: string = "/recruiter/recruiter-details"
    static RECRUITER_CANDIDATE_REPORT: string = "/report"
    static RECRUITER_CANDIDATE_SAVE: string = "/recruiter/candidate-save";
    static RECRUITER_CANDIDATE_UN_SAVE: string = "/recruiter/candidate-unsave";
    static RECRUITER_MARK_AS_HIRED: string = "/recruiter/mark-hired";
    static RECRUITER_PAYMENT_RECEIVED: string = "/recruiter/payment-receive";

    static INBOX: string = "/inbox";
    static MESSAGES: string = "/messages";



  }
  