import {
    RECRUITER_AUTH_BASE_URL, RECRUITER_BASE_URL, RECRUITER_CANDIDATE_PROFILE_WITH_USER_ID,
    RECRUITER_FORGOT_PASSWORD,
    RECRUITER_POST_FORGOT_PASSWORD, RECRUITER_POST_SIGN_UP, RECRUITER_PROFILE,
    RECRUITER_RESET_PASSWORD,
    RECRUITER_RESET_PASSWORD_CONFIRM, RECRUITER_SEARCH_CANDIDATE,
    RECRUITER_SIGN_IN,
    RECRUITER_SIGN_UP, RECRUITER_TERMS, RECRUITER_VERIFY_PAGE, RECRUITER_VERIFY_RESET_PASSWORD_PAGE
} from "../utils/RouteNamingConfig";
import RecruiterAuthenticationLayout from "../layouts/app/recruiter/RecruiterAuthenticationLayout";
import RecruiterSemiPublicRoute from "../utils/RecruiterSemiPublicRoute";
import SignUp from "../views/recruiter/sign-up/SignUp";
import SignIn from "../views/recruiter/sign-in/SignIn";
import ForgotPassword from "../views/recruiter/forgot-password/ForgotPassword";
import PostForgotPassword from "../views/recruiter/post-forgot-password/PostForgotPassword";
import ResetPassword from "../views/recruiter/reset-password/ResetPassword";
import ResetPasswordConfirm from "../views/recruiter/reset-password-confirm/ResetPasswordConfirm";
import RecruiterBaseLayout from "../layouts/app/recruiter/RecruiterBaseLayout";
import NotFound from "../views/404-not-found/NotFound";
import PostSignUp from "../views/recruiter/post-sign-up/PostSignUp";
import RecruiterPrivateRoute from "../utils/RecruiterPrivateRoute";
import RecruiterProfile from "../views/recruiter/profile/RecruiterProfile";
import CandidateProfilePublicViewWrapper from "../views/recruiter/candidate-profile/CandidateProfilePublicViewWrapper";
import SearchCandidate from "../views/recruiter/search-candidate/SearchCandidate";
import RecruiterBaseLayoutWithFooter from "../layouts/app/recruiter/RecruiterBaseLayoutWithFooter";
import TermsAndConditionForRecruiter from "../views/terms-and-conditions/TermsAndConditionForRecruiter";
import Verification from "../views/recruiter/verification/Verification";
import VerificationResetPassword from "../views/recruiter/verification-reset-password/VerificationResetPassword";
import React from "react";

export const recruiterAuthRoutes = {
    path: RECRUITER_AUTH_BASE_URL,
    element: <RecruiterAuthenticationLayout />,
    children:[
        {
            path: RECRUITER_SIGN_UP,
            element: <RecruiterSemiPublicRoute />,
            children: [
                {
                    path: RECRUITER_SIGN_UP,
                    element: <SignUp />,
                },
            ]
        },
        {
            path: RECRUITER_SIGN_IN,
            element: <RecruiterSemiPublicRoute />,
            children: [
                {
                    path: RECRUITER_SIGN_IN,
                    element: <SignIn />,
                },
            ]
        },
        {
            path: RECRUITER_FORGOT_PASSWORD,
            element: <RecruiterSemiPublicRoute />,
            children: [
                {
                    path: RECRUITER_FORGOT_PASSWORD,
                    element: <ForgotPassword />,
                },
            ]
        },
        {
            path: RECRUITER_POST_FORGOT_PASSWORD,
            element: <RecruiterSemiPublicRoute />,
            children: [
                {
                    path: RECRUITER_POST_FORGOT_PASSWORD,
                    element: <PostForgotPassword />,
                },
            ]
        },
        {
            path: RECRUITER_RESET_PASSWORD,
            element: <RecruiterSemiPublicRoute />,
            children: [
                {
                    path: RECRUITER_RESET_PASSWORD,
                    element: <ResetPassword />,
                },
            ]
        },
        {
            path: RECRUITER_RESET_PASSWORD_CONFIRM,
            element: <RecruiterSemiPublicRoute />,
            children: [
                {
                    path: RECRUITER_RESET_PASSWORD_CONFIRM,
                    element: <ResetPasswordConfirm />,
                },
            ]
        },
    ]
};

export const recruiterRoutes = {
    path: RECRUITER_BASE_URL,
    element: <RecruiterBaseLayout />,
    children: [
        {
            path: RECRUITER_BASE_URL,
            element: <NotFound />,
        },
        {
            path: RECRUITER_POST_SIGN_UP,
            element: <PostSignUp />,
        },
        {
            path: RECRUITER_PROFILE,
            element: <RecruiterPrivateRoute/>,
            children: [
                {
                    path: RECRUITER_PROFILE,
                    element: <RecruiterProfile />
                }
            ]
        },
        {
            path: RECRUITER_CANDIDATE_PROFILE_WITH_USER_ID,
            element: <RecruiterPrivateRoute/>,
            children: [
                {
                    path: RECRUITER_CANDIDATE_PROFILE_WITH_USER_ID,
                    element: <CandidateProfilePublicViewWrapper />
                }
            ]
        },
        {
            path: RECRUITER_SEARCH_CANDIDATE,
            element: <RecruiterPrivateRoute/>,
            children: [
                {
                    path: RECRUITER_SEARCH_CANDIDATE,
                    element: <SearchCandidate />
                }
            ]
        }

    ],
};

export const recruiterTermsRoutes = {
    path: RECRUITER_TERMS,
    element: <RecruiterBaseLayoutWithFooter/>,
    children: [
        {
            path: RECRUITER_TERMS,
            element: <TermsAndConditionForRecruiter/>
        },

    ]
};

export const recruiterVerifyRoute ={
    path: RECRUITER_VERIFY_PAGE,
    element: <Verification/>,
};

export const recruiterVerifyResetPasswordRoute ={
    path: RECRUITER_VERIFY_RESET_PASSWORD_PAGE,
    element: <VerificationResetPassword/>,
};