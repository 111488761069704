import Dropdown from "react-bootstrap/Dropdown";
import {useNavigate} from "react-router-dom";
import {useAuthContext} from "../../context/useAuthContext";
import {CANDIDATE_PROFILE_PAGE, RECRUITER_PROFILE} from "../../utils/RouteNamingConfig";
import {AppStorageService} from "../../services/AppStorageService";

const CandidateLoggedInMenu = (props:any) => {
    let storage = new AppStorageService();
    let user = props.user;
    let email = user.email ?? '--';
    let name = user.name ?? '';
    let profilePicture = user.picture;
    let navigate = useNavigate();
    const {dispatch}:any= useAuthContext();
    const handleProfileButton = () => {
        navigate(CANDIDATE_PROFILE_PAGE);
    }
    const signOut = () => {
        localStorage.clear();
        dispatch({type:'LOGOUT'})
        storage.setUserLoggedIn(false);
        localStorage.clear();
        window.location.reload();
    }
    return (
        <>
            <div className="dropdown">
                <Dropdown>
                    <Dropdown.Toggle id="profile_dropdown" className="profile_dropdown">
                        <img
                            src={profilePicture}
                            className="img-fluid rounded-circle border border-3 border-white"
                            width="40"
                            height="40"
                            alt=""
                        />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="me-2" style={{ width: 40 }}>
                                    <img
                                        src={profilePicture}
                                        className="img-fluid rounded-circle border"
                                        width="40"
                                        height="40"
                                        alt=""
                                    />
                                </div>
                                <div>
                                    <p className="mb-0 fw-semibold">{name}</p>
                                    <p className="mb-0">{email}</p>
                                </div>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={handleProfileButton}>
                            <i className="bi bi-person me-3"></i>Profile
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={signOut}>
                            <i className="bi bi-box-arrow-right me-3"></i>Sign out
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    );
}

export default CandidateLoggedInMenu;