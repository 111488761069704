import Dropdown from "react-bootstrap/Dropdown";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  RECRUITER_PROFILE,
  RECRUITER_SIGN_IN,
} from "../../utils/RouteNamingConfig";
import { useAuthContext } from "../../context/useAuthContext";
import recruiterProfile from "../../assets/images/recruiter-image.png";
import { RecruiterStatusCollection } from "../../utils/CommonConstants";
import RecruiterStatusHeading from "../recruiter/RecruiterStatusHeading";

const RecruiterLoggedInMenu = (props: any) => {
  let recruiterUser = props.user;
  let email = recruiterUser.email ?? "--";
  let name = "Recruiter";
  if (recruiterUser && recruiterUser.name) {
    if (recruiterUser.name != "--") {
      name = recruiterUser.name;
    }
  }
  let firstLetter = "R";
  if (recruiterUser && recruiterUser.first_letter) {
    if (recruiterUser.first_letter != "-") {
      firstLetter = recruiterUser.first_letter;
    }
  }
  let navigate = useNavigate();
  const { user, dispatch }: any = useAuthContext();

  const handleProfileButton = () => {
    navigate(RECRUITER_PROFILE);
  };

  const signOut = () => {
    localStorage.clear();
    dispatch({ type: "LOGOUT" });
    navigate(RECRUITER_SIGN_IN);
  };
  return (
    <>
      <div>
        <div className="d-flex justify-content-center gap-6">
          <div className="align-self-center">
            <span>
              <RecruiterStatusHeading type={user.status} />
            </span>
          </div>
          <p
            className="mb-0 fw-semibold text-black align-self-center d-inline-block"
            style={{ fontSize: "18px" }}
          >
            <span className="d-none d-md-inline-block">Inbox{" "}</span>
            <span
              className="px-2 py-1 text-primary rounded-circle fs-6"
              style={{ backgroundColor: "#F9F5FF" }}
            >
              0
            </span>{" "}
          </p>
          <Dropdown>
            <Dropdown.Toggle id="profile_dropdown" className="profile_dropdown">
              {/* <img
                  src={recruiterProfile}
                  className="img-fluid rounded-circle border border-white"
                  width="40"
                  height="40"
                  alt=""
                /> */}
              <div
                className="d-flex justify-content-center align-items-center bg-danger rounded-circle"
                style={{ width: "40px", height: "40px" }}
              >
                <p className="mb-0 text-uppercase text-white">{firstLetter}</p>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="me-2" style={{ width: 40 }}>
                    <div
                      className="d-flex justify-content-center align-items-center bg-danger rounded-circle"
                      style={{ width: "40px", height: "40px" }}
                    >
                      <p className="mb-0 text-uppercase text-white">
                        {firstLetter}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="mb-0 fw-semibold">{name}</p>
                    <p className="mb-0">{email}</p>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleProfileButton}>
                <i className="bi bi-person me-3"></i>Profile
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={signOut}>
                <i className="bi bi-box-arrow-right me-3"></i>Sign out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};
export default RecruiterLoggedInMenu;
